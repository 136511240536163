import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar";
import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Pagination } from "../../../Components/Pagination";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { fetchAttendances, getAllEvents, getUsers, seeAllLetters, seeAllTask } from "../../../Redux/app/action";
import LeaveLetter from "./LeaveLetter";
import Late from "./Late";
import Early from "./Early";
import { Link } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);

const HRHomeDash = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(seeAllLetters());
  }, [dispatch]);

  const totaluser = useSelector((state) => state.AppReducer.users.length);

  //Task

  useEffect(() => {
    dispatch(seeAllTask());
  }, [dispatch]);
  const taskData = useSelector((state) => state.AppReducer.taskData);
  //console.log("taskdata", taskData);
  // Handling Pagination Here


  useEffect(() => {
    dispatch(fetchAttendances());
  }, [dispatch]);
  //const attendances = useSelector((state) => state.AppReducer.attendances);
  //console.log("totalatt", attendances);



  const [page, setPage] = useState(1);

  let perPage = 4;

  let totalPage = Math.ceil(taskData.length / perPage);

  let end = page * perPage;
  let start = end - perPage;
  let filteredTask = [...taskData].reverse();
  let paginationData = [...filteredTask].slice(start, end);
  //console.log("revtaskdata", paginationData);

  // Handling Pagination End Here

  const [tabIndex, setTabIndex] = useState(0);

  const handleSliderChange = (event) => {
    setTabIndex(parseInt(event.target.value, 10));
  };

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const [tabIndex2, setTabIndex2] = useState(0);

  const handleTabsChange2 = (index) => {
    setTabIndex2(index);
  };

  const [tabIndex3, setTabIndex3] = useState(0);

  const handleTabsChange3 = (index) => {
    setTabIndex3(index);
  };

  ////////////// TASK CHART DATA ////////////////

  // const [selectedLabels, setSelectedLabels] = useState([]);

  // // Count the number of tasks for each priority
  // const priorityCounts = {
  //   High: taskData.filter((task) => task.priority === "High").length,
  //   Low: taskData.filter((task) => task.priority === "Low").length,
  //   Medium: taskData.filter((task) => task.priority === "Medium").length,
  // };

  // // Count the number of tasks for each status
  // const statusCounts = {
  //   Completed: taskData.filter((task) => task.task_status === "Completed")
  //     .length,
  //   Pending: taskData.filter((task) => task.task_status === "Pending").length,
  // };

  // // Get the data based on the selected labels
  // const getData = () => {
  //   if (selectedLabels.length === 0 || selectedLabels.includes("Task Status")) {
  //     return Object.values(statusCounts);
  //   } else if (selectedLabels.includes("Priority")) {
  //     return Object.values(priorityCounts);
  //   }
  //   return [];
  // };

  // // Get the labels based on the selected labels
  // const getLabels = () => {
  //   const labels = [];
  //   if (selectedLabels.length === 0 || selectedLabels.includes("Task Status")) {
  //     labels.push(...Object.keys(statusCounts));
  //   }
  //   if (selectedLabels.includes("Priority")) {
  //     labels.push(...Object.keys(priorityCounts));
  //   }
  //   return labels;
  // };

  // // Handle label checkbox change
  // const handleLabelChange = (event) => {
  //   const { value, checked } = event.target;
  //   if (checked) {
  //     setSelectedLabels((prevLabels) => [...prevLabels, value]);
  //   } else {
  //     setSelectedLabels((prevLabels) =>
  //       prevLabels.filter((label) => label !== value)
  //     );
  //   }
  // };

  // // Prepare the chart data
  // const chartData = {
  //   labels: getLabels(),
  //   datasets: [
  //     {
  //       data: getData(),
  //       backgroundColor: selectedLabels.includes("Task Status")
  //         ? ["#4CAF50", "#36A2EB"] // Green, Blue
  //         : selectedLabels.includes("Priority")
  //         ? ["#e53e3e", "#FFCE56", "#FFFFA8"] // Red, Yellow, Light Yellow
  //         : ["#4CAF50", "#36A2EB"],
  //     },
  //   ],
  // };


////////////// TASK STATUS CHART DATA //////////////////

const [selectedTaskStatusLabels, setSelectedTaskStatusLabels] = useState([]);

  const statusCounts = {
    Completed: taskData.filter((task) => task.task_status === "Completed")
      .length,
    Pending: taskData.filter((task) => task.task_status === "Pending").length,
  };
const getTaskStatusData = () => {
  if (
    selectedTaskStatusLabels.length === 0 ||
    selectedTaskStatusLabels.includes("Task Status")
  ) {
    return Object.values(statusCounts);
  }
  return [];
};

const getTaskStatusLabels = () => {
  const labels = [];
  if (
    selectedTaskStatusLabels.length === 0 ||
    selectedTaskStatusLabels.includes("Task Status")
  ) {
    labels.push(...Object.keys(statusCounts));
  }
  return labels;
};

// Prepare the chart data
const chartTaskStatusData = {
  labels: getTaskStatusLabels(),
  datasets: [
    {
      data: getTaskStatusData(),
      backgroundColor: ["#4CAF50", "#36A2EB"],
    },
  ],
};

////////////// TASK PRIORITY CHART DATA //////////////////

const [selectedPriorityLabels, setSelectedPriorityLabels] = useState([]);

  const priorityCounts = {
    High: taskData.filter((task) => task.priority === "High").length,
    Low: taskData.filter((task) => task.priority === "Low").length,
    Medium: taskData.filter((task) => task.priority === "Medium").length,
  };
const getPriorityData = () => {
  if (
    selectedPriorityLabels.length === 0 ||
    selectedPriorityLabels.includes("Priority")
  ) {
    return Object.values(priorityCounts);
  }
  return [];
};

const getPriorityLabels = () => {
  const labels = [];
  if (
    selectedPriorityLabels.length === 0 ||
    selectedPriorityLabels.includes("Priority")
  ) {
    labels.push(...Object.keys(priorityCounts));
  }
  return labels;
};

// Prepare the chart data
const chartPriorityData = {
  labels: getPriorityLabels(),
  datasets: [
    {
      data: getPriorityData(),
      backgroundColor: ["#e53e3e", "#FFCE56", "#FFFFA8"],
    },
  ],
};


  //////////////LEAVE LETTER CHART DATA ///////////////
  const leavesData = useSelector((state) => state.AppReducer.leaves);
  const [selectedLeavesLabels, setSelectedLeavesLabels] = useState([]);

  const leavestatusCounts = {
    Approved: leavesData.filter((leave) => leave.leave_status === "Approved")
      .length,
    Pending: leavesData.filter((leave) => leave.leave_status === "Pending")
      .length,
    Rejected: leavesData.filter((leave) => leave.leave_status === "Rejected")
      .length,
  };
  const getLeaveData = () => {
    if (
      selectedLeavesLabels.length === 0 ||
      selectedLeavesLabels.includes("Leave Letter")
    ) {
      return Object.values(leavestatusCounts);
    }
    return [];
  };

  const getLeaveLabels = () => {
    const labels = [];
    if (
      selectedLeavesLabels.length === 0 ||
      selectedLeavesLabels.includes("Leave Letter")
    ) {
      labels.push(...Object.keys(leavestatusCounts));
    }
    return labels;
  };

  // Prepare the chart data
  const chartLeaveData = {
    labels: getLeaveLabels(),
    datasets: [
      {
        data: getLeaveData(),
        backgroundColor: ["#4CAF50", "#36A2EB", "#e53e3e"],
      },
    ],
  };

  //////////////LATELOGIN CHART DATA //////////////////
  const lateLoginData = useSelector((state) => state.AppReducer.lateLogin);
  const [selectedLateLabels, setSelectedLateLabels] = useState([]);

  const latestatusCounts = {
    Approved: lateLoginData.filter((late) => late.late_status === "Approved")
      .length,
    Pending: lateLoginData.filter((late) => late.late_status === "Pending")
      .length,
    Rejected: lateLoginData.filter((late) => late.late_status === "Rejected")
      .length,
  };
  const getLateData = () => {
    if (
      selectedLateLabels.length === 0 ||
      selectedLateLabels.includes("Late Letter")
    ) {
      return Object.values(latestatusCounts);
    }
    return [];
  };

  const getLateLabels = () => {
    const labels = [];
    if (
      selectedLateLabels.length === 0 ||
      selectedLateLabels.includes("Late Letter")
    ) {
      labels.push(...Object.keys(latestatusCounts));
    }
    return labels;
  };

  // Prepare the chart data
  const chartLateLoginData = {
    labels: getLateLabels(),
    datasets: [
      {
        data: getLateData(),
        backgroundColor: ["#4CAF50", "#36A2EB", "#e53e3e"],
      },
    ],
  };

  //////////////EARLYLOGOUT CHART DATA //////////////////
  const earlyLogoutData = useSelector((state) => state.AppReducer.earlyLogout);
  const [selectedEarlyLogoutLabels, setSelectedEarlyLogoutLeavesLabels] =
    useState([]);

  const earlylogoutstatusCounts = {
    Approved: earlyLogoutData.filter(
      (earlylogout) => earlylogout.earlylogout_status === "Approved"
    ).length,
    Pending: earlyLogoutData.filter(
      (earlylogout) => earlylogout.earlylogout_status === "Pending"
    ).length,
    Rejected: earlyLogoutData.filter(
      (earlylogout) => earlylogout.earlylogout_status === "Rejected"
    ).length,
  };
  const getEarlyLogoutData = () => {
    if (
      selectedEarlyLogoutLabels.length === 0 ||
      selectedEarlyLogoutLabels.includes("Early Logout Letter")
    ) {
      return Object.values(earlylogoutstatusCounts);
    }
    return [];
  };

  const getEarlyLogoutLabels = () => {
    const labels = [];
    if (
      selectedEarlyLogoutLabels.length === 0 ||
      selectedEarlyLogoutLabels.includes("Early Logout Letter")
    ) {
      labels.push(...Object.keys(earlylogoutstatusCounts));
    }
    return labels;
  };

  // Prepare the chart data
  const chartEarlyLogoutData = {
    labels: getEarlyLogoutLabels(),
    datasets: [
      {
        data: getEarlyLogoutData(),
        backgroundColor: ["#4CAF50", "#36A2EB", "#e53e3e"],
      },
    ],
  };

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);


  const users = useSelector((store) => store.AppReducer.users);
  const userPathMap = {};
  users.forEach((user) => {
    userPathMap[user.employee_id] = user.path;
  });

  // Now you can use the map to get the path for each task's employee_id
  paginationData.forEach((task) => {
    if (userPathMap.hasOwnProperty(task.employee)) {
      const path = userPathMap[task.employee];
      //console.log(`Employee ID: ${task.employee_id}, Path: ${path}`);
    } else {
      //console.log(`Employee ID: ${task.employee_id}, Path not found.`);
    }
  });
  useEffect(() => {
    dispatch(getAllEvents());
  }, [dispatch]);

  return (
    <Box
      pl={{ base: "2", md: "10" }}
      pt={{ base: "10", md: "10" }}
      w={{ base: "100%", md: "80%" }}
    >
      <Navbar />
      <br />
      <br />
      <Flex gap={5}>
        <Heading size="sm">HR </Heading>
      </Flex>
      <Flex flexWrap="wrap" gap={4} m={6}>
        <Card bgGradient='linear(to-r, green.400, green.300, green.200)' w={250}>
          <CardBody>
            <Stack spacing="3" textAlign="center">
              <Heading size="sm">Total Employees</Heading>
              <Heading size="sm">{totaluser -1 }</Heading>
            </Stack>
          </CardBody>
        </Card>
        <Card bgGradient='linear(to-r, green.400, green.300, green.200)' w={250}>
          <CardBody>
            <Stack spacing="3" textAlign="center">
              <Heading size="sm">Pending Requests</Heading>
              <Heading size="sm">0</Heading>
            </Stack>
          </CardBody>
        </Card>
      </Flex>
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
      >
        <Box
          w={{ base: "100%", md: "70%" }}
          p={5}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <Heading
            textAlign={"start"}
            size={"sm"}
            p={2}
            textDecoration={"underline"}
          >
            Task Assigned
          </Heading>
          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"md"}>
                  <Th style={{ textAlign: "center" }} boxShadow={"md"}>
                    Assigned To
                  </Th>
                  <Th style={{ textAlign: "center" }} boxShadow={"md"}>
                    Assign On
                  </Th>
                  <Th style={{ textAlign: "center" }} boxShadow={"md"}>
                    Title
                  </Th>
                  <Th style={{ textAlign: "center" }} boxShadow={"md"}>
                    Start Date
                  </Th>
                  <Th style={{ textAlign: "center" }} boxShadow={"md"}>
                    End Date
                  </Th>
                  <Th style={{ textAlign: "center" }} boxShadow={"md"}>
                    Status
                  </Th>
                  <Th style={{ textAlign: "center" }} boxShadow={"md"}>
                    Priority
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {paginationData.length > 0 &&
                  paginationData?.map((el) => {
                    const path = userPathMap[el.employee];
                    return (
                    <Tr key={el._id} boxShadow={"md"}>
                      <Link to="/hr/task">
                        <Td style={{ textAlign: "center" }}>
                          <Wrap>
                            <WrapItem>
                              <Flex justifyContent={"space-around"} gap={2}>
                              <Avatar
                                size="xs"
                                name={el.employee}
                                src={
                                  path
                                    ? `https://project360-backend.onrender.com/${path}`
                                    : `https://project360-backend.onrender.com/${path}`
                                }
                              />
                                <Heading size={"xs"} >
                                <Button size={"xs"} fontWeight={"bold"}> 
                                {el.employee}
                                </Button>
                                </Heading>
                              </Flex>
                            </WrapItem>
                          </Wrap>
                        </Td>
                      </Link>

                      <Td style={{ textAlign: "center" }}>
                        <Button size={"xs"} fontWeight={"bold"}>
                          {new Date(el.createdAt).toDateString()}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button size={"xs"} fontWeight={"bold"}>{el.title}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button size={"xs"} fontWeight={"bold"}>
                          {new Date(el.start_date).toDateString()}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button size={"xs"} fontWeight={"bold"}>
                          {new Date(el.end_date).toDateString()}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button
                          color={
                            el.task_status === "Pending" ? "blue" : "green"
                          }
                          size={"xs"} fontWeight={"bold"}
                        >
                          {el.task_status}
                        </Button>
                      </Td>
                      <Td>
                        <Button
                          style={{
                            color:
                              el.priority === "High" ? "red"
                              : el.priority === "Low" ? "orange"
                              : "#41729F",
                            
                          }}
                          size={"xs"} fontWeight={"bold"}
                        >
                          {el.priority}
                        </Button>
                      </Td>
                    </Tr>
                  )
                        })}
              </Tbody>
            </Table>
            <Box>
              {paginationData.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found
                </Alert>
              )}
            </Box>
          </TableContainer>
          <Flex justifyContent={"flex-end"} mt={5}>
            <Pagination page={page} setPage={setPage} totalPage={totalPage} />
          </Flex>
        </Box>
        <Box
          w={{ base: "100%", md: "25%" }}
          p={5}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
      
          <Box>
            <Box>
              <Tabs index={tabIndex3} onChange={handleTabsChange3}>
                <Flex justifyContent={{ base: "center", md: "center" }}>
                  <TabList
                    textAlign="center"
                    size="sm"
                    spacing={5}
                    justifyContent="center"
                  >
                    <Tab fontWeight="bold">Task Status</Tab>
                    <Tab fontWeight="bold">Priority</Tab>
                    
                  </TabList>
                </Flex>
                <TabPanels>
                  <TabPanel>
                    <Doughnut data={chartTaskStatusData} />
                  </TabPanel>
                  <TabPanel>
                    <Doughnut data={chartPriorityData} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </Box>
      </Flex>

      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
        mt={5}
      >
        <Box
          w={{ base: "100%", md: "70%" }}
          p={5}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <Box>
            <Tabs index={tabIndex} onChange={handleTabsChange}>
              <Flex justifyContent={"space-between"}>
                <TabList textAlign={"start"} size={"sm"} gap={5}>
                  <Tab fontWeight={"bold"}>Leave Letter</Tab>
                  <Tab fontWeight={"bold"}>Late Login</Tab>
                  <Tab fontWeight={"bold"}>Early Logout</Tab>
                  <Tab fontWeight={"bold"}>Advice</Tab>
                  <Tab fontWeight={"bold"}>Activity</Tab>
                  <Tab fontWeight={"bold"}>Complaint</Tab>
                </TabList>
                <input
                  type="range"
                  min="0"
                  max="5"
                  value={tabIndex}
                  onChange={handleSliderChange}
                />
              </Flex>
              <TabPanels>
                <TabPanel>
                  <LeaveLetter />
                </TabPanel>
                <TabPanel>
                  <Late />
                </TabPanel>
                <TabPanel>
                  <Early />
                </TabPanel>
                <TabPanel>
                  <LeaveLetter />
                </TabPanel>
                <TabPanel>
                  <Late />
                </TabPanel>
                <TabPanel>
                  <Early />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
        <Box
          w={{ base: "100%", md: "25%" }}
          p={5}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <Box>
            <Box>
              <Tabs index={tabIndex2} onChange={handleTabsChange2}>
                <Flex justifyContent={{ base: "center", md: "space-between" }}>
                  <TabList
                    textAlign={{ base: "center", md: "start" }}
                    size="sm"
                    spacing={5}
                  >
                    <Tab fontWeight="bold">Leave Letter</Tab>
                    <Tab fontWeight="bold">Late Login</Tab>
                    <Tab fontWeight="bold">Early Logout</Tab>
                  </TabList>
                </Flex>
                <TabPanels>
                  <TabPanel>
                    <Doughnut data={chartLeaveData} />
                  </TabPanel>
                  <TabPanel>
                    <Doughnut data={chartLateLoginData} />
                  </TabPanel>
                  <TabPanel>
                    <Doughnut data={chartEarlyLogoutData} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default HRHomeDash;
