import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  ListItem,
  Text,
  Textarea,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Sidebar from "../../../../Components/Sidebar";
import Navbar from "../../../../Components/Navbar";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@chakra-ui/icons";
import { BiBold, BiItalic, BiUnderline } from "react-icons/bi";

const ActivityRequest = () => {
  const [fontSize, setFontSize] = useState(16);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);

  const handleIncreaseFontSize = () => {
    setFontSize(fontSize + 2);
  };

  const handleDecreaseFontSize = () => {
    setFontSize(fontSize - 2);
  };

  const handleToggleBold = () => {
    setIsBold(!isBold);
  };

  const handleToggleItalic = () => {
    setIsItalic(!isItalic);
  };

  const handleToggleUnderline = () => {
    setIsUnderline(!isUnderline);
  };
  return (
    <Flex>
    <Sidebar />
    <Box pl={{ base: "2", md: "10" }}
    pt={{ base: "10", md: "10" }}
    w={{ base: "100%", md: "80%" }}>
      <Navbar />
      <br />
      <br />
      <Heading size={"md"} textAlign={"start"}>
        Generate Letters
      </Heading>
      <Breadcrumb
        spacing="8px"
        mt={6}
        mb={6}
        separator={<ChevronRightIcon color="gray.500" />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink href="/employee">Dashboard</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink href="/generateletters">
            Generate Letter
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href="#">Early Logout</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justifyContent={"space-between"}>
        <Box
          w={"70%"}
          border={"1px solid"}
          color={"gray.400"}
          p={10}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
          height={600}
        >
          <Box position="relative">
            <Input
              borderRadius="md"
              borderWidth="2px"
              borderColor="gray.300"
              py={2}
              px={4}
              variant="unstyled"
            />
            <Box
              position="absolute"
              top="-8px"
              left="8px"
              pointerEvents="none"
              color="gray.400"
              fontSize="sm"
              bg="white"
              px={1}
            >
              From
            </Box>
          </Box>
          <br />
          <Box position="relative">
            <Input
              borderRadius="md"
              borderWidth="2px"
              borderColor="gray.300"
              py={2}
              px={4}
              variant="unstyled"
            />
            <Box
              position="absolute"
              top="-8px"
              left="8px"
              pointerEvents="none"
              color="gray.400"
              fontSize="sm"
              bg="white"
              px={1}
            >
              To
            </Box>
          </Box>
          <br />
          <Box position="relative">
            <Input
              borderRadius="md"
              borderWidth="2px"
              borderColor="gray.300"
              py={2}
              px={4}
              variant="unstyled"
            />
            <Box
              position="absolute"
              top="-8px"
              left="8px"
              pointerEvents="none"
              color="gray.400"
              fontSize="sm"
              bg="white"
              px={1}
            >
              Date
            </Box>
          </Box>
          <br />
          <Box>
            <Text
              textAlign={"start"}
              fontWeight={"semibold"}
              color="gray.400"
            >
              Content
            </Text>
            <Box
              mt={2}
              display="flex"
              justifyContent="flex-end"
              border={"1px solid"}
              borderRadius={5}
              borderBottom={"none"}
              gap={2}
              color={"gray.400"}
              pt={2}
              pb={2}
              pr={2}
            >
              <Text fontWeight={"semibold"} color="gray.400">
                Font Size :
              </Text>
              <IconButton
                aria-label="Decrease Font Size"
                icon={
                  <ChevronDownIcon
                    style={{ color: "black", fontSize: "20" }}
                  />
                }
                onClick={handleDecreaseFontSize}
                size="sm"
              />
              <IconButton
                aria-label="Increase Font Size"
                icon={
                  <ChevronUpIcon style={{ color: "black", fontSize: "20" }} />
                }
                onClick={handleIncreaseFontSize}
                size="sm"
              />
              <IconButton
                aria-label="Bold"
                icon={<BiBold style={{ color: "black", fontSize: "20" }} />}
                onClick={handleToggleBold}
                size="sm"
                colorScheme={isBold ? "teal" : undefined}
              />
              <IconButton
                aria-label="Italic"
                icon={<BiItalic style={{ color: "black", fontSize: "20" }} />}
                onClick={handleToggleItalic}
                size="sm"
                colorScheme={isItalic ? "teal" : undefined}
              />
              <IconButton
                aria-label="Underline"
                icon={
                  <BiUnderline style={{ color: "black", fontSize: "20" }} />
                }
                onClick={handleToggleUnderline}
                size="sm"
                colorScheme={isUnderline ? "teal" : undefined}
              />
            </Box>
            <Textarea
              fontSize={fontSize}
              fontWeight={isBold ? "bold" : undefined}
              fontStyle={isItalic ? "italic" : undefined}
              textDecoration={isUnderline ? "underline" : undefined}
              resize="vertical"
              minHeight="200px"
              placeholder="Message"
              focusBorderColor="gray.400"
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button>Send For Approval</Button>
          </Box>
        </Box>
        <Box
          w={"25%"}
          border={"1px solid"}
          // color={"gray.400"}
          p={5}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
          // overflowY={"scroll"}
          // height={700}
        >
          <Box
            border={"1px solid"}
            color={"gray.400"}
            p={5}
            borderRadius={10}
            boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
          >
            <Heading
              size={"md"}
              textAlign={"start"}
              textDecoration={"underline"}
              color={"green"}
            >
              Employee Details
            </Heading>
            <UnorderedList textAlign={"start"} mt={5}>
              <ListItem>Lorem ipsum dolor sit amet</ListItem>
              <ListItem>Consectetur adipiscing elit</ListItem>
              <ListItem>Integer molestie lorem at massa</ListItem>
              <ListItem>Facilisis in pretium nisl aliquet</ListItem>
            </UnorderedList>
          </Box>
          <br />
          <Box
            border={"1px solid"}
            color={"gray.400"}
            p={5}
            borderRadius={10}
            boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
          >
            <Heading
              size={"md"}
              textAlign={"start"}
              textDecoration={"underline"}
              color={"green"}
            >
              Company Details
            </Heading>
            <UnorderedList textAlign={"start"} mt={5}>
              <ListItem>Lorem ipsum dolor sit amet</ListItem>
              <ListItem>Consectetur adipiscing elit</ListItem>
              <ListItem>Integer molestie lorem at massa</ListItem>
              <ListItem>Facilisis in pretium nisl aliquet</ListItem>
            </UnorderedList>
          </Box>
          <br />
          <Box
            border={"1px solid"}
            color={"gray.400"}
            p={5}
            borderRadius={10}
            boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
          >
            <Heading
              size={"md"}
              textAlign={"start"}
              textDecoration={"underline"}
              color={"green"}
            >
              Employee Supervisor
            </Heading>
            <UnorderedList textAlign={"start"} mt={5}>
              <ListItem>Lorem ipsum dolor sit amet</ListItem>
              <ListItem>Consectetur adipiscing elit</ListItem>
              <ListItem>Integer molestie lorem at massa</ListItem>
              <ListItem>Facilisis in pretium nisl aliquet</ListItem>
            </UnorderedList>
          </Box>
          <br />
          <Box
            border={"1px solid"}
            color={"gray.400"}
            p={5}
            borderRadius={10}
            boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
          >
            <Heading
              size={"md"}
              textAlign={"start"}
              textDecoration={"underline"}
              color={"green"}
            >
              Employee Supervisor
            </Heading>
            <UnorderedList textAlign={"start"} mt={5}>
              <ListItem>Lorem ipsum dolor sit amet</ListItem>
              <ListItem>Consectetur adipiscing elit</ListItem>
              <ListItem>Integer molestie lorem at massa</ListItem>
              <ListItem>Facilisis in pretium nisl aliquet</ListItem>
            </UnorderedList>
          </Box>
          <br />
          <Box
            border={"1px solid"}
            color={"gray.400"}
            p={5}
            borderRadius={10}
            boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
          >
            <Heading
              size={"md"}
              textAlign={"start"}
              textDecoration={"underline"}
              color={"green"}
            >
              Employee Supervisor
            </Heading>
            <UnorderedList textAlign={"start"} mt={5}>
              <ListItem>Lorem ipsum dolor sit amet</ListItem>
              <ListItem>Consectetur adipiscing elit</ListItem>
              <ListItem>Integer molestie lorem at massa</ListItem>
              <ListItem>Facilisis in pretium nisl aliquet</ListItem>
            </UnorderedList>
          </Box>
        </Box>
      </Flex>
    </Box>
  </Flex>
  )
}

export default ActivityRequest