import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  Flex,
  Grid,
  GridItem,
  Heading,
} from "@chakra-ui/react";
import React from "react";
import Sidebar from "../../../Components/Sidebar";
import Navbar from "../../../Components/Navbar";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { AiOutlineFileText } from "react-icons/ai";
import { BiRun } from "react-icons/bi";
import { GiOwl } from "react-icons/gi";
import { GrCompliance } from "react-icons/gr";
import { RiUserVoiceLine } from "react-icons/ri";
import { HiOutlineUserGroup } from "react-icons/hi";
import { Link } from "react-router-dom";

const GenerateLetter = () => {
  return (
    <Flex>
      <Sidebar />
      <Box
        pl={{ base: "2", md: "10" }}
        pt={{ base: "10", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Generate Letters
        </Heading>
        <Breadcrumb
          spacing="8px"
          mt={6}
          mb={6}
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/employee">Dashboard</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href="#">Generate Letter</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          gap="10"
          pl={10}
        >
          {/* <Link to="/leave"> */}

          <Link to="/leave">
            <Card w={"60%"} h={"100%"} p={10}>
              <GridItem>
                <Box
                  mx="auto"
                  mb={[2, 3, 4, 5]} // Responsive margin
                  width={["80%", "60%", "50%", "40%"]} // Responsive width
                >
                  <AiOutlineFileText size={[40, 60, 80, 100]} color="black" />{" "}
                  {/* Responsive size */}
                </Box>

                <Heading mt={10} size={"md"} textAlign={"center"}>
                  Leave Letter
                </Heading>
              </GridItem>
            </Card>
          </Link>

          <Link to="/latelogin">
            <Card w={"60%"} h={"100%"} p={10}>
              <GridItem>
                <Box
                  mx="auto"
                  mb={[2, 3, 4, 5]} // Responsive margin
                  width={["80%", "60%", "50%", "40%"]} // Responsive width
                >
                  <BiRun size={[40, 60, 80, 100]} color="black" />{" "}
                  {/* Responsive size */}
                </Box>

                <Heading mt={10} size={"md"} textAlign={"center"}>
                  Late Login
                </Heading>
              </GridItem>
            </Card>
          </Link>
          <Link to="/earlylogout">
            <Card w={"60%"} h={"100%"} p={10}>
              <GridItem>
                <Box
                  mx="auto"
                  mb={[2, 3, 4, 5]} // Responsive margin
                  width={["80%", "60%", "50%", "40%"]} // Responsive width
                >
                  <GiOwl size={[40, 60, 80, 100]} color="black" />{" "}
                  {/* Responsive size */}
                </Box>

                <Heading mt={10} size={"md"} textAlign={"center"}>
                  Early Logout
                </Heading>
              </GridItem>
            </Card>
          </Link>
          <Link to="/complaint">
            <Card w={"60%"} h={"100%"} p={10}>
              <GridItem>
                <Box
                  mx="auto"
                  mb={[2, 3, 4, 5]} // Responsive margin
                  width={["80%", "60%", "50%", "40%"]} // Responsive width
                >
                  <GrCompliance size={[40, 60, 80, 100]} color="black" />{" "}
                  {/* Responsive size */}
                </Box>

                <Heading mt={10} size={"md"} textAlign={"center"}>
                  Complaint
                </Heading>
              </GridItem>
            </Card>
          </Link>
          <Link to="/advice">
            <Card w={"60%"} h={"100%"} p={10}>
              <GridItem>
                <Box
                  mx="auto"
                  mb={[2, 3, 4, 5]} // Responsive margin
                  width={["80%", "60%", "50%", "40%"]} // Responsive width
                >
                  <RiUserVoiceLine size={[40, 60, 80, 100]} color="black" />{" "}
                  {/* Responsive size */}
                </Box>

                <Heading mt={10} size={"md"} textAlign={"center"}>
                  Advice / Suggestions{" "}
                </Heading>
              </GridItem>
            </Card>
          </Link>
          <Link to="/activity">
            <Card w={"60%"} h={"100%"} p={10}>
              <GridItem>
                <Box
                  mx="auto"
                  mb={[2, 3, 4, 5]} // Responsive margin
                  width={["80%", "60%", "50%", "40%"]} // Responsive width
                >
                  <HiOutlineUserGroup size={[40, 60, 80, 100]} color="black" />{" "}
                  {/* Responsive size */}
                </Box>

                <Heading mt={10} size={"md"} textAlign={"center"}>
                  Activity Request
                </Heading>
              </GridItem>
            </Card>
          </Link>
        </Grid>
      </Box>
    </Flex>
  );
};

export default GenerateLetter;
