import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchAttendances } from "../../../Redux/app/action";
import AdminSidebar from "../../../Components/AdminSidebar";
import Select from "react-select";
import moment from "moment";
import Calendar from "react-awesome-calendar";
import { BsCalendar3 } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker-custom.css";
const AdminAttendence = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAttendances());
  }, [dispatch]);
  const attendances = useSelector((state) => state.AppReducer.attendances);
  //console.log("totalatt", moment(attendances[0].date).format("YYYY-MM-DD"));

  const users = useSelector((state) => state.AppReducer.users);

  const [selectedUser, setSelectedUser] = useState({});

  const options = [];
  users.forEach((user) => {
    const option = {
      value: user._id,
      label: user.sudo_name,
    };
    options.push(option);
  });
  //console.log("opt", options);

  const handleSelectChange = (selectedOption) => {
    setSelectedUser(selectedOption);
  };

  const selectValue = selectedUser.value;
  //console.log("seluser", selectValue);
  const filtereAttdData =
    attendances?.filter((obj) => obj.employee_id === selectValue) || [];
  //console.log("selectedattdata", filtereAttdData);

  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleButtonClick = () => {
    setShowCalendar(!showCalendar);
  };

  const handleCalendarClose = () => {
    setShowCalendar(false);
  };
  //console.log("selectedDate", selectedDate);
  const correct = moment(selectedDate).format("YYYY-MM-DD");
  //console.log("correct", correct);

  const filteredAttendanceData =
    filtereAttdData?.filter(
      (attendance) => moment(attendance.date).format("YYYY-MM-DD") === correct
    ) || [];
  //console.log("filteredAttendanceData", filteredAttendanceData);

  const calculateTotalTime = (startTime, endTime) => {
    if (!startTime || !endTime) {
      return "";
    }
    const start = new Date(startTime);
    const end = new Date(endTime);
    const timeDiff = end - start;
    const totalHours = Math.floor(timeDiff / (1000 * 60 * 60));
    const totalMinutes = Math.floor(
      (timeDiff % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${totalHours} Hrs ${totalMinutes} Min`;
  };

  const calculateFinalTime = (totalTime, breakTime) => {
    if (!totalTime || !breakTime) {
      return "NA";
    }

    const [totalHours, totalMinutes] = totalTime.split(" Hrs ").map(Number);

    const [breakHours, breakMinutes] = breakTime.split(" Hrs ").map(Number);

    const finalHours = totalHours - breakHours;
    const finalMinutes = totalMinutes - breakMinutes;

    if (finalMinutes < 0) {
      finalHours--;
      finalMinutes += 60;
    }

    return `${finalHours} Hrs ${finalMinutes} Min`;
  };

  const parseDurationToMinutes = (duration) => {
    if (!duration || typeof duration !== "string") return NaN;

    const parts = duration.split(" ");
    let minutes = 0;

    for (let i = 0; i < parts.length; i += 2) {
      const value = parseInt(parts[i]);
      const unit = parts[i + 1].toLowerCase();

      if (isNaN(value) || (unit !== "hrs" && unit !== "min")) {
        return NaN; // Invalid duration format
      }

      if (unit === "hrs") {
        minutes += value * 60;
      } else if (unit === "min") {
        minutes += value;
      }
    }

    return minutes;
  };

  const formatDuration = (minutes) => {
    if (isNaN(minutes)) return `${0} Hrs ${0} Min`;

    const hrs = Math.floor(minutes / 60);
    const min = minutes % 60;

    return `${hrs} Hrs ${min} Min`;
  };

  // const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  // const handleDateChange = (date) => {
  //   setSelectedDate1(date);
  // };

  const formatDate = (date) => {
    if (!date) return ""; // Return empty string if date is not selected
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "numeric",
    }).format(date);
    return formattedDate;
  };



  const formatDateForFilter = (date) => {
    if (!date) return null;

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const filteredData =
    filtereAttdData?.filter((obj) => {
      if (!selectedStartDate || !selectedEndDate) return true; // If no date range is selected, show all data
      const startDate = new Date(selectedStartDate);
      const endDate = new Date(selectedEndDate);
      const date = new Date(obj.date);
      return (
        formatDateForFilter(date) >= formatDateForFilter(startDate) &&
        formatDateForFilter(date) <= formatDateForFilter(endDate)
      );
    }) || [];

  return (
    <Flex>
      <AdminSidebar />
      <Box
        pl={{ base: "none", md: "10" }}
        pt={{ base: "none", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Attendences
        </Heading>
        <Flex justifyContent={"space-between"}>
          <Breadcrumb
            spacing="8px"
            mt={6}
            mb={6}
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/admin">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="#">Attendences</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="#">{selectedUser.label}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex justifyContent={"space-evenly"} gap={3}>
            <Box>
              <DatePicker
                selected={selectedStartDate}
                onChange={(date) => setSelectedStartDate(date)}
                selectsStart
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                placeholderText="Start Date"
                dateFormat="yyyy-MM-dd" // Update date format
                customInput={
                  <InputGroup>
                    <Input
                      value={formatDateForFilter(selectedStartDate)}
                      placeholder="Start Date"
                      style={{
                        border: "1px solid gray",
                        fontWeight: "inherit",
                      }}
                    />
                    <InputRightElement>
                      <BsCalendar3 />
                    </InputRightElement>
                  </InputGroup>
                }
              />
              <DatePicker
                selected={selectedEndDate}
                onChange={(date) => setSelectedEndDate(date)}
                selectsEnd
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                placeholderText="End Date"
                dateFormat="yyyy-MM-dd" // Update date format
                customInput={
                  <InputGroup>
                    <Input
                      value={formatDateForFilter(selectedEndDate)}
                      placeholder="End Date"
                      style={{
                        border: "1px solid gray",
                        fontWeight: "inherit",
                      }}
                    />
                    <InputRightElement>
                      <BsCalendar3 />
                    </InputRightElement>
                  </InputGroup>
                }
              />
            </Box>
            <Box style={{ position: "relative" }}>
              <Select
                onChange={handleSelectChange}
                options={options}
                placeholder=""
                required
                name="to"
                id="to"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "md",
                    borderWidth: "2px",
                    borderColor: "gray.300",
                    padding: "2px 4px",
                    fontWeight: "semibold",
                    width: "200px",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 2,
                    textAlign: "start",
                  }),
                }}
              />

              <Box
                style={{
                  position: "absolute",
                  top: "-8px",
                  left: "8px",
                  pointerEvents: "none",
                  color: "gray",
                  fontSize: "xs",
                  backgroundColor: "white",
                  padding: "0 2px",
                }}
              >
                Select Employees
              </Box>
            </Box>
          </Flex>
        </Flex>
        <Box>
          <TableContainer overflowY={"scroll"} maxH={"650"}>
            <Table
              variant="simple"
              colorScheme="green"
              boxShadow={"md"}
              sticky="thead"
            >
              <Thead
                boxShadow={"md"}
                position="sticky"
                top="0"
                zIndex={1}
                bgColor={"white"}
              >
                <Tr boxShadow={"md"}>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Sl.No
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Date
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Login
                  </Th>

                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Logout
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Breaks
                  </Th>

                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Total Break
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Total Duration <br style={{ textAlign: "center" }} /> In
                    Office
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Total Work <br style={{ textAlign: "center" }} /> Hours
                  </Th>

                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Status
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {filteredData.length > 0 &&
                  filteredData?.reverse()?.map((attendance, index) => {
                    const calculateTotalCompletedTime = (
                      loginTime,
                      logoutTime,
                      breakInTime,
                      breakOutTime
                    ) => {
                      if (
                        !loginTime ||
                        !logoutTime ||
                        !breakInTime ||
                        !breakOutTime
                      ) {
                        return "";
                      }

                      const start = new Date(loginTime);
                      const end = new Date(logoutTime);
                      const breakStart = new Date(breakInTime);
                      const breakEnd = new Date(breakOutTime);

                      const totalWorkTime =
                        end - start - (breakEnd - breakStart);

                      const totalHours = Math.floor(
                        totalWorkTime / (1000 * 60 * 60)
                      );
                      const totalMinutes = Math.floor(
                        (totalWorkTime % (1000 * 60 * 60)) / (1000 * 60)
                      );

                      return `${totalHours} Hrs ${totalMinutes} Min`;
                    };

                    const totalDurationinOffice = calculateTotalTime(
                      attendance.loginTime,
                      attendance.logoutTime
                    );

                    let totalDurationMinutes = 0;
                    attendance.breakInTime.forEach((breakInTime, index) => {
                      if (
                        attendance.breakOutTime &&
                        attendance.breakOutTime.length > index &&
                        attendance.breakOutTime[index]
                      ) {
                        const breakOutTime = new Date(
                          attendance.breakOutTime[index]
                        );
                        totalDurationMinutes += Math.floor(
                          (breakOutTime - new Date(breakInTime)) / (1000 * 60)
                        );
                      }
                    });
                    const totalHours = Math.floor(totalDurationMinutes / 60);
                    const totalMinutes = totalDurationMinutes % 60;
                    const formattedTotalDuration = `${totalHours} Hrs ${totalMinutes} Min`;

                    const final = calculateFinalTime(
                      totalDurationinOffice,
                      formattedTotalDuration
                    );

                    const formattedTotalMinutes = parseDurationToMinutes(
                      formattedTotalDuration
                    );
                    const totalOfficeMinutes = parseDurationToMinutes(
                      totalDurationinOffice
                    );

                    const finalMinutes =
                      totalOfficeMinutes - formattedTotalMinutes;
                    const finalDuration = formatDuration(finalMinutes);
                    return (
           
                      <Tr key={attendance._id}>
                        <Td style={{ textAlign: "center" }}>
                          <Button size={"xs"}>{index + 1}</Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button size={"xs"}>
                            {moment(attendance.date).format("Do MMM YYYY")}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          {attendance.loginTime ? (
                            <>
                              <Button size={"xs"} color="blue.500">
                                {new Date(
                                  attendance.loginTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </Button>
                            </>
                          ) : (
                            ""
                          )}
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          {attendance.logoutTime ? (
                            <>
                              <Button size={"xs"} color="red" opacity="0.9">
                                {new Date(
                                  attendance.logoutTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </Button>
                            </>
                          ) : (
                            ""
                          )}
                        </Td>

                        <Td style={{ textAlign: "center" }}>
                          {attendance.breakInTime &&
                          attendance.breakInTime.length > 0 ? (
                            <Popover>
                              <PopoverTrigger>
                                <Button size={"xs"} color="purple.500">
                                  {/* {new Date(
                                attendance.breakInTime[
                                  attendance.breakInTime.length - 1
                                ]
                              ).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                              })} */}
                                  Click To See
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent w={450}>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverBody>
                                  <Table
                                    variant="simple"
                                    colorScheme="green"
                                    boxShadow={"md"}
                                  >
                                    <Thead>
                                      <Tr boxShadow={"md"}>
                                        <Th
                                          boxShadow={"md"}
                                          style={{ textAlign: "center" }}
                                        >
                                          No
                                        </Th>
                                        <Th
                                          boxShadow={"md"}
                                          style={{ textAlign: "center" }}
                                        >
                                          In
                                        </Th>
                                        <Th
                                          boxShadow={"md"}
                                          style={{ textAlign: "center" }}
                                        >
                                          Out
                                        </Th>
                                        <Th
                                          boxShadow={"md"}
                                          style={{ textAlign: "center" }}
                                        >
                                          Duration
                                        </Th>
                                      </Tr>
                                    </Thead>
                                    <Tbody>
                                      {attendance.breakInTime.map(
                                        (breakInTime, index) => (
                                          <Tr key={index}>
                                            <Td style={{ textAlign: "center" }}>
                                              <Button
                                                size={"xs"}
                                                colorScheme="blue"
                                              >
                                                B{index + 1}
                                              </Button>
                                            </Td>
                                            <Td style={{ textAlign: "center" }}>
                                              <Button
                                                size={"xs"}
                                                colorScheme="green"
                                              >
                                                {new Date(
                                                  breakInTime
                                                ).toLocaleTimeString([], {
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                })}
                                              </Button>
                                            </Td>
                                            <Td style={{ textAlign: "center" }}>
                                              {attendance.breakOutTime &&
                                              attendance.breakOutTime.length >
                                                index &&
                                              attendance.breakOutTime[index] ? (
                                                <Button
                                                  size={"xs"}
                                                  colorScheme="yellow"
                                                >
                                                  {new Date(
                                                    attendance.breakOutTime[
                                                      index
                                                    ]
                                                  ).toLocaleTimeString([], {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  })}
                                                </Button>
                                              ) : (
                                                <span>-</span>
                                              )}
                                            </Td>
                                            <Td style={{ textAlign: "center" }}>
                                              {attendance.breakOutTime &&
                                              attendance.breakOutTime.length >
                                                index &&
                                              attendance.breakOutTime[index] ? (
                                                <Button
                                                  size={"xs"}
                                                  colorScheme="cyan"
                                                >
                                                  {Math.floor(
                                                    (new Date(
                                                      attendance.breakOutTime[
                                                        index
                                                      ]
                                                    ) -
                                                      new Date(breakInTime)) /
                                                      (1000 * 60)
                                                  )}{" "}
                                                  Min
                                                </Button>
                                              ) : (
                                                <span>-</span>
                                              )}
                                            </Td>
                                          </Tr>
                                        )
                                      )}
                                    </Tbody>
                                  </Table>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          ) : (
                            ""
                          )}
                        </Td>

                        <Td style={{ textAlign: "center" }}>
                          <Button size={"xs"}>{formattedTotalDuration}</Button>
                        </Td>

                        <Td style={{ textAlign: "center" }}>
                          {attendance.loginTime && attendance.logoutTime ? (
                            <>
                              <Button size={"xs"}>
                                {totalDurationinOffice}
                              </Button>
                            </>
                          ) : (
                            ""
                          )}
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          {finalDuration ? (
                            <Button size={"xs"}>{finalDuration}</Button>
                          ) : (
                            ""
                          )}
                        </Td>

                        <Td style={{ textAlign: "center" }}>
                          <Button
                            style={{
                              backgroundColor:
                                attendance.status === "Available"
                                  ? "green"
                                  : "red",
                              color: "white",
                              fontWeight: "bolder",
                            }}
                            size={"xs"}
                          >
                            {attendance.status}
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
            <Box>
              {filteredData.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Attendance Record Found For The Selected User
                </Alert>
              )}
            </Box>
          </TableContainer>
          {/* <Flex justifyContent={"flex-end"} mt={5}>
            <Pagination page={page} setPage={setPage} totalPage={totalPage} />
          </Flex> */}
        </Box>
      </Box>
    </Flex>
  );
};

export default AdminAttendence;
