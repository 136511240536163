import { Flex } from "@chakra-ui/react";
import React from "react";
import Sidebar from "../../Components/Sidebar";
import HomeDash from "./HomeDash/HomeDash";

const EmpDash = () => {
  return (
    <Flex>
      <Sidebar />
      <HomeDash />
    </Flex>
  );
};

export default EmpDash;
