import { Flex } from "@chakra-ui/react";
import React from "react";
import HRHomeDash from "./HRHomeDash/HRHomeDash";
import HRSidebar from "../../Components/HRSidebar";

const HRDash = () => {
  return (
    <Flex>
      <HRSidebar />
      <HRHomeDash />
    </Flex>
  );
};

export default HRDash;
