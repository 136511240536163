import { Flex } from "@chakra-ui/react";
import React from "react";
import AdminSidebar from "../../Components/AdminSidebar";
import AdminHomeDash from "./AdminHomeDash/AdminHomeDash";

const AdminDash = () => {

  return (
    <Flex>
    <AdminSidebar />
    <AdminHomeDash />
  </Flex>
  );
};

export default AdminDash;
