import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Wrap,
  WrapItem,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PaginationOther } from "../../../Components/PaginationOther";
import { getUsers, leaveapproval } from "../../../Redux/app/action";

const LeaveLetter = () => {
  const leavesData = useSelector((state) => state.AppReducer.leaves);

  const [otherPage, setotherPage] = useState(1);
  let otherperPage = 5;
  let othertotalPage = Math.ceil(leavesData.length / otherperPage);
  let endother = otherPage * otherperPage;
  let startother = endother - otherperPage;

  let filteredLetters = [...leavesData].reverse();

  let paginationDataLetters = filteredLetters.slice(startother, endother);

  const dispatch = useDispatch();
  const toast = useToast();
  const handleLetterStatusChange = (letterId) => {
    const payload = {
      leave_status: "Approved",
    };

    dispatch(leaveapproval(letterId, payload));
    toast({
      position: "top",
      title: "Leave Approved",
      description: "",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    window.location.reload();
  };
  const handleLetterStatusRejected = (letterId) => {
    const payload = {
      leave_status: "Rejected",
    };

    dispatch(leaveapproval(letterId, payload));
    toast({
      position: "top",
      title: "Leave Rejected",
      description: "",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    window.location.reload();
  };

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const users = useSelector((state) => state.AppReducer.users);

  const userPathMap = {};
  users.forEach((user) => {
    userPathMap[user.employee_id] = user.path;
  });

  // Now you can use the map to get the path for each task's employee_id
  paginationDataLetters.forEach((task) => {
    if (userPathMap.hasOwnProperty(task.employee_id)) {
      const path = userPathMap[task.employee_id];
      //console.log(`Employee ID: ${task.employee_id}, Path: ${path}`);
    } else {
      //console.log(`Employee ID: ${task.employee_id}, Path not found.`);
    }
  });

  return (
    <>
      <TableContainer>
        <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
          <Thead>
            <Tr boxShadow={"md"}>
              <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                Requested By
              </Th>
              <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                Letter Type
              </Th>
              <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                Applied On
              </Th>
              <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                From
              </Th>
              <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                To
              </Th>
              <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                Status
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {paginationDataLetters.length > 0 &&
              paginationDataLetters?.map((el) => {
                const path = userPathMap[el.employee_id];

                return (
                  <Tr key={el._id} boxShadow={"md"}>
                    <Link to="/leaverequest">
                      <Td style={{ textAlign: "center" }}>
                        <Wrap>
                          <WrapItem>
                            <Flex justifyContent={"space-around"} gap={2}>
                              <Avatar
                                size="xs"
                                name={el.employee_id}
                                src={
                                  path
                                    ? `https://project360-backend.onrender.com/${path}`
                                    : `https://project360-backend.onrender.com/${path}`
                                }
                              />
                              <Heading size={"xs"}>
                                <Button size={"xs"} fontWeight={"bold"}>
                                  {el.employee_id}
                                </Button>
                              </Heading>
                            </Flex>
                          </WrapItem>
                        </Wrap>
                      </Td>
                    </Link>
                    <Td style={{ textAlign: "center" }}>
                      <Button size={"xs"} fontWeight={"bold"}>
                        {el.type}
                      </Button>
                    </Td>
                    <Td style={{ textAlign: "center" }}>
                      <Button size={"xs"} fontWeight={"bold"}>
                        {new Date(el.currentDate).toDateString()}
                      </Button>
                    </Td>
                    <Td style={{ textAlign: "center" }}>
                      <Button size={"xs"} fontWeight={"bold"}>
                        {new Date(el.start_date).toDateString()}
                      </Button>
                    </Td>

                    <Td style={{ textAlign: "center" }}>
                      <Button size={"xs"} fontWeight={"bold"}>
                        {new Date(el.end_date).toDateString()}
                      </Button>
                    </Td>

                    <Td style={{ textAlign: "center" }}>
                      {el.leave_status !== "Approved" ? (
                        <Popover>
                          <PopoverTrigger>
                            <Button
                              disabled={el.leave_status === "Approved"}
                              color={
                                el.leave_status === "Rejected"
                                  ? "red"
                                  : el.leave_status === "Pending"
                                  ? "blue"
                                  : "green"
                              }
                              size={"xs"}
                              fontWeight={"bold"}
                            >
                              {el.leave_status}
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader>Confirmation!</PopoverHeader>
                            <PopoverBody>
                              Are you sure you want to approve?
                            </PopoverBody>
                            <PopoverFooter
                              display="flex"
                              justifyContent="flex-end"
                              gap={2}
                            >
                              <ButtonGroup size="xs">
                                <Button
                                  color="red"
                                  _hover={{ color: "white", bgColor: "red" }}
                                  onClick={() =>
                                    handleLetterStatusRejected(el._id)
                                  }
                                  fontWeight={"bold"}
                                >
                                  No
                                </Button>
                              </ButtonGroup>
                              <ButtonGroup size="xs">
                                <Button
                                  color="green"
                                  _hover={{ color: "white", bgColor: "green" }}
                                  onClick={() =>
                                    handleLetterStatusChange(el._id)
                                  }
                                  fontWeight={"bold"}
                                >
                                  Yes
                                </Button>
                              </ButtonGroup>
                            </PopoverFooter>
                          </PopoverContent>
                        </Popover>
                      ) : (
                        <Button
                          color={
                            el.leave_status === "Rejected"
                              ? "red"
                              : el.leave_status === "Pending"
                              ? "blue"
                              : "green"
                          }
                          disabled
                          size={"xs"}
                          fontWeight={"bold"}
                        >
                          {el.leave_status}
                        </Button>
                      )}
                    </Td>
                    {/* <Td>{el.message}</Td> */}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
        <Box>
          {paginationDataLetters.length === 0 && (
            <Alert
              status="info"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mt={4}
            >
              <AlertIcon boxSize={8} />
              No Record Found
            </Alert>
          )}
        </Box>
      </TableContainer>
      <Flex justifyContent={"flex-end"} mt={5}>
        <PaginationOther
          otherPage={otherPage}
          setotherPage={setotherPage}
          othertotalPage={othertotalPage}
        />
      </Flex>
    </>
  );
};

export default LeaveLetter;
