import thunk from "redux-thunk";
import { authenticationReducer } from "./auth/reducer";
import { reducer as AppReducer } from "./app/reducer";
import { legacy_createStore, applyMiddleware, combineReducers } from "redux";

const rootReducer = combineReducers({
  authentication: authenticationReducer,

  AppReducer,
});
const store = legacy_createStore(rootReducer,loadState(), applyMiddleware(thunk));


store.subscribe(() => {
  saveState(store.getState());
});

function saveState(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (error) {
    // Handle errors while saving
  }
}

function loadState() {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
}

export { store };
