import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Navbar from "../../../Components/Navbar";
import { ChevronRightIcon } from "@chakra-ui/icons";
import AdminSidebar from "../../../Components/AdminSidebar";
import { useDispatch } from "react-redux";
import { registerUser } from "../../../Redux/app/action";
import { useNavigate } from "react-router-dom";
import { AiOutlineCamera } from "react-icons/ai";
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
} from "react-icons/io";

const OnBoardingUser = () => {
  const InputStyle = {
    borderRadius: "md",
    borderWidth: "2px",
    borderColor: "gray.300",
    py: "2",
    px: "4",
    variant: "unstyled",
    // required: true,
  };

  const BoxStyle = {
    position: "absolute",
    top: "-8px",
    left: "8px",
    pointerEvents: "none",
    color: "green",
    fontSize: "sm",
    bg: "white",
    px: "1",
  };

  const [formValues, setFormValues] = useState({});
  //console.log("onboard", formValues);
  const dispatch = useDispatch();
  const [fileError, setFileError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();

  // const handleInputChangeee = (e) => {
  //   const { name, value } = e.target;

  //   setFormValues({
  //     ...formValues,
  //     [name]: value,
  //   });

  //   // if (name === "file") {
  //   //   const file = e.target.files[0];

  //   //   const allowedExtensions = [".jpeg", ".jpg", ".png"];
  //   //   const fileExtension = file.name
  //   //     .substring(file.name.lastIndexOf("."))
  //   //     .toLowerCase();
  //   //   if (!allowedExtensions.includes(fileExtension)) {
  //   //     setFileError("Invalid file type. Please select a JPEG or PNG image.");
  //   //     setSelectedFile(null);
  //   //     return;
  //   //   }

  //   //   setSelectedFile(URL.createObjectURL(file));
  //   //   setFileError("");

  //   //   setFormValues({
  //   //     ...formValues,
  //   //     file: file,
  //   //   });
  //   // }
  //   // if (name === "confirm_password") {
  //   //   if (value !== formValues.password) {
  //   //   } else {
  //   //     toast({
  //   //       position: "top",
  //   //       title: "Password Matched",
  //   //       status: "success",
  //   //       duration: 3000,
  //   //       isClosable: true,
  //   //     });
  //   //   }
  //   // } else {
  //   //   setFormValues({
  //   //     ...formValues,
  //   //     [name]: value,
  //   //   });
  //   // }
  //   // if (name === "confirm_password") {
  //   //   if (value !== formValues.password) {
  //   //   } else {
  //   //     toast({
  //   //       position: "top",
  //   //       title: "Password Matched",
  //   //       status: "success",
  //   //       duration: 3000,
  //   //       isClosable: true,
  //   //     });
  //   //   }
  //   // }
  // };

  // const handleOnboarddd = (e) => {
  //   e.preventDefault();
  //   dispatch(registerUser(formValues));
  //   toast({
  //     position: "top",
  //     title: "User Registered Successfully",
  //     status: "success",
  //     duration: 3000,
  //     isClosable: true,
  //   });
  //   setTimeout(() => {
  //     navigate("/employees");
  //   }, 3000);
  // };
  const [passwordMatch, setPasswordMatch] = useState(false);
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "file") {
      const file = files[0];

      const allowedExtensions = [".jpeg", ".jpg", ".png"];
      const fileExtension = file.name
        .substring(file.name.lastIndexOf("."))
        .toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        setFileError("Invalid file type. Please select a JPEG or PNG image.");
        setSelectedFile(null);
        return;
      }

      setSelectedFile(URL.createObjectURL(file));
      setFileError("");

      setFormValues({
        ...formValues,
        file: file,
      });
    } else {
      //   setFormValues({
      //     ...formValues,
      //     [name]: value,
      //   });
      // }
      if (name === "confirm_password") {
        setPasswordMatch(value === formValues.password);
      }
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleOnboard = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("file", formValues.file);
      formData.append("first_name", formValues.first_name);
      formData.append("last_name", formValues.last_name);
      formData.append("father_name", formValues.father_name);
      formData.append("email", formValues.email);
      formData.append("mother_name", formValues.mother_name);
      formData.append("employee_id", formValues.employee_id);
      formData.append("alternate_email", formValues.alternate_email);
      formData.append("phone", formValues.phone);
      formData.append("alternate_phone", formValues.alternate_phone);
      formData.append("dob", formValues.dob);
      formData.append("gender", formValues.gender);
      formData.append("country", formValues.country);
      formData.append("city", formValues.city);
      formData.append("state", formValues.state);
      formData.append("zip_code", formValues.zip_code);
      formData.append("sudo_name", formValues.sudo_name);
      formData.append("blood_group", formValues.blood_group);
      formData.append("designation", formValues.designation);
      formData.append("department", formValues.department);
      formData.append("marital_status", formValues.marital_status);
      formData.append("doj", formValues.doj);
      formData.append("password", formValues.password);
      formData.append("confirm_password", formValues.confirm_password);
      formData.append("role", formValues.role);
      formData.append("account_holder_name", formValues.account_holder_name);
      formData.append("bank_name", formValues.bank_name);
      formData.append("ifsc", formValues.ifsc);
      formData.append("account_number", formValues.account_number);
      formData.append("branch_name", formValues.branch_name);
      formData.append("upi_id", formValues.upi_id);
      formData.append("registered_phone", formValues.registered_phone);
      formData.append("aadhar", formValues.aadhar);
      await dispatch(registerUser(formData));
      //console.log("userregistration", formData);
      toast({
        position: "top",
        title: "User Registered Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setTimeout(() => {
        navigate("/employees");
      }, 3000);
    } catch (error) {
      toast({
        position: "top",
        title: "An error occurred while registering the user.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex>
      <AdminSidebar />
      <Box
        pl={{ base: "none", md: "10" }}
        pt={{ base: "none", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Users
        </Heading>
        <Flex justifyContent={"space-between"}>
          <Breadcrumb
            spacing="8px"
            mt={6}
            mb={6}
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/employee">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="/employees">User</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="#">User Details</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Flex>
        <form onSubmit={handleOnboard}>
          <Box>
            <Flex
              direction={{ base: "column", md: "row" }}
              justifyContent={{ base: "flex-start", md: "space-between" }}
              gap={5}
            >
              <Box
                w={{ base: "100%", md: "50%" }}
                borderRadius={10}
                boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
                p={5}
              >
                <Flex justifyContent={"center"}>
                  <Box p={5} borderRadius={10}>
                    <FormControl>
                      <label htmlFor="image">
                        <Box
                          as="div"
                          display="inline-flex"
                          alignItems="center"
                          justifyContent="center"
                          width="150px"
                          height="150px"
                          borderRadius="full"
                          border="2px dashed"
                          borderColor="gray.300"
                          position="relative"
                          overflow="hidden"
                          cursor="pointer"
                        >
                          {selectedFile ? (
                            <Image
                              src={selectedFile}
                              alt="Selected file"
                              width="100%"
                              height="100%"
                              objectFit="cover"
                            />
                          ) : (
                            <>
                              <AiOutlineCamera size={24} color="gray" />
                              <Box
                                position="absolute"
                                top="50%"
                                left="50%"
                                transform="translate(-50%, -50%)"
                              >
                                <Box as="span" fontSize="sm" color="gray.500">
                                  Click to upload
                                </Box>
                              </Box>
                            </>
                          )}
                          <Input
                            type="file"
                            accept=".jpeg, .jpg, .png"
                            onChange={handleInputChange}
                            id="file"
                            name="file"
                            position="absolute"
                            opacity="0"
                            width="100%"
                            height="100%"
                            cursor="pointer"
                          />
                        </Box>
                      </label>
                      <FormHelperText color={"green"}>
                        Allowed *.jpeg, *.jpg, *.png, *.PNG
                      </FormHelperText>
                      {fileError && (
                        <Box color="red" fontSize="sm" mt={1}>
                          {fileError}
                        </Box>
                      )}
                    </FormControl>
                  </Box>
                </Flex>
                <Flex
                  direction={{ base: "column", md: "row" }}
                  justifyContent={{ base: "flex-start", md: "space-between" }}
                >
                  <Box w={{ base: "100%", md: "45%" }} p={5} borderRadius={10}>
                    <Box textAlign={"start"}>
                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          id="first_name"
                          name="first_name"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>First Name</Box>
                      </Box>
                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          id="father_name"
                          name="father_name"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Father's Name</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="email"
                          id="email"
                          name="email"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Email Address</Box>
                      </Box>
                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="email"
                          id="alternate_email"
                          name="alternate_email"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Alternate Email Address</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="date"
                          id="dob"
                          name="dob"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Date Of Birth</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          id="city"
                          name="city"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>City</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          maxLength={6}
                          id="zip_code"
                          name="zip_code"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Zip Code</Box>
                      </Box>
                      <Box position="relative" mb={5}>
                        <Select
                          placeholder=""
                          required
                          name="role"
                          id="role"
                          onChange={handleInputChange}
                          value={formValues.role || ""}
                        >
                          <option value=""></option>
                          <option value="employee">Employee</option>
                          <option value="hr">HR</option>
                          <option value="admin">Admin</option>
                        </Select>
                        <Box {...BoxStyle}>Role</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          id="blood_group"
                          name="blood_group"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Blood Group</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Select
                          placeholder=""
                          required
                          name="department"
                          id="department"
                          onChange={handleInputChange}
                          value={formValues.department || ""}
                        >
                          <option value=""></option>
                          <option value="Software Development">
                            Software Development
                          </option>
                          <option value="Lead Generation">
                            Lead Generation
                          </option>
                          <option value="Customer Support">
                            Customer Support
                          </option>
                          <option value="HR">HR</option>
                        </Select>
                        <Box {...BoxStyle}>Department</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="date"
                          id="doj"
                          name="doj"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Date Of Joining</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="password"
                          id="password"
                          name="password"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Create Password</Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box w={{ base: "100%", md: "45%" }} p={5} borderRadius={10}>
                    <Box textAlign={"start"}>
                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          id="last_name"
                          name="last_name"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Last Name</Box>
                      </Box>
                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          id="mother_name"
                          name="mother_name"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Mother's Name</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          maxLength={10}
                          id="phone"
                          name="phone"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Phone</Box>
                      </Box>
                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          maxLength={10}
                          id="alternate_phone"
                          name="alternate_phone"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Alternate Phone</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Select
                          placeholder=""
                          required
                          name="gender"
                          id="gender"
                          onChange={handleInputChange}
                          value={formValues.gender || ""}
                        >
                          <option value=""></option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </Select>
                        <Box {...BoxStyle}>Gender</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          id="state"
                          name="state"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>State</Box>
                      </Box>
                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          id="country"
                          name="country"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Country</Box>
                      </Box>
                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          id="sudo_name"
                          name="sudo_name"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Sudo Name</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Select
                          placeholder=""
                          required
                          name="marital_status"
                          id="marital_status"
                          onChange={handleInputChange}
                          value={formValues.marital_status || ""}
                        >
                          <option value=""></option>
                          <option value="Married">Married</option>
                          <option value="Unmarried">Unmarried</option>
                        </Select>
                        <Box {...BoxStyle}>Marital Status</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          id="designation"
                          name="designation"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Designation</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          id="employee_id"
                          name="employee_id"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Employee ID</Box>
                      </Box>

                      {/* <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="password"
                          id="confirm_password"
                          name="confirm_password"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Confirm Password</Box>
                      </Box> */}
                      <Box position="relative" mb={5}>
                        <InputGroup>
                          <Input
                            {...InputStyle}
                            type="password"
                            id="confirm_password"
                            name="confirm_password"
                            onChange={handleInputChange}
                          />
                          <InputRightElement
                            pointerEvents="none"
                            children={
                              passwordMatch ? (
                                <IoMdCheckmarkCircleOutline color="green" />
                              ) : (
                                <IoMdCloseCircleOutline color="red" />
                              )
                            }
                          />
                        </InputGroup>

                        <Box {...BoxStyle}>Confirm Password</Box>
                        {/* {passwordMatch ? (
                          <IoMdCheckmarkCircleOutline color="green" />
                        ) : (
                          <IoMdCloseCircleOutline color="red" />
                        )} */}
                      </Box>
                    </Box>
                  </Box>
                </Flex>
              </Box>
              <Box
                w={{ base: "100%", md: "50%" }}
                borderRadius={10}
                boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
                p={5}
              >
                <Flex
                  direction={{ base: "column", md: "row" }}
                  justifyContent={{ base: "flex-start", md: "space-between" }}
                >
                  <Box w={{ base: "100%", md: "45%" }} p={5} borderRadius={10}>
                    <Box textAlign={"start"}>
                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          id="account_holder_name"
                          name="account_holder_name"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Account Holder Name</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          id="ifsc"
                          name="ifsc"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>IFSC</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          id="branch_name"
                          name="branch_name"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Branch</Box>
                      </Box>
                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          id="aadhar"
                          name="aadhar"
                          maxLength={12}
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Aadhar Number</Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box w={{ base: "100%", md: "45%" }} p={5} borderRadius={10}>
                    <Box textAlign={"start"}>
                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          id="bank_name"
                          name="bank_name"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Bank Name</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          id="account_number"
                          name="account_number"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Account Number</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          id="upi_id"
                          name="upi_id"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>UPI ID</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Input
                          {...InputStyle}
                          type="text"
                          maxLength={10}
                          id="registered_phone"
                          name="registered_phone"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Registered Phone</Box>
                      </Box>
                    </Box>
                  </Box>
                </Flex>
                <Flex justifyContent={"flex-end"}>
                  <Button colorScheme="green" size={"sm"} type="submit">
                    OnBoard Employee
                  </Button>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </form>
      </Box>
    </Flex>
  );
};

export default OnBoardingUser;
