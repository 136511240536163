import { Box, Flex, Input } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUsers } from "../../../Redux/app/action";

const LeftForm = () => {
  const InputStyle = {
    borderBottomWidth: "2px",
    borderBottomColor: "gray.300",
    py: "1",
    mt:"3",
    px: "4",
    variant: "unstyled",
    required: true,
    
  };

  const BoxStyle = {
    position: "absolute",
    top: "-8px",
    left: "8px",
    pointerEvents: "none",
    color: "green",
    fontSize: "sm",
    bg: "white",
    px: "1",
  };

  const { id } = useParams();
  //console.log("iiddd", id);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);
  const users = useSelector((state) => state.AppReducer.users);

  const singleUser = users.filter((obj) => obj._id === id);
  //console.log("single by id", singleUser);

  const [formValues, setFormValues] = useState(singleUser);
  //console.log("sss", formValues);

  return (
    <>
      <form>
        {formValues?.map((el, index) => (
          
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent={{ base: "flex-start", md: "space-between" }}
            key={el._id}
          >
            <Box w={{ base: "100%", md: "45%" }} p={5} borderRadius={10}>
              <Box textAlign={"start"}>
                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    type="text"
                    id="first_name"
                    name="first_name"
                    value={el.first_name}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      // setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>First Name</Box>
                </Box>
                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    type="text"
                    id="father_name"
                    name="father_name"
                    value={el.father_name}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Father's Name</Box>
                </Box>

                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    type="email"
                    id="email"
                    name="email"
                    value={el.email}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Email Address</Box>
                </Box>
                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    type="email"
                    id="alternate_email"
                    name="alternate_email"
                    value={el.alternate_email}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Alternate Email Address</Box>
                </Box>

                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    // type="date"
                    id="dob"
                    name="dob"
                    value={new Date(el.dob).toDateString()}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Date Of Birth</Box>
                </Box>

                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    type="text"
                    id="city"
                    name="city"
                    value={el.city}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>City</Box>
                </Box>

                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    type="text"
                    maxLength={6}
                    id="zip_code"
                    name="zip_code"
                    value={el.zip_code}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Zip Code</Box>
                </Box>
                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    textTransform={"capitalize"}
                    type="text"
                    id="role"
                    name="role"
                    value={el.role}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Role</Box>
                </Box>

                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    type="text"
                    id="blood_group"
                    name="blood_group"
                    value={el.blood_group}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Blood Group</Box>
                </Box>

                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    type="text"
                    id="department"
                    name="department"
                    value={el.department}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Department</Box>
                </Box>

                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    // type="date"
                    id="doj"
                    name="doj"
                    value={new Date(el.doj).toDateString()}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Date Of Joining</Box>
                </Box>

                {/* <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    // type="password"
                    id="password"
                    name="password"
                    value={el.password}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Create Password</Box>
                </Box> */}
              </Box>
            </Box>
            <Box w={{ base: "100%", md: "45%" }} p={5} borderRadius={10}>
              <Box textAlign={"start"}>
                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    type="text"
                    id="last_name"
                    name="last_name"
                    value={el.last_name}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Last Name</Box>
                </Box>
                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    type="text"
                    id="mother_name"
                    name="mother_name"
                    value={el.mother_name}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Mother's Name</Box>
                </Box>

                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    type="text"
                    maxLength={10}
                    id="phone"
                    name="phone"
                    value={el.phone}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Phone</Box>
                </Box>
                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    type="text"
                    maxLength={10}
                    id="alternate_phone"
                    name="alternate_phone"
                    value={el.alternate_phone}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Alternate Phone</Box>
                </Box>

                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    type="text"
                    id="gender"
                    name="gender"
                    value={el.gender}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Gender</Box>
                </Box>

                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    type="text"
                    id="state"
                    name="state"
                    value={el.state}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>State</Box>
                </Box>
                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    type="text"
                    id="country"
                    name="country"
                    value={el.country}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Country</Box>
                </Box>
                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    type="text"
                    id="sudo_name"
                    name="sudo_name"
                    value={el.sudo_name}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Sudo Name</Box>
                </Box>

                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    type="text"
                    id="marital_status"
                    name="marital_status"
                    value={el.marital_status}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Marital Status</Box>
                </Box>

                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    type="text"
                    id="designation"
                    name="designation"
                    value={el.designation}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Designation</Box>
                </Box>

                <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    type="text"
                    id="employee_id"
                    name="employee_id"
                    value={el.employee_id}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Employee ID</Box>
                </Box>

                {/* <Box position="relative" mb={5}>
                  <Input
                    {...InputStyle}
                    // type="password"
                    id="confirm_password"
                    name="confirm_password"
                    value={el.confirm_password}
                    onChange={(e) => {
                      const updatedValues = [...formValues];
                      updatedValues[index] = e.target.value;
                      //setFormValues(updatedValues);
                    }}
                  />
                  <Box {...BoxStyle}>Confirm Password</Box>
                </Box> */}
              </Box>
            </Box>
          </Flex>
        ))}
      </form>
    </>
  );
};

export default LeftForm;
