import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Tooltip,
  ModalOverlay,
  ModalFooter,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Card,
  CardBody,
  Text,
  Icon,
  Stack,
  SimpleGrid,
  Avatar,
  Wrap,
  WrapItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  PopoverFooter,
  ButtonGroup,
  AvatarGroup,
  Badge,
  Select as SelectChakra,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../../Components/Navbar";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTask,
  editTask,
  getUsers,
  postTaskTest,
  seeAllTask,
} from "../../../Redux/app/action";
import AdminSidebar from "../../../Components/AdminSidebar";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin2Line } from "react-icons/ri";
import { Pagination } from "../../../Components/Pagination";
import Select from "react-select";
import moment from "moment";
import {
  FaRegFile,
  FaRegFileAudio,
  FaRegFileExcel,
  FaRegFilePdf,
  FaRegFilePowerpoint,
  FaRegFileVideo,
  FaRegFileWord,
} from "react-icons/fa";
import { BsImage } from "react-icons/bs";
import axios from "axios";

const AdminAssignTask = () => {
  const InputStyle = {
    borderRadius: "md",
    borderWidth: "2px",
    borderColor: "gray.300",
    py: "2",
    px: "4",
    variant: "unstyled",
  };

  const BoxStyle = {
    position: "absolute",
    top: "-8px",
    left: "8px",
    pointerEvents: "none",
    color: "green",
    fontSize: "sm",
    bg: "white",
    px: "1",
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);
  const users = useSelector((state) => state.AppReducer.users);
  //console.log("users",users)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSidebarOpen,
    onOpen: onSidebarOpen,
    onClose: onSidebarClose,
  } = useDisclosure();

  const OverlayTwo = () => <ModalOverlay bg="whiteAlpha.100" />;

  const [overlay, setOverlay] = useState(<OverlayTwo />);

  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  const btnRef = useRef();

  const toast = useToast();

  useEffect(() => {
    dispatch(seeAllTask());
  }, [dispatch]);

  const taskData = useSelector((state) => state.AppReducer.taskData);
  //console.log("ttdata", taskData);

  // Handling Task Delete Here

  const handleTaskDelete = async (_id) => {
    try {
      await dispatch(deleteTask(_id));
      // navigate("/admintask");
      // window.location.reload();

      toast({
        position: "top",
        title: "Task Deleted Successfully",
        description: "",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Deleting Task",
        description: "An error occurred while deleting the user.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Handling Task Delete End Here

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  let perPage = 10;

  // Check if taskData is an array, otherwise initialize it as an empty array
  let taskDataArray = Array.isArray(taskData) ? taskData : [taskData];

  // Calculate total pages based on the filtered data
  let totalPage = Math.ceil(taskDataArray.length / perPage);
  let end = page * perPage;
  let start = end - perPage;

  // Apply filtering on the taskDataArray and handle non-array cases
  let filteredData = Array.isArray(taskDataArray)
    ? taskDataArray
        .filter(
          (el) =>
            (el.title &&
              el.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (el.description &&
              el.description
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (el.task_status &&
              el.task_status
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (el.end_date &&
              el.end_date.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (el.start_date &&
              el.start_date
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (el.priority &&
              el.priority.toLowerCase().includes(searchQuery.toLowerCase()))
        )
        .reverse()
    : [taskDataArray];

  // Apply pagination to the filteredData
  let paginationData = filteredData.slice(start, end);

  // If taskData is not an array, show paginationData
  let displayData = Array.isArray(taskDataArray)
    ? paginationData
    : [paginationData];
  //console.log("displaytask", displayData)

  // Now you can use the displayData in your UI to show the filtered and paginated data

  // Handling Pagination End Here

  const [hoveredIdTitle, setHoveredIdTitle] = useState(null);

  const handleHoverTitle = (id) => {
    setHoveredIdTitle(id);
  };

  const handleMouseLeaveTitle = () => {
    setHoveredIdTitle(null);
  };

  const [hoveredIdEmployee, setHoveredIdEmployee] = useState(null);

  const handleHoverEmployee = (id) => {
    setHoveredIdEmployee(id);
  };

  const handleMouseLeaveEmployee = () => {
    setHoveredIdEmployee(null);
  };

  const [selectedData, setSelectedData] = useState(paginationData);

  const handleAttachment = (paginationData) => {
    setSelectedData(paginationData);
  };

  // const userPathMap = {};
  // users.forEach((user) => {
  //   userPathMap[user.employee_id] = user.path;
  // });

  // // Now you can use the map to get the path for each task's employee_id
  // paginationData.forEach((task) => {
  //   if (userPathMap.hasOwnProperty(task.employee)) {
  //     const path = userPathMap[task.employee];
  //     console.log(`Employee ID: ${task.employee_id}, Path: ${path}`);
  //   } else {
  //     //console.log(`Employee ID: ${task.employee_id}, Path not found.`);
  //   }
  // });
  // console.log("userPathMap",userPathMap)

  const userPathMap = {};
  users.forEach((user) => {
    userPathMap[user.sudo_name] = user.path;
  });
  //console.log("userPathMap", userPathMap);
  // Now you can use the map to get the path for each task's employee_id
  paginationData.forEach((task) => {
    // const user = users.find((user) => user.sudo_name === task.employee);
    const user = users.find((user) => task.employee.includes(user.sudo_name));

    if (user) {
      const path = user.path;
      //console.log(`Employee ID: ${task.employee}, Path: ${path}`);
    } else {
      // Handle the case when the user is not found
      //console.log(`Employee ID: ${task.employee}, Path not found.`);
    }
  });

  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [start_date, setstartDate] = useState("");
  const [end_date, setendDate] = useState("");
  const [priority, setPriority] = useState("");

  const [selectedHourOption, setSelectedHourOption] = useState("");
  //console.log("selectedHourOption", selectedHourOption);
  const [selectedQuarterOption, setSelectedQuarterOption] = useState("");
  const todayDate = new Date().toISOString().split("T")[0];

  const handleHourSelectChange = (value) => {
    setSelectedHourOption(value);
    setSelectedQuarterOption("");
  };

  const handleQuarterSelectChange = (value) => {
    setSelectedQuarterOption(value);
    setSelectedHourOption("");
  };

  const handleAddMultipleEmployeeTask = async () => {
    try {
      const formData = new FormData();

      formData.append("title", title);
      formData.append("description", description);
      formData.append("start_date", start_date);
      formData.append("end_date", end_date);
      formData.append("priority", priority);
      formData.append("hourly", selectedHourOption);
      formData.append("quarterly", selectedQuarterOption);
      for (const department of selectedDepartments) {
        formData.append("department", department);
      }
      for (const employee of selectedEmployees) {
        formData.append("employee", employee);
      }

      for (const file of files) {
        formData.append("files", file);
      }

      const response = await axios.post(
        `https://project360-backend.onrender.com/postmultiple`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      //console.log("Response from server task:", response.data);
      toast({
        position: "top",
        title: "Task Added",
        description: "The task has been added.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      //console.error("Error adding task:", error);
      toast({
        position: "top",
        title: "Error",
        description: "An error occurred while adding the task.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const options = [
    { value: "All", label: "All" },
    {
      value: "Software Development",
      label: "Software Development",
    },
    {
      value: "Lead Generation",
      label: "Lead Generation",
    },
    {
      value: "Customer Support",
      label: "Customer Support",
    },
  ];

  const priorityOptions = [
    {
      value: "Low",
      label: "Low",
    },
    {
      value: "Medium",
      label: "Medium",
    },
    {
      value: "High",
      label: "High",
    },
  ];

  const filteredEmployeesss = users.filter((user) => {
    if (
      selectedDepartments.length === 0 ||
      selectedDepartments.includes("All")
    ) {
      return true;
    } else {
      return selectedDepartments.includes(user.department);
    }
  });
  //console.log("filteredEmployeesss",filteredEmployeesss)

  const handleDepartmentChange = (selectedOptions) => {
    const selectedDepartmentValues = selectedOptions.map(
      (option) => option.value
    );

    if (selectedDepartmentValues.includes("All")) {
      setSelectedDepartments(
        options
          .filter((option) => option.value !== "All")
          .map((option) => option.value)
      );
      setSelectedEmployees(
        filteredEmployeesss.map((employee) => employee.sudo_name)
      );
    } else {
      setSelectedDepartments(selectedDepartmentValues);

      setSelectedEmployees((prevSelectedEmployees) =>
        prevSelectedEmployees.filter((employee) => {
          const employeeObj = filteredEmployeesss.find(
            (emp) => emp.sudo_name === employee
          );
          return (
            employeeObj &&
            selectedDepartmentValues.includes(employeeObj.department)
          );
        })
      );
    }
  };

  const handleEmployeeChange = (selectedOptions) => {
    const selectedEmployeeValues = selectedOptions.map(
      (option) => option.value
    );

    if (selectedEmployeeValues.includes("All")) {
      setSelectedEmployees(
        filteredEmployeesss.map((employee) => employee.sudo_name)
      );
    } else {
      setSelectedEmployees(selectedEmployeeValues);
    }
  };

  const handleInputTitle = (e) => {
    const { name, value } = e.target;

    if (name === "title") {
      setTitle(value);
    }
  };

  const handleInputDescription = (e) => {
    const { name, value } = e.target;

    if (name === "description") {
      setDescription(value);
    }
  };
  const handleInputFiles = (e) => {
    const filesArray = Array.from(e.target.files);
    if (files.length > 4) {
      toast({
        position: "top",
        title: "Max 4 Files",
        description: "",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setFiles(filesArray);
  };

  const handleInputStart = (e) => {
    const { name, value } = e.target;

    if (name === "start_date") {
      setstartDate(value);
    }
  };

  const handleInputEnd = (e) => {
    const { name, value } = e.target;

    if (name === "end_date") {
      setendDate(value);
    }
  };

  const handleInputPriority = (selectedOption) => {
    // Use selectedOption to access the selected value
    const value = selectedOption ? selectedOption.value : null;
    setPriority(value);
  };

  const handleNavigateToAllTasks = () => {
    window.location.href = "/alltasks";
  };

  const [selectedItem, setSelectedItem] = useState(null);
  // console.log("selected", selectedItem);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    priority: "",
    start_date: "",
    end_date: "",
    hourly: "",
    quarterly: "",
  });
  //console.log("fffff", formData);

  const handleTaskUpdate = async (_id) => {
    try {
      await dispatch(editTask(selectedItem._id, formData));
      setSelectedItem(null);
      setFormData({
        title: "",
        description: "",
        priority: "",
        start_date: "",
        end_date: "",
        hourly: "",
        quarterly: "",
      });
      //navigate("/admintask");
      //window.location.reload();
      toast({
        position: "top",
        title: "Task Updated Successfully",
        description: "",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Updating Task",
        description: "An error occurred while updating the task.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (el) => {
    setSelectedItem(el);

    setFormData({
      title: el.title,
      description: el.description,
      priority: el.priority,
      start_date: el.start_date,
      end_date: el.end_date,
      hourly: el.hourly,
      quarterly: el.quarterly,
    });
  };

  const editPriorityOptions = [
    { value: "", label: "" },
    { value: "Low", label: "Low" },
    { value: "Medium", label: "Medium" },
    { value: "High", label: "High" },
  ];

  const editHourlyOptions = [
    { value: "", label: "" },
    { value: "30 Min", label: "30 Min" },
    { value: "1 Hour", label: "1 Hour" },
    { value: "2 Hour", label: "2 Hour" },
    { value: "3 Hour", label: "3 Hour" },
    { value: "4 Hour", label: "4 Hour" },
    { value: "5 Hour", label: "5 Hour" },
  ];

  const editQuarterlyOptions = [
    { value: "", label: "" },
    { value: "1st", label: "1st quarter" },
    { value: "2nd", label: "2nd quarter" },
    { value: "3rd Hour", label: "3rd quarter" },
    { value: "4th", label: "4th quarter" },
  ];

  const [isOpenModal1, setIsOpenModal1] = useState(false);
  const onOpenModal1 = () => setIsOpenModal1(true);
  const onCloseModal1 = () => setIsOpenModal1(false);

  return (
    <Flex>
      <AdminSidebar />
      <Box
        pl={{ base: "none", md: "10" }}
        pt={{ base: "none", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Tasks
        </Heading>
        <Flex justifyContent={"space-between"}>
          <Breadcrumb
            spacing="8px"
            mt={6}
            mb={6}
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/admin">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="#">Task</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex justifyContent={"space-between"} gap={10}>
            <Box>
              <Button
                ref={btnRef}
                // colorScheme="teal"
                // onClick={onOpen}
                onClick={onOpenModal1}
                bgGradient="linear(to-r, green.300,  teal.100)"
                _hover={{ bgGradient: "linear(to-r, green.100,  green.400)" }}
                w={400}
              >
                Assign Task
              </Button>

              <Modal isOpen={isOpenModal1} onClose={onCloseModal1} size={"2xl"}>
                <ModalOverlay />
                <form
                  onSubmit={handleAddMultipleEmployeeTask}
                  encType="multipart/form-data"
                >
                  <ModalContent>
                    <ModalHeader>Assign Task</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <Box position="relative">
                        <Input
                          {...InputStyle}
                          required
                          type="text"
                          id="title"
                          name="title"
                          value={title}
                          onChange={handleInputTitle}
                        />
                        <Box {...BoxStyle}>Title*</Box>
                      </Box>
                      <br />
                      <Box position="relative">
                        <Textarea
                          {...InputStyle}
                          required
                          type="text"
                          id="description"
                          name="description"
                          value={description}
                          onChange={handleInputDescription}
                        />
                        <Box {...BoxStyle}>Description*</Box>
                      </Box>
                      <br />
                      <Box position="relative">
                        <Input
                          {...InputStyle}
                          type="file"
                          id="file"
                          name="file"
                          onChange={handleInputFiles}
                          multiple
                        />
                        <Box {...BoxStyle}>Attach Files</Box>
                        {files.length === 0 && (
                          <Box fontSize="xs" mt="2" color={"red"}>
                            Max 4 files can be selected.
                          </Box>
                        )}
                        {files && files.length > 0 && files.length <= 4 && (
                          <Box fontSize="xs" mt="2">
                            {files.length} Files selected.
                          </Box>
                        )}
                      </Box>
                      <br />

                      <Box position="relative" mb={5}>
                        <Select
                          placeholder=""
                          required
                          name="priority"
                          id="priority"
                          onChange={handleInputPriority}
                          value={priorityOptions.find(
                            (option) => option.value === priority
                          )}
                          options={priorityOptions}
                        />

                        <Box {...BoxStyle}>Priority*</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Select
                          placeholder=""
                          required
                          isMulti
                          name="department"
                          id="department"
                          onChange={handleDepartmentChange}
                          value={selectedDepartments.map((department) => ({
                            value: department,
                            label: department,
                          }))}
                          options={options}
                        />
                        <Box {...BoxStyle}>Select Department*</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Select
                          placeholder=""
                          required
                          isMulti
                          name="employee"
                          id="employee"
                          onChange={handleEmployeeChange}
                          value={selectedEmployees.map((employee) => ({
                            value: employee,
                            label: employee,
                          }))}
                          options={[
                            { value: "All", label: "All" },
                            ...filteredEmployeesss.map((employee) => ({
                              value: employee.sudo_name,
                              label: employee.sudo_name,
                            })),
                          ]}
                        />
                        <Box {...BoxStyle}>Select Employee*</Box>
                      </Box>

                      <Box position="relative">
                        <Input
                          {...InputStyle}
                          type="date"
                          id="start_date"
                          name="start_date"
                          value={start_date}
                          onChange={handleInputStart}
                          min={todayDate}
                        />
                        <Box {...BoxStyle}>Start Date*</Box>
                      </Box>

                      <br />

                      <Flex justifyContent="space-between">
                        <Box position="relative" mb={5} width="45%">
                          <SelectChakra
                            placeholder="Select Hours"
                            value={selectedHourOption}
                            onChange={(e) =>
                              handleHourSelectChange(e.target.value)
                            }
                            isDisabled={
                              selectedQuarterOption !== "" || end_date !== ""
                            }
                          >
                            <option value="30 Min">30 Min</option>
                            <option value="1 Hour">1 Hour</option>
                            <option value="2 Hour">2 Hour</option>
                            <option value="3 Hour">3 Hour</option>
                            <option value="4 Hour">4 Hour</option>
                            <option value="5 Hour">5 Hour</option>
                          </SelectChakra>

                          <Box {...BoxStyle}>Select Hours</Box>
                        </Box>
                        <Box position="relative" mb={5} width="45%">
                          <SelectChakra
                            placeholder="Select Quarters"
                            value={selectedQuarterOption}
                            onChange={(e) =>
                              handleQuarterSelectChange(e.target.value)
                            }
                            isDisabled={
                              selectedHourOption !== "" || end_date !== ""
                            }
                          >
                            <option value="1st">1st quarter</option>
                            <option value="2nd">2nd quarter</option>
                            <option value="3rd">3rd quarter</option>
                            <option value="4th">4th quarter</option>
                          </SelectChakra>

                          <Box {...BoxStyle}>Select Quarters</Box>
                        </Box>
                      </Flex>

                      <Box position="relative">
                        <Input
                          {...InputStyle}
                          type="date"
                          id="end_date"
                          name="end_date"
                          value={end_date}
                          onChange={handleInputEnd}
                          isDisabled={
                            selectedHourOption || selectedQuarterOption
                          }
                          min={start_date}
                        />
                        <Box {...BoxStyle}>End Date</Box>
                      </Box>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        type="submit"
                        bgGradient="linear(to-r, teal.300,  teal.100)"
                        _hover={{
                          bgGradient: "linear(to-r, teal.100,  teal.400)",
                        }}
                      >
                        Assign Task
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </form>
              </Modal>

              <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                finalFocusRef={btnRef}
                size={"lg"}
              >
                <DrawerOverlay />
                <form
                  onSubmit={handleAddMultipleEmployeeTask}
                  encType="multipart/form-data"
                >
                  <DrawerContent>
                    <DrawerCloseButton />

                    <DrawerHeader>Assign Task</DrawerHeader>

                    <DrawerBody>
                      <Box position="relative">
                        <Input
                          {...InputStyle}
                          required
                          type="text"
                          id="title"
                          name="title"
                          value={title}
                          onChange={handleInputTitle}
                        />
                        <Box {...BoxStyle}>Title*</Box>
                      </Box>
                      <br />
                      <Box position="relative">
                        <Textarea
                          {...InputStyle}
                          required
                          type="text"
                          id="description"
                          name="description"
                          value={description}
                          onChange={handleInputDescription}
                        />
                        <Box {...BoxStyle}>Description*</Box>
                      </Box>
                      <br />
                      <Box position="relative">
                        <Input
                          {...InputStyle}
                          type="file"
                          id="file"
                          name="file"
                          onChange={handleInputFiles}
                          multiple
                        />
                        <Box {...BoxStyle}>Attach Files</Box>
                        {files.length === 0 && (
                          <Box fontSize="xs" mt="2" color={"red"}>
                            Max 4 files can be selected.
                          </Box>
                        )}
                        {files && files.length > 0 && files.length <= 4 && (
                          <Box fontSize="xs" mt="2">
                            {files.length} Files selected.
                          </Box>
                        )}
                      </Box>
                      <br />

                      <Box position="relative" mb={5}>
                        <Select
                          placeholder=""
                          required
                          name="priority"
                          id="priority"
                          onChange={handleInputPriority}
                          value={priorityOptions.find(
                            (option) => option.value === priority
                          )}
                          options={priorityOptions}
                        />

                        <Box {...BoxStyle}>Priority*</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Select
                          placeholder=""
                          required
                          isMulti
                          name="department"
                          id="department"
                          onChange={handleDepartmentChange}
                          value={selectedDepartments.map((department) => ({
                            value: department,
                            label: department,
                          }))}
                          options={options}
                        />
                        <Box {...BoxStyle}>Select Department*</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Select
                          placeholder=""
                          required
                          isMulti
                          name="employee"
                          id="employee"
                          onChange={handleEmployeeChange}
                          value={selectedEmployees.map((employee) => ({
                            value: employee,
                            label: employee,
                          }))}
                          options={[
                            { value: "All", label: "All" },
                            ...filteredEmployeesss.map((employee) => ({
                              value: employee.sudo_name,
                              label: employee.sudo_name,
                            })),
                          ]}
                        />
                        <Box {...BoxStyle}>Select Employee*</Box>
                      </Box>

                      <Box position="relative">
                        <Input
                          {...InputStyle}
                          type="date"
                          id="start_date"
                          name="start_date"
                          value={start_date}
                          onChange={handleInputStart}
                          min={todayDate}
                        />
                        <Box {...BoxStyle}>Start Date*</Box>
                      </Box>

                      <br />

                      <Flex justifyContent="space-between">
                        <Box position="relative" mb={5} width="45%">
                          <SelectChakra
                            placeholder="Select Hours"
                            value={selectedHourOption}
                            onChange={(e) =>
                              handleHourSelectChange(e.target.value)
                            }
                            isDisabled={
                              selectedQuarterOption !== "" || end_date !== ""
                            }
                          >
                            <option value="30 Min">30 Min</option>
                            <option value="1 Hour">1 Hour</option>
                            <option value="2 Hour">2 Hour</option>
                            <option value="3 Hour">3 Hour</option>
                            <option value="4 Hour">4 Hour</option>
                            <option value="5 Hour">5 Hour</option>
                          </SelectChakra>

                          <Box {...BoxStyle}>Select Hours</Box>
                        </Box>
                        <Box position="relative" mb={5} width="45%">
                          <SelectChakra
                            placeholder="Select Quarters"
                            value={selectedQuarterOption}
                            onChange={(e) =>
                              handleQuarterSelectChange(e.target.value)
                            }
                            isDisabled={
                              selectedHourOption !== "" || end_date !== ""
                            }
                          >
                            <option value="1st">1st quarter</option>
                            <option value="2nd">2nd quarter</option>
                            <option value="3rd">3rd quarter</option>
                            <option value="4th">4th quarter</option>
                          </SelectChakra>

                          <Box {...BoxStyle}>Select Quarters</Box>
                        </Box>
                      </Flex>

                      <Box position="relative">
                        <Input
                          {...InputStyle}
                          type="date"
                          id="end_date"
                          name="end_date"
                          value={end_date}
                          onChange={handleInputEnd}
                          isDisabled={
                            selectedHourOption || selectedQuarterOption
                          }
                          min={start_date}
                        />
                        <Box {...BoxStyle}>End Date</Box>
                      </Box>
                    </DrawerBody>

                    <DrawerFooter>
                      <Button
                        type="submit"
                        bgGradient="linear(to-r, teal.300,  teal.100)"
                        _hover={{
                          bgGradient: "linear(to-r, teal.100,  teal.400)",
                        }}
                      >
                        Assign
                      </Button>
                    </DrawerFooter>
                  </DrawerContent>
                </form>
              </Drawer>
            </Box>
            <Input
              htmlSize={20}
              width="auto"
              placeholder="Search"
              focusBorderColor="green.400"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button
              ref={btnRef}
              // colorScheme="teal"
              onClick={handleNavigateToAllTasks}
              bgGradient="linear(to-r, teal.300,  teal.100)"
              _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
            >
              Sort Tasks
            </Button>
          </Flex>
        </Flex>
        <Box>
          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"md"}>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Assigned To
                  </Th>

                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Assign On
                  </Th>

                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Title
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Description
                  </Th>

                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Start Date
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    End Date
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Status
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Priority
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Task
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Action
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {paginationData.length > 0 &&
                  paginationData?.map((el) => {
                    // const path = userPathMap[el.employee];
                    const path = el.employee.map(
                      (employeeValue) => userPathMap[employeeValue]
                    );
                    //console.log("abpath", path);
                    return (
                      <Tr key={el._id} boxShadow={"md"}>
                        <Td
                          style={{ textAlign: "center", position: "relative" }}
                        >
                          <Wrap>
                            {el.employee.map((employee, index) => (
                              <WrapItem
                                key={index}
                                style={{
                                  position: "absolute",
                                  top: 10,
                                  left: 20,
                                }}
                              >
                                <Flex
                                  justifyContent={"center"}
                                  gap={1}
                                  direction="row"
                                  alignItems="center"
                                  style={{
                                    position: "relative",
                                    zIndex: el.employee.length - index,
                                  }}
                                >
                                  {el.employee.length > 1 ? (
                                    <>
                                      <AvatarGroup
                                        size="xs"
                                        max={2}
                                        onMouseEnter={() =>
                                          handleHoverEmployee(el._id)
                                        }
                                        onMouseLeave={handleMouseLeaveEmployee}
                                        cursor={"pointer"}
                                      >
                                        <Avatar
                                          name={employee}
                                          src={
                                            path
                                              ? `https://project360-backend.onrender.com/${path[0]}`
                                              : `https://project360-backend.onrender.com/${path}`
                                          }
                                        />
                                        <Avatar
                                          name={employee}
                                          src={
                                            path
                                              ? `https://project360-backend.onrender.com/${path[1]}`
                                              : `https://project360-backend.onrender.com/${path}`
                                          }
                                        />
                                      </AvatarGroup>
                                    </>
                                  ) : (
                                    <Avatar
                                      name={employee}
                                      src={`https://project360-backend.onrender.com/${path[0]}`}
                                      size="xs"
                                      onMouseEnter={() =>
                                        handleHoverEmployee(el._id)
                                      }
                                      onMouseLeave={handleMouseLeaveEmployee}
                                      cursor={"pointer"}
                                    />
                                  )}
                                </Flex>
                              </WrapItem>
                            ))}
                            {el.employee.length > 1 && (
                              <WrapItem
                                style={{
                                  position: "absolute",
                                  top: 12,
                                  left: 40,
                                }}
                              >
                                <Flex
                                  justifyContent={"center"}
                                  alignItems="center"
                                >
                                  <Tooltip
                                    label={`Assigned To Employees: ${el.employee.join(
                                      ", "
                                    )}`}
                                    isOpen={hoveredIdEmployee === el._id}
                                    placement="bottom"
                                    color="green.500"
                                  >
                                    <Badge
                                      borderRadius="full"
                                      px="2"
                                      bg="gray.300"
                                      color="red"
                                      fontSize={10}
                                      fontWeight="bold"
                                      ml={2}
                                      cursor="pointer"
                                      onMouseEnter={() =>
                                        handleHoverEmployee(el._id)
                                      }
                                      onMouseLeave={handleMouseLeaveEmployee}
                                    >
                                      +{el.employee.length - 1}
                                    </Badge>
                                  </Tooltip>
                                </Flex>
                              </WrapItem>
                            )}
                          </Wrap>
                        </Td>

                        <Td style={{ textAlign: "center" }}>
                          <Button size={"xs"}>
                            {moment(el.createdAt).format("lll")}
                          </Button>
                        </Td>

                        <Td
                          style={{
                            textAlign: "center",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Tooltip
                            label={el.title}
                            isOpen={hoveredIdTitle === el._id}
                            placement="bottom"
                          >
                            <Button
                              size={"xs"}
                              onMouseEnter={() => handleHoverTitle(el._id)}
                              onMouseLeave={handleMouseLeaveTitle}
                              // width={hoveredIdTitle === el._id ? 'auto' : '100px'}
                            >
                              {el.title.slice(0, 15)}
                            </Button>
                          </Tooltip>
                        </Td>

                        <Td style={{ textAlign: "center" }}>
                          <Button
                            size={"xs"}
                            onClick={() => {
                              onViewOpen();
                              handleAttachment(el);
                            }}
                          >
                            View
                          </Button>
                        </Td>

                        <Modal
                          isCentered
                          isOpen={isViewOpen && selectedData !== null}
                          onClose={onViewClose}
                        >
                          {overlay}
                          <ModalContent>
                            <ModalHeader>Task Description</ModalHeader>
                            <ModalCloseButton />

                            <ModalBody>
                              <Stack spacing={4}>
                                <Card>
                                  <CardBody>
                                    <Text>{selectedData.description}</Text>
                                  </CardBody>
                                </Card>

                                {selectedData.files &&
                                selectedData.files.length > 0 ? (
                                  <SimpleGrid
                                    columns={{ base: 1, md: 2 }}
                                    spacing={4}
                                  >
                                    {selectedData.files.map((file, index) => (
                                      <Card key={index}>
                                        <CardBody>
                                          <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                          >
                                            <Icon
                                              as={
                                                file.name.endsWith(".pdf")
                                                  ? FaRegFilePdf
                                                  : file.name.endsWith(
                                                      ".doc"
                                                    ) ||
                                                    file.name.endsWith(".docx")
                                                  ? FaRegFileWord
                                                  : file.name.endsWith(
                                                      ".xls"
                                                    ) ||
                                                    file.name.endsWith(".xlsx")
                                                  ? FaRegFileExcel
                                                  : file.name.endsWith(
                                                      ".ppt"
                                                    ) ||
                                                    file.name.endsWith(".pptx")
                                                  ? FaRegFilePowerpoint
                                                  : file.name.endsWith(
                                                      ".jpg"
                                                    ) ||
                                                    file.name.endsWith(
                                                      ".jpeg"
                                                    ) ||
                                                    file.name.endsWith(".png")
                                                  ? BsImage
                                                  : file.name.endsWith(
                                                      ".mp3"
                                                    ) ||
                                                    file.name.endsWith(".wav")
                                                  ? FaRegFileAudio
                                                  : file.name.endsWith(
                                                      ".mp4"
                                                    ) ||
                                                    file.name.endsWith(
                                                      ".avi"
                                                    ) ||
                                                    file.name.endsWith(
                                                      ".webm"
                                                    ) ||
                                                    file.name.endsWith(".mov")
                                                  ? FaRegFileVideo
                                                  : FaRegFile
                                              }
                                              boxSize={20}
                                              color={"blue.500"}
                                            />

                                            <Text
                                              mt={2}
                                              fontWeight={"semibold"}
                                              maxWidth="80%"
                                              overflow="hidden"
                                              textOverflow="ellipsis"
                                              whiteSpace="nowrap"
                                            >
                                              {file.name.substring(
                                                0,
                                                file.name.lastIndexOf(".")
                                              )}
                                            </Text>
                                            <Text color="gray.500">
                                              {file.name.substring(
                                                file.name.lastIndexOf(".") + 1
                                              )}
                                            </Text>
                                            <Button
                                              mt={2}
                                              as="a"
                                              href={`https://project360-backend.onrender.com/${file.path}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              size={"sm"}
                                              colorScheme="cyan"
                                            >
                                              Download
                                            </Button>
                                          </Box>
                                        </CardBody>
                                      </Card>
                                    ))}
                                  </SimpleGrid>
                                ) : (
                                  <Text>No files available.</Text>
                                )}
                              </Stack>
                            </ModalBody>

                            <ModalFooter></ModalFooter>
                          </ModalContent>
                        </Modal>

                        <Td style={{ textAlign: "center" }}>
                          <Button size={"xs"}>
                            {new Date(el.start_date).toDateString()}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          {el.end_date && (
                            <Button size={"xs"}>
                              {new Date(el.end_date).toDateString()}
                            </Button>
                          )}
                          {el.hourly && (
                            <Button size={"xs"} ml={2}>
                              {el.hourly}
                            </Button>
                          )}
                          {el.quarterly && (
                            <Button size={"xs"} ml={2}>
                              {el.quarterly} Quarter
                            </Button>
                          )}
                        </Td>

                        {/* <Td style={{ textAlign: "center" }}>
                          {el.end_date ? (
                            <Button size={"xs"}>
                              {new Date(el.end_date).toDateString()}
                            </Button>
                          ) : (
                            ""
                          )}
                        </Td> */}
                        <Td style={{ textAlign: "center" }}>
                          <Button
                            color={
                              el.task_status === "Pending"
                                ? "blue.600"
                                : "green"
                            }
                            size={"xs"}
                          >
                            {el.task_status}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button
                            style={{
                              color: el.priority === "High" ? "red" : "orange",
                            }}
                            size={"xs"}
                          >
                            {el.priority}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          {el.task_approval ? (
                            <Button
                              style={{
                                color:
                                  el.task_approval === "Rejected"
                                    ? "red"
                                    : el.task_approval === "Accepted"
                                    ? "green"
                                    : "blue",
                              }}
                              size={"xs"}
                            >
                              {el.task_approval}
                            </Button>
                          ) : (
                            ""
                          )}
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Flex justifyContent={"space-between"} gap={2}>
                            <Button
                              ref={btnRef}
                              onClick={() => {
                                onSidebarOpen();
                                handleEdit(el);
                              }}
                              size={"xs"}
                            >
                              <FiEdit />
                            </Button>

                            <Popover>
                              <PopoverTrigger>
                                <Button size={"xs"} color="red">
                                  <RiDeleteBin2Line />
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverBody>
                                  Are you sure you want to delete?
                                </PopoverBody>
                                <PopoverFooter
                                  display="flex"
                                  justifyContent="flex-end"
                                  gap={2}
                                >
                                  <ButtonGroup size="xs"></ButtonGroup>
                                  <ButtonGroup size="xs">
                                    <Button
                                      color="green"
                                      _hover={{
                                        color: "white",
                                        bgColor: "green",
                                      }}
                                      onClick={() => handleTaskDelete(el._id)}
                                      fontWeight={"bold"}
                                    >
                                      Yes
                                    </Button>
                                  </ButtonGroup>
                                </PopoverFooter>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>

            {selectedItem && (
              <Box>
                <Drawer
                  isOpen={isSidebarOpen}
                  onClose={onSidebarClose}
                  placement="right"
                  finalFocusRef={btnRef}
                  size={"md"}
                >
                  <form>
                    <DrawerOverlay />
                    <DrawerContent>
                      <DrawerCloseButton />
                      <DrawerHeader>Edit Task</DrawerHeader>
                      <DrawerBody>
                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            type="text"
                            value={formData.title}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                title: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>Title</Box>
                        </Box>
                        <br />
                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            type="text"
                            value={formData.description}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                description: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>Description</Box>
                        </Box>
                        <br />

                        <Box position="relative" mb={5}>
                          <Select
                            placeholder=""
                            required
                            value={editPriorityOptions.find(
                              (option) => option.value === formData.priority
                            )}
                            options={editPriorityOptions}
                            onChange={(selectedOption) =>
                              setFormData({
                                ...formData,
                                priority: selectedOption
                                  ? selectedOption.value
                                  : "",
                              })
                            }
                          />
                          <Box {...BoxStyle}>Priority</Box>
                        </Box>

                        <Box position="relative">
                          {/* <Input
                            {...InputStyle}
                            type="date"
                            value={formData.start_date}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                start_date: e.target.value,
                              })
                            }
                          /> */}
                          <Input
                            {...InputStyle}
                            type="date"
                            value={
                              formData.start_date
                                ? formData.start_date.slice(0, 10)
                                : ""
                            }
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                start_date: e.target.value + "T00:00:00.000Z",
                              })
                            }
                            min={todayDate}
                          />

                          <Box {...BoxStyle}>Start Date</Box>
                        </Box>
                        <br />

                        <Box position="relative">
                          {/* <Input
                            {...InputStyle}
                            type="date"
                            value={formData.end_date}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                end_date: e.target.value,
                              })
                            }
                          /> */}
                          <Input
                            {...InputStyle}
                            type="date"
                            value={
                              formData.end_date
                                ? formData.end_date.slice(0, 10)
                                : ""
                            }
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                end_date: e.target.value + "T00:00:00.000Z",
                              })
                            }
                            isDisabled={formData.quarterly || formData.hourly}
                            min={formData.start_date || todayDate}
                          />
                          <Box {...BoxStyle}>End Dates</Box>
                        </Box>
                        <br />

                        <Box position="relative" mb={5}>
                          <Select
                            placeholder=""
                            required
                            value={editHourlyOptions.find(
                              (option) => option.value === formData.hourly
                            )}
                            options={editHourlyOptions}
                            onChange={(selectedOption) =>
                              setFormData({
                                ...formData,
                                hourly: selectedOption
                                  ? selectedOption.value
                                  : "",
                              })
                            }
                            isDisabled={formData.quarterly || formData.end_date}
                          />
                          <Box {...BoxStyle}>Select Hours</Box>
                        </Box>
                        <br />
                        <Box position="relative" mb={5}>
                          <Select
                            placeholder=""
                            required
                            value={editQuarterlyOptions.find(
                              (option) => option.value === formData.quarterly
                            )}
                            options={editQuarterlyOptions}
                            onChange={(selectedOption) =>
                              setFormData({
                                ...formData,
                                quarterly: selectedOption
                                  ? selectedOption.value
                                  : "",
                              })
                            }
                            isDisabled={formData.hourly || formData.end_date}
                          />
                          <Box {...BoxStyle}>Select Quarter</Box>
                        </Box>
                      </DrawerBody>
                      <DrawerFooter>
                        <Button
                          onClick={handleTaskUpdate}
                          bgGradient="linear(to-r, teal.300,  teal.100)"
                          _hover={{
                            bgGradient: "linear(to-r, teal.100,  teal.400)",
                          }}
                        >
                          Update Task
                        </Button>
                      </DrawerFooter>
                    </DrawerContent>
                  </form>
                </Drawer>
              </Box>
            )}
          </TableContainer>
          <Flex justifyContent={"flex-end"} mt={5}>
            <Pagination page={page} setPage={setPage} totalPage={totalPage} />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default AdminAssignTask;
