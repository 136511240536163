import {
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Input,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Sidebar from "../../../Components/Sidebar";
import Navbar from "../../../Components/Navbar";
import {
  CalendarIcon,
  ChevronRightIcon,
  StarIcon,
  TimeIcon,
} from "@chakra-ui/icons";
import { FaRegFilePdf, FaUser } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import { BsFillPersonFill, BsBank, BsFiletypeDoc } from "react-icons/bs";
import { GiSuitcase } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";

import {
  editUser,
  getAllProbationFiles,
  getUsers,
} from "../../../Redux/app/action";
import DocumentsUpload from "./DocumentsUpload";

const EmployeeDetails = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const userData = useSelector((state) => state.authentication.users);
//console.log("uuu",userData)
  const headingStyles = {
    mt: "2",
    mb: "2",
    size: "sm",
    textAlign: "start",
    fontWeight: "semibold",
    fontSize: { base: "sm", md: "md" },
  };

  const QuickDetailsStyle = {
    fontSize: { base: "sm", md: "md" },
    fontWeight: "semibold",
  };

  const BoxFormStyle = {
    w: { base: "100%", md: "45%" },
    p: "5",
    borderRadius: "10",
  };

  const currentDate = new Date(); // Current date
  const joiningDate = new Date(userData.doj); // Joining date

  // Calculate the duration between the current date and the joining date
  const durationInMilliseconds = currentDate - joiningDate;
  const durationInYears = Math.floor(
    durationInMilliseconds / (365.25 * 24 * 60 * 60 * 1000)
  ); // Assuming 365.25 days in a year for accuracy
  const durationInMonths = Math.floor(
    durationInMilliseconds / (30.44 * 24 * 60 * 60 * 1000)
  ); // Assuming 30.44 days in a month for accuracy
  const durationInDays = Math.floor(
    durationInMilliseconds / (24 * 60 * 60 * 1000)
  );

  // Display the duration in the desired format
  const durationString = `${durationInYears}Yrs ${
    durationInMonths % 12
  }Months ${durationInDays % 30}Days`;

  const [folders, setFolders] = useState([]);
  const [folderName, setFolderName] = useState("");

  const handleCreateFolder = () => {
    // Logic to create the folder goes here
    const newFolder = { name: folderName };
    setFolders([...folders, newFolder]);
    setFolderName("");
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [destinationFolder, setDestinationFolder] = useState("");

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleMoveFile = () => {
    handleMoveFile(selectedFile, destinationFolder);
    setSelectedFile(null);
    setDestinationFolder("");
  };

  useEffect(() => {
    dispatch(getAllProbationFiles());
  }, [dispatch]);

  const probationLetters = useSelector(
    (state) => state.AppReducer.probationLetter
  );
  //console.log("probletter",probationLetters)
  const users = useSelector((store) => store.AppReducer.users);
  const globalState = useSelector((store) => store.authentication.users.id);
  //console.log("empid", globalState);

  const filteredData =
    probationLetters?.filter((obj) => obj.employee_id === globalState) || [];

  const filteredUser = users?.filter((obj) => obj._id === globalState) || [];

  const [isEditing, setIsEditing] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const toast = useToast();
  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleUpdateImage = async (id) => {
    if (selectedImage) {
      const formData = new FormData();
      formData.append("file", selectedImage);

      try {
        const response = await fetch(
          `https://project360-backend.onrender.com/editUser/${id}`,
          {
            method: "PATCH",
            body: formData,
          }
        );

        if (response.ok) {
          toast({
            position: "top",
            title: "Profile Picture Updated Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });

          setTimeout(() => {
            window.location.reload();
          }, 3000);
          setIsEditing(false);
        } else {
          //console.error("Error updating image");
          toast({
            position: "top",
            title: "Error updating image",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        //console.error("Error updating image:", error);
        toast({
          position: "top",
          title: "Error updating image",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };
  const circularInputStyles = {
    borderRadius: "50%",
    border: "2px dashed #A0AEC0",
    width: "130px",
    height: "130px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    overflow: "hidden",
  };

  const hiddenInputStyles = {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  };

  return (
    <Flex>
      <Sidebar />

      <Box
        pl={{ base: "2", md: "10" }}
        pt={{ base: "10", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"} fontWeight={"semibold"}>
          Profile
        </Heading>
        <Breadcrumb
          spacing="8px"
          mt={6}
          mb={6}
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/employee">Dashboard</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href="#">User</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="#">
              {userData.first_name} {userData.last_name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Box
          p={10}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <Flex spacing="4">
            <Flex
              flex="1"
              gap="4"
              alignItems="center"
              textAlign={"start"}
              flexWrap="wrap"
            >
              {isEditing ? (
                <>
                  <label style={circularInputStyles}>
                    <Input
                      as="input"
                      type="file"
                      accept=".jpeg, .jpg, .png"
                      onChange={handleImageChange}
                      style={hiddenInputStyles}
                    />
                    {selectedImage ? (
                      <img
                        src={URL.createObjectURL(selectedImage)}
                        alt="Selected"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <Text>Select Image</Text>
                    )}
                  </label>
                  {selectedImage && (
                    <Button
                      onClick={() => handleUpdateImage(userData.id)}
                      size={"xs"}
                      color={"green"}
                    >
                      Upload
                    </Button>
                  )}
                  <Button
                    onClick={() => setIsEditing(false)}
                    size={"xs"}
                    color={"red"}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Box position="relative" display="inline-block">
                  <Avatar
                    name={userData.first_name}
                    size="2xl"
                    src={
                      filteredUser[0]
                        ? `https://project360-backend.onrender.com/${filteredUser[0].path}`
                        : `https://project360-backend.onrender.com/${userData.path}`
                    }
                    onClick={() => setIsEditing(true)}
                    cursor="pointer"
                    transition="filter 0.3s"
                    _hover={{ filter: "blur(2px)" }}
                  />

                  <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    textAlign="center"
                    fontSize="xs"
                    fontWeight="bold"
                    color="white"
                    zIndex="1"
                  >
                    <Box
                      width="2.5rem"
                      height="2.5rem"
                      borderRadius="50%"
                      backgroundColor="rgba(0, 0, 0, 0)"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mb={1}
                    >
                      Upload Image
                    </Box>
                  </Box>
                </Box>
              )}

              <Box>
                <Heading size="sm">
                  {userData.first_name} {userData.last_name}
                </Heading>
                <Text>{userData.designation}</Text>
              </Box>
            </Flex>
          </Flex>

          {/* <Divider /> */}
          <Box
            mt={2}
            display="flex"
            justifyContent="flex-end"
            // border={"1px solid"}
            borderRadius={5}
            borderBottom={"none"}
            gap={2}
            color={"gray.400"}
          ></Box>
        </Box>
        <br />
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent={{ base: "flex-start", md: "space-between" }}
        >
          <Box w={{ base: "100%", md: "25%" }} borderRadius={10}>
            <Box
              boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
              p={5}
              borderRadius={10}
            >
              <Heading
                size={"sm"}
                textAlign={"start"}
                textDecoration={"underline"}
                color={"green"}
                mb={5}
                fontWeight={"semibold"}
              >
                Quick Details
              </Heading>

              <Flex align="center" gap={5} mb={5} fontWeight={"semibold"}>
                <Icon as={FaUser} boxSize={6} color="gray.500" mr={2} />
                <Heading {...QuickDetailsStyle}>{userData.employee_id}</Heading>
              </Flex>
              <Flex align="center" gap={5} mb={5}>
                <Icon as={CalendarIcon} boxSize={6} color="gray.500" mr={2} />
                <Heading {...QuickDetailsStyle} color={"green"}>
                  Hire Date
                </Heading>
              </Flex>
              <Flex align="center" gap={5} mb={5}>
                <Icon as={CalendarIcon} boxSize={6} color="gray.500" mr={2} />
                <Heading {...QuickDetailsStyle}>
                  {new Date(userData.doj).toDateString()}
                </Heading>
              </Flex>
              <Flex align="center" gap={5} mb={5}>
                <Icon as={CalendarIcon} boxSize={6} color="gray.500" mr={2} />
                <Heading {...QuickDetailsStyle}>{durationString}</Heading>
              </Flex>
              <Flex align="center" gap={5} mb={5}>
                <Icon as={StarIcon} boxSize={6} color="gray.500" mr={2} />
                <Heading {...QuickDetailsStyle} color={"green"}>
                  Designation
                </Heading>
              </Flex>
              <Flex align="center" gap={5} mb={5}>
                <Icon as={TimeIcon} boxSize={6} color="gray.500" mr={2} />
                <Heading {...QuickDetailsStyle}>Full Time</Heading>
              </Flex>
              <Flex align="center" gap={5} mb={5}>
                <Icon as={StarIcon} boxSize={6} color="gray.500" mr={2} />
                <Heading {...QuickDetailsStyle}>{userData.designation}</Heading>
              </Flex>
            </Box>
            <br />
            <Box
              // border={"1px solid"}
              p={5}
              borderRadius={10}
              boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
            >
              <Flex align="center" gap={5} mb={5}>
                <Icon as={CiLocationOn} boxSize={6} color="red.500" mr={2} />
                <Heading {...QuickDetailsStyle} textAlign={"start"}>
                  {userData.city}, {userData.city}, {userData.state},{" "}
                  {userData.zip_code}
                </Heading>
              </Flex>
            </Box>
          </Box>

          <Box
            w={{ base: "100%", md: "70%" }}
            p={10}
            borderRadius={10}
            boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
          >
            <Tabs position="relative" variant="unstyled" isFitted>
              <TabList
                // justifyContent="start"
                // // px={2}
                // overflowX="auto"
                // flexWrap="nowrap"
                // css={{
                //   "&::-webkit-scrollbar": {
                //     display: "none",
                //   },
                //   scrollbarWidth: "none",
                //   msOverflowStyle: "none",
                // }}
                textAlign={{ base: "start", md: "center" }}
                  size={{ base: "sm", md: "md" }}
                  gap={{ base: 2, md: 5 }}
                  display={{ base: "block", md: "flex" }}
              >
                <Tab>
                  <Flex align="center" gap={1}>
                    <Icon
                      as={BsFillPersonFill}
                      boxSize={{ base: 6, md: 6 }}
                      color="gray.500"
                      mr={{ base: 1, md: 1 }}
                    />

                    <Heading
                      fontSize={{ base: "sm", md: "md" }}
                      fontWeight={"semibold"}
                    >
                      Personal
                    </Heading>
                  </Flex>
                </Tab>
                <Tab>
                  <Flex align="center" gap={1}>
                    <Icon
                      as={BsBank}
                      boxSize={{ base: 6, md: 6 }}
                      color="gray.500"
                      mr={{ base: 1, md: 1 }}
                    />
                    <Heading
                      fontSize={{ base: "sm", md: "md" }}
                      fontWeight={"semibold"}
                    >
                      Bank Details
                    </Heading>
                  </Flex>
                </Tab>
                <Tab>
                  <Flex align="center" gap={1}>
                    <Icon
                      as={BsFiletypeDoc}
                      boxSize={{ base: 6, md: 6 }}
                      color="gray.500"
                      mr={{ base: 1, md: 1 }}
                    />
                    <Heading
                      fontSize={{ base: "sm", md: "md" }}
                      fontWeight={"semibold"}
                    >
                      Documents
                    </Heading>
                  </Flex>
                </Tab>
                <Tab>
                  <Flex align="center" gap={1}>
                    <Icon
                      as={GiSuitcase}
                      boxSize={{ base: 6, md: 6 }}
                      color="gray.500"
                      mr={{ base: 1, md: 1 }}
                    />
                    <Heading
                      fontSize={{ base: "sm", md: "md" }}
                      fontWeight={"semibold"}
                    >
                      Compensation
                    </Heading>
                  </Flex>
                </Tab>
              </TabList>
              <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="green.500"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel>
                  <Flex
                    direction={{ base: "column", md: "row" }}
                    justifyContent={{
                      base: "flex-start",
                      md: "space-between",
                    }}
                  >
                    <Box {...BoxFormStyle}>
                      <Box textAlign={"start"}>
                        <Text fontSize="sm" color={"green"}>
                          First Name
                        </Text>
                        <Heading {...headingStyles}>
                          {userData.first_name}
                        </Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          Date Of Birth
                        </Text>
                        <Heading {...headingStyles}>
                          {new Date(userData.dob).toDateString()}
                        </Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          Email
                        </Text>
                        <Heading {...headingStyles}>{userData.email}</Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          Gender
                        </Text>
                        <Heading {...headingStyles}>{userData.gender}</Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          Blood Group
                        </Text>
                        <Heading {...headingStyles}>
                          {userData.blood_group}
                        </Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          Father Name
                        </Text>
                        <Heading {...headingStyles}>
                          {userData.father_name}
                        </Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          Permanent Address
                        </Text>
                        <Heading {...headingStyles}>
                          {userData.city} {userData.state}
                        </Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          Sudo Name
                        </Text>
                        <Heading {...headingStyles}>{userData.sudo_name}</Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          Emergency Contact
                        </Text>
                        <Heading {...headingStyles}>
                          +91-{userData.phone}
                        </Heading>
                        <Divider mb={5} />
                      </Box>
                    </Box>
                    <Box {...BoxFormStyle}>
                      <Box textAlign={"start"}>
                        <Text fontSize="sm" color={"green"}>
                          Last Name
                        </Text>
                        <Heading {...headingStyles}>
                          {userData.last_name}
                        </Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          Joining Date
                        </Text>
                        <Heading {...headingStyles}>
                          {new Date(userData.doj).toDateString()}
                        </Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          Phone
                        </Text>
                        <Heading {...headingStyles}>
                          +91-{userData.phone}
                        </Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          Marital Status
                        </Text>
                        <Heading {...headingStyles}>
                          {userData.marital_status}
                        </Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          Nationality
                        </Text>
                        <Heading {...headingStyles}>{userData.country}</Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          Mother Name
                        </Text>
                        <Heading {...headingStyles}>
                          {userData.mother_name}
                        </Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          Current Address
                        </Text>
                        <Heading {...headingStyles}>
                          {userData.city} {userData.state}
                        </Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          Aadhar Number
                        </Text>
                        <Heading {...headingStyles}>{userData.aadhar}</Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          Alternate Email
                        </Text>
                        <Heading {...headingStyles}>
                          {userData.alternate_email}
                        </Heading>
                        <Divider mb={5} />
                      </Box>
                    </Box>
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <Flex
                    direction={{ base: "column", md: "row" }}
                    justifyContent={{
                      base: "flex-start",
                      md: "space-between",
                    }}
                  >
                    <Box {...BoxFormStyle}>
                      <Box textAlign={"start"}>
                        <Text fontSize="sm" color={"green"}>
                          Account Holder Name
                        </Text>
                        <Heading {...headingStyles}>
                          {userData.account_holder_name}
                        </Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          IFSC
                        </Text>
                        <Heading {...headingStyles}>{userData.ifsc}</Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          Branch
                        </Text>
                        <Heading {...headingStyles}>
                          {userData.branch_name}
                        </Heading>
                        <Divider mb={5} />
                      </Box>
                    </Box>
                    <Box {...BoxFormStyle}>
                      <Box textAlign={"start"}>
                        <Text fontSize="sm" color={"green"}>
                          Bank Name
                        </Text>
                        <Heading {...headingStyles}>
                          {userData.bank_name}
                        </Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          Account Number
                        </Text>
                        <Heading {...headingStyles}>
                          {userData.account_number}
                        </Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          Upi Id
                        </Text>
                        <Heading {...headingStyles}>{userData.upi_id}</Heading>
                        <Divider mb={5} />
                        <Text fontSize="sm" color={"green"}>
                          Registered Phone
                        </Text>
                        <Heading {...headingStyles}>
                          +91-{userData.registered_phone}
                        </Heading>
                        <Divider mb={5} />
                      </Box>
                    </Box>
                  </Flex>
                </TabPanel>
                <TabPanel>
               

                  <DocumentsUpload />
                </TabPanel>
                <TabPanel></TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default EmployeeDetails;
