import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Avatar,
  Box,
  Card,
  CardHeader,
  Flex,
  Heading,
  Text,
  Image,
} from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineHome, AiOutlineMail } from "react-icons/ai";
import { BsListTask, BsFingerprint, BsChatDots } from "react-icons/bs";
import { SlCalender } from "react-icons/sl";
import { TbMathGreater } from "react-icons/tb";
import { HiOutlineDocumentText } from "react-icons/hi";
import companylogo from "../assets/companylogo.png";
import { useEffect } from "react";
import { fetchAttendances, getUsers } from "../Redux/app/action";
const AdminSidebar = () => {
  const globalState = useSelector((store) => store.authentication.data.token);
  //console.log(globalState);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);
  const id = useSelector((store) => store.authentication.users.id);
  const users = useSelector((store) => store.AppReducer.users);
  const filteredUser = users?.filter((obj) => obj._id === id) || [];

  const isActive = (pathname) => {
    return location.pathname === pathname;
  };

  useEffect(() => {
    dispatch(fetchAttendances());
  }, [dispatch]);
  const attendances = useSelector((state) => state.AppReducer.attendances);
  const empglobalState = useSelector((store) => store.authentication.users.id);
  const filteredData =
    attendances?.filter((obj) => obj.employee_id === empglobalState) || [];

  const reversedAttendances = [...filteredData].reverse();
  const attendance = reversedAttendances.find(
    (attendance) => attendance.employee_id === id
  );
  const getStatusColor = (status) => {
    return status === "Available" ? "green" : "red";
  };
  const statusColor = attendance ? getStatusColor(attendance.status) : "gray";
  return (
    <Box p={{ base: "none", md: 5 }} w={{ base: "100%", md: "15%" }}>
      <Box display={{ base: "none", sm: "block" }}>
        <Link to="/admin">
          <Box w={150}>
            <Image
              src={companylogo}
              width={{ base: "50%", sm: "50%", md: "50%" }}
              height="auto"
              objectFit="cover"
            />
          </Box>
        </Link>
        <br />
        <Link to="#">
          <Card
            maxW="md"
            bgGradient="linear(to-r, teal.300,  teal.100)"
            _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
          >
            <CardHeader>
              <Flex spacing="4">
                <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
                  {/* <Avatar
                    name={globalState.first_name}
                    src={`https://project360-backend.onrender.com/${filteredUser[0].path}`}
                    backgroundColor={"teal"}
                  /> */}
                  <Box
                    style={{
                      position: "relative",
                      display: "inline-block",
                    }}
                  >
                    <Avatar
                      name={globalState.first_name}
                      size="sm"
                      src={
                        filteredUser[0]
                          ? `https://project360-backend.onrender.com/${filteredUser[0].path}`
                          : `https://project360-backend.onrender.com/${globalState.path}`
                      }
                    />

                    <span
                      style={{
                        position: "absolute",
                        bottom: "-2px", // Adjust this value to position the status dot properly
                        right: "-2px", // Adjust this value to position the status dot properly
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: statusColor,
                      }}
                    />
                  </Box>

                  <Box>
                    <Heading size="sm">{globalState.first_name}</Heading>
                    <Text textTransform={"capitalize"}>{globalState.role}</Text>
                  </Box>
                </Flex>
              </Flex>
            </CardHeader>
          </Card>
        </Link>
        <br />
        <br />
        <Heading size="md" fontWeight={"bold"} textAlign={"start"}>
          General
        </Heading>
        <br />
        <Accordion allowToggle lineHeight={8}>
          <Link to="/admin">
            <AccordionItem>
              <h2>
                <AccordionButton
                  _hover={{
                    fontWeight: "semibold",
                    color: isActive("/admin") ? "green" : "green",
                  }}
                  color={isActive("/admin") ? "green" : "inherit"}
                >
                  <AiOutlineHome />
                  <Box as="span" flex="1" textAlign="left" ml={3}>
                    Dashboard
                  </Box>
                </AccordionButton>
              </h2>
            </AccordionItem>
          </Link>
          <Link to="/admintask">
            <AccordionItem>
              <h2>
                <AccordionButton
                  _hover={{
                    fontWeight: "semibold",
                    color: isActive("/admintask") ? "green" : "green",
                  }}
                  color={isActive("/admintask") ? "green" : "inherit"}
                >
                  <BsListTask />
                  <Box as="span" flex="1" textAlign="left" ml={3}>
                    Task
                  </Box>
                </AccordionButton>
              </h2>
            </AccordionItem>
          </Link>

          <Link to="#">
            <AccordionItem>
              <h2>
                <AccordionButton
                  _hover={{
                    fontWeight: "semibold",
                    color: isActive("/") ? "green" : "green",
                  }}
                  color={isActive("/") ? "green" : "inherit"}
                >
                  <BsChatDots />
                  <Box as="span" flex="1" textAlign="left" ml={3}>
                    Chats
                  </Box>
                </AccordionButton>
              </h2>
            </AccordionItem>
          </Link>
          <Link to="/adminattendence">
            <AccordionItem>
              <h2>
                <AccordionButton
                  _hover={{
                    fontWeight: "semibold",
                    color: isActive("/adminattendence") ? "green" : "green",
                  }}
                  color={isActive("/adminattendence") ? "green" : "inherit"}
                >
                  <BsFingerprint />
                  <Box as="span" flex="1" textAlign="left" ml={3}>
                    Attendence
                  </Box>
                </AccordionButton>
              </h2>
            </AccordionItem>
          </Link>
          <Link to="/admincalender">
            <AccordionItem>
              <h2>
                <AccordionButton
                  _hover={{
                    fontWeight: "semibold",
                    color: isActive("/admincalender") ? "green" : "green",
                  }}
                  color={isActive("/admincalender") ? "green" : "inherit"}
                >
                  <SlCalender />
                  <Box as="span" flex="1" textAlign="left" ml={3}>
                    Calender
                  </Box>
                </AccordionButton>
              </h2>
            </AccordionItem>
          </Link>
          <Link to="#">
            <AccordionItem>
              <h2>
                <AccordionButton
                  _hover={{
                    fontWeight: "semibold",
                    color: isActive("/") ? "green" : "green",
                  }}
                  color={isActive("/") ? "green" : "inherit"}
                >
                  <AiOutlineMail />
                  <Box as="span" flex="1" textAlign="left" ml={3}>
                    Mails
                  </Box>
                  <TbMathGreater />
                </AccordionButton>
              </h2>
            </AccordionItem>
          </Link>
          <Link to="/employees">
            <AccordionItem>
              <h2>
                <AccordionButton
                  _hover={{
                    fontWeight: "semibold",
                    color: isActive("/employees") ? "green" : "green",
                  }}
                  color={isActive("/employees") ? "green" : "inherit"}
                >
                  <AiOutlineMail />
                  <Box as="span" flex="1" textAlign="left" ml={3}>
                    Employees
                  </Box>
                  <TbMathGreater />
                </AccordionButton>
              </h2>
            </AccordionItem>
          </Link>
          <Link to="/admingenerateletters">
            <AccordionItem>
              <h2>
                <AccordionButton
                  _hover={{
                    fontWeight: "semibold",
                    color: isActive("/admingenerateletters")
                      ? "green"
                      : "green",
                  }}
                  color={
                    isActive("/admingenerateletters") ? "green" : "inherit"
                  }
                >
                  <HiOutlineDocumentText />
                  <Box as="span" flex="1" textAlign="left" ml={3}>
                    Generate Letters
                  </Box>
                  <TbMathGreater />
                </AccordionButton>
              </h2>
            </AccordionItem>
          </Link>
        </Accordion>
      </Box>
    </Box>
  );
};

export default AdminSidebar;
