import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PaginationOther } from "../../../Components/PaginationOther";
import { useDispatch } from "react-redux";
import { getUsers, seeAllLetters } from "../../../Redux/app/action";

const LeaveLetter = () => {
  const dispatch = useDispatch();
    useEffect(() => {
    dispatch(seeAllLetters());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);
  const id = useSelector((store) => store.authentication.users.id);
  const users = useSelector((store) => store.AppReducer.users);
  const filteredUser = users?.filter((obj) => obj._id === id) || [];

  const globalState = useSelector(
    (store) => store.authentication.data.token.employee_id
  );
  const leavesData = useSelector((state) => state.AppReducer.leaves);


  const employeeLeaveLetter = leavesData.filter(
    (obj) => obj.employee_id === globalState
  );
  const [otherPage, setotherPage] = useState(1);
  let otherperPage = 5;
  let othertotalPage = Math.ceil(employeeLeaveLetter.length / otherperPage);
  let endother = otherPage * otherperPage;
  let startother = endother - otherperPage;
   let filteredLetters = [...employeeLeaveLetter].reverse();
  // let filteredLetters = [...leavesData].reverse();
  // const employeeLeaveLetter = filteredLetters.filter(
  //   (obj) => obj.employee_id === globalState
  // );
  let paginationDataLetters = filteredLetters.slice(startother, endother);
  return (
    <>
      <TableContainer>
        <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
          <Thead>
            <Tr boxShadow={"md"}>
              <Th boxShadow={"md"} style={{ textAlign: "center" }}>Requested By</Th>
              <Th boxShadow={"md"} style={{ textAlign: "center" }}>Letter Type</Th>
              <Th boxShadow={"md"} style={{ textAlign: "center" }}>Applied On</Th>
              <Th boxShadow={"md"} style={{ textAlign: "center" }}>From</Th>
              <Th boxShadow={"md"} style={{ textAlign: "center" }}>To</Th>
              <Th boxShadow={"md"} style={{ textAlign: "center" }}>Status</Th>
            </Tr>
          </Thead>
         
            <Tbody >
            {paginationDataLetters.length > 0 && paginationDataLetters?.map((el) => (
              <Tr key={el._id} boxShadow={"md"}>
                <Link to="/appliedleaveletter">
                  <Td style={{ textAlign: "center" }}>
                    <Wrap>
                      <WrapItem>
                        <Flex justifyContent={"space-around"} gap={2}>
                          <Avatar
                            size="xs"
                            name="Kent Dodds"
                            src={`https://project360-backend.onrender.com/${filteredUser[0].path}`}
                          />
                          <Heading size={"xs"}>
                              <Button size={"xs"} fontWeight={"bold"}>
                                {el.employee_id}
                                </Button>
                              </Heading>
                        </Flex>
                      </WrapItem>
                    </Wrap>
                  </Td>
                </Link>
                <Td style={{ textAlign: "center" }}><Button size={"xs"}  fontWeight={"bold"}>{el.type}</Button></Td>
                <Td style={{ textAlign: "center" }}><Button size={"xs"} fontWeight={"bold"}>{new Date(el.currentDate).toDateString()}</Button></Td>
                <Td style={{ textAlign: "center" }}><Button size={"xs"} fontWeight={"bold"}>{new Date(el.start_date).toDateString()}</Button></Td>

                <Td style={{ textAlign: "center" }}><Button size={"xs"}>{new Date(el.end_date).toDateString()}</Button></Td>
                <Td style={{ textAlign: "center" }}>
                  <Button
                    color={
                      el.leave_status === "Pending"
                        ? "blue"
                        : el.leave_status === "Rejected"
                        ? "red"
                        : "green"
                    }
                    disabled
                    size={"xs"} fontWeight={"bold"}
                  >
                    {el.leave_status}
                  </Button>
                </Td>
              </Tr>
               ))}
            </Tbody>
         
        </Table>
        <Box>
              {paginationDataLetters.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found 
                </Alert>
              )}
            </Box>
      </TableContainer>
      <Flex justifyContent={"flex-end"} mt={5}>
        <PaginationOther
          otherPage={otherPage}
          setotherPage={setotherPage}
          othertotalPage={othertotalPage}
        />
      </Flex>
    </>
  );
};

export default LeaveLetter;
