import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css"; // Import the default styles
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import Select from "react-select";

import {
  FaRegFile,
  FaRegFileAudio,
  FaRegFileExcel,
  FaRegFilePdf,
  FaRegFilePowerpoint,
  FaRegFileVideo,
  FaRegFileWord,
} from "react-icons/fa";
import { BsImage } from "react-icons/bs";
import axios from "axios";
import { getAllEvents, getUsers } from "../../../Redux/app/action";
import HRSidebar from "../../../Components/HRSidebar";
import Navbar from "../../../Components/Navbar";
const localizer = momentLocalizer(moment);

const CustomEvent = ({ event }) => {
  return (
    <Box className="custom-event" textAlign={"start"}>
      <Heading as="h5" size="sm">
        Title : {event.title}
      </Heading>
      <Box>Description : {event.description}</Box>
      <Box>Department: {event.department.join(", ")}</Box>
      <Box>Employee : {event.employee.join(", ")}</Box>
      <Box>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
          {event.files.map((file, index) => (
            <Card key={index}>
              <CardBody>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Icon
                    as={
                      file.name.endsWith(".pdf")
                        ? FaRegFilePdf
                        : file.name.endsWith(".doc") ||
                          file.name.endsWith(".docx")
                        ? FaRegFileWord
                        : file.name.endsWith(".xls") ||
                          file.name.endsWith(".xlsx")
                        ? FaRegFileExcel
                        : file.name.endsWith(".ppt") ||
                          file.name.endsWith(".pptx")
                        ? FaRegFilePowerpoint
                        : file.name.endsWith(".jpg") ||
                          file.name.endsWith(".jpeg") ||
                          file.name.endsWith(".png")
                        ? BsImage
                        : file.name.endsWith(".mp3") ||
                          file.name.endsWith(".wav")
                        ? FaRegFileAudio
                        : file.name.endsWith(".mp4") ||
                          file.name.endsWith(".avi") ||
                          file.name.endsWith(".webm") ||
                          file.name.endsWith(".mov")
                        ? FaRegFileVideo
                        : FaRegFile
                    }
                    boxSize={{ base: 8, sm: 10 }} // Responsive icon size
                    color={"blue.500"}
                  />

                  <Text
                    mt={2}
                    fontWeight="semibold"
                    maxWidth="80%"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    fontSize={{ base: "xx-small", sm: "xs" }} // Responsive font size
                  >
                    {file.name.substring(0, 10)}
                    <Text as="span" color="gray.500" fontSize="xs">
                      .{file.name.substring(file.name.lastIndexOf(".") + 1)}
                    </Text>
                  </Text>

                  <Button
                    mt={2}
                    as="a"
                    href={`https://project360-backend.onrender.com/${file.path}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    size={{ base: "xs", sm: "xs" }} // Responsive button size
                    colorScheme="cyan"
                    w={{ base: "100%", sm: "auto" }}
                    maxW="200px"
                  >
                    Download
                  </Button>
                </Box>
              </CardBody>
            </Card>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

const HRCalender = (props) => {
  const dispatch = useDispatch();
  const toast = useToast();
  useEffect(() => {
    dispatch(getAllEvents());
  }, [dispatch]);
  const events = useSelector((state) => state.AppReducer.events);

  const eventsData = events.map((el) => ({
    _id: el._id,
    title: el.title,
    start: new Date(el.start),
    end: new Date(el.end),
    description: el.description,
    department: el.department,
    employee: el.employee,
    files: el.files.map((file) => ({
      _id: file._id,
      name: file.name,
      path: file.path,
    })),
  }));

  //console.log("evdat", eventsData);

  const InputStyle = {
    borderRadius: "md",
    borderWidth: "2px",
    borderColor: "gray.300",
    py: "2",
    px: "4",
    variant: "unstyled",
  };

  const BoxStyle = {
    position: "absolute",
    top: "-8px",
    left: "8px",
    pointerEvents: "none",
    color: "green",
    fontSize: "sm",
    bg: "white",
    px: "1",
  };
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const users = useSelector((state) => state.AppReducer.users);

  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [start, setstartDate] = useState("");
  const [end, setendDate] = useState("");

  const options = [
    { value: "All", label: "All" },
    {
      value: "Software Development",
      label: "Software Development",
    },
    {
      value: "Lead Generation",
      label: "Lead Generation",
    },
    {
      value: "Customer Support",
      label: "Customer Support",
    },
  ];

  const handleEventChange = (e) => {
    const { name, value } = e.target;

    if (name === "title") {
      setTitle(value);
    }
  };

  const handleDescriptionChange = (e) => {
    const { name, value } = e.target;

    if (name === "description") {
      setDescription(value);
    }
  };

  const handleFileChange = (e) => {
    const filesArray = Array.from(e.target.files);
    if (files.length > 2) {
      toast({
        position: "top",
        title: "Max 2 Files",
        description: "",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setFiles(filesArray);
  };

  const handleStartChange = (e) => {
    const { name, value } = e.target;

    if (name === "start") {
      setstartDate(value);
    }
  };

  const handleEndChange = (e) => {
    const { name, value } = e.target;

    if (name === "end") {
      setendDate(value);
    }
  };

  const filteredEmployees = users.filter((user) => {
    if (
      selectedDepartments.length === 0 ||
      selectedDepartments.includes("All")
    ) {
      return true;
    } else {
      return selectedDepartments.includes(user.department);
    }
  });

  const handleDepartmentChange = (selectedOptions) => {
    const selectedDepartmentValues = selectedOptions.map(
      (option) => option.value
    );

    if (selectedDepartmentValues.includes("All")) {
      setSelectedDepartments(
        options
          .filter((option) => option.value !== "All")
          .map((option) => option.value)
      );
      setSelectedEmployees(
        filteredEmployees.map((employee) => employee.sudo_name)
      );
    } else {
      setSelectedDepartments(selectedDepartmentValues);

      setSelectedEmployees((prevSelectedEmployees) =>
        prevSelectedEmployees.filter((employee) => {
          const employeeObj = filteredEmployees.find(
            (emp) => emp.sudo_name === employee
          );
          return (
            employeeObj &&
            selectedDepartmentValues.includes(employeeObj.department)
          );
        })
      );
    }
  };

  const handleEmployeeChange = (selectedOptions) => {
    const selectedEmployeeValues = selectedOptions.map(
      (option) => option.value
    );

    if (selectedEmployeeValues.includes("All")) {
      setSelectedEmployees(
        filteredEmployees.map((employee) => employee.sudo_name)
      );
    } else {
      setSelectedEmployees(selectedEmployeeValues);
    }
  };

  const handleAddEvent = async () => {
    try {
      const formData = new FormData();

      formData.append("title", title);
      formData.append("description", description);
      formData.append("start", start);
      formData.append("end", end);
      for (const department of selectedDepartments) {
        formData.append("department", department);
      }
      for (const employee of selectedEmployees) {
        formData.append("employee", employee);
      }

      for (const file of files) {
        formData.append("files", file);
      }

      const response = await axios.post(
        `https://project360-backend.onrender.com/event`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      //console.log("Response from server:", response.data);
      toast({
        title: "Event Added",
        description: "The event has been added to the calendar.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      //console.error("Error adding event:", error);
      toast({
        title: "Error",
        description: "An error occurred while adding the event.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex>
      <HRSidebar />
      <Box
        pl={{ base: "2", md: "10" }}
        pt={{ base: "10", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Calender
        </Heading>
        <Flex justifyContent={"space-between"}>
          <Breadcrumb
            spacing="8px"
            mt={6}
            mb={6}
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/hr">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="/hr/calender">Calender</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Button
            onClick={onOpen}
            bgGradient="linear(to-r, teal.300,  teal.100)"
            _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
          >
            Add Events
          </Button>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <form onSubmit={handleAddEvent} encType="multipart/form-data">
              <ModalContent>
                <ModalHeader fontStyle={"italic"}>
                  Add Event To Calendar
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                  <Box position="relative">
                    <Input
                      {...InputStyle}
                      type="text"
                      id="title"
                      name="title"
                      value={title}
                      onChange={handleEventChange}
                    />
                    <Box {...BoxStyle}>Event Title</Box>
                  </Box>
                  <br />
                  <Box position="relative">
                    <Textarea
                      {...InputStyle}
                      type="text"
                      id="description"
                      name="description"
                      value={description}
                      onChange={handleDescriptionChange}
                    />
                    <Box {...BoxStyle}>Event Description</Box>
                  </Box>
                  <br />
                  <Box position="relative">
                    <Input
                      {...InputStyle}
                      type="file"
                      id="file"
                      name="file"
                      onChange={handleFileChange}
                      multiple
                    />
                    <Box {...BoxStyle}>Attach Files</Box>
                    {files.length === 0 && (
                      <Box fontSize="xs" mt="2" color="red">
                        Max 2 files can be selected.
                      </Box>
                    )}

                    {files.length > 0 && files.length <= 2 && (
                      <Box fontSize="xs" mt="2">
                        {files.length} Files selected.
                      </Box>
                    )}
                  </Box>
                  <br />
                  <Box position="relative" mb={5}>
                    <Select
                      placeholder=""
                      required
                      isMulti
                      name="department"
                      id="department"
                      onChange={handleDepartmentChange}
                      value={selectedDepartments.map((department) => ({
                        value: department,
                        label: department,
                      }))}
                      options={options}
                    />
                    <Box {...BoxStyle}>Select Department</Box>
                  </Box>

                  <Box position="relative" mb={5}>
                    <Select
                      placeholder=""
                      required
                      isMulti
                      name="employee"
                      id="employee"
                      onChange={handleEmployeeChange}
                      value={selectedEmployees.map((employee) => ({
                        value: employee,
                        label: employee,
                      }))}
                      options={[
                        { value: "All", label: "All" },
                        ...filteredEmployees.map((employee) => ({
                          value: employee.sudo_name,
                          label: employee.sudo_name,
                        })),
                      ]}
                    />
                    <Box {...BoxStyle}>Select Employee</Box>
                  </Box>
                  <Box position="relative">
                    <Input
                      {...InputStyle}
                      type="datetime-local"
                      id="start"
                      name="start"
                      value={start}
                      onChange={handleStartChange}
                    />
                    <Box {...BoxStyle}>Start Event</Box>
                  </Box>

                  <br />

                  <Box position="relative">
                    <Input
                      {...InputStyle}
                      type="datetime-local"
                      id="end"
                      name="end"
                      value={end}
                      onChange={handleEndChange}
                    />
                    <Box {...BoxStyle}>End Event</Box>
                  </Box>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="green" size={"sm"} type="submit">
                    Add Event
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          </Modal>
        </Flex>
        <Calendar
          localizer={localizer}
          events={eventsData}
          startAccessor="start"
          endAccessor="end"
          components={{
            event: CustomEvent,
          }}
        />
      </Box>
    </Flex>
  );
};

export default HRCalender;
