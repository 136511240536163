import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Text,

} from "@chakra-ui/react";
import React from "react";
import Sidebar from "../../../Components/Sidebar";
import Navbar from "../../../Components/Navbar";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css"; // Import the default styles
import { useDispatch, useSelector } from "react-redux";
import { getAllEvents, getUsers } from "../../../Redux/app/action";
import { useEffect } from "react";

import {
  FaRegFile,
  FaRegFileAudio,
  FaRegFileExcel,
  FaRegFilePdf,
  FaRegFilePowerpoint,
  FaRegFileVideo,
  FaRegFileWord,
} from "react-icons/fa";
import { BsImage } from "react-icons/bs";
const localizer = momentLocalizer(moment);

const CustomEvent = ({ event }) => {
  return (
    <Box className="custom-event" textAlign={"start"}>
      <Heading as="h5" size="sm">
        Title : {event.title}
      </Heading>
      <Box>Description : {event.description}</Box>

      <Box>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
          {event.files.map((file, index) => (
            <Card key={index}>
              <CardBody>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Icon
                    as={
                      file.name.endsWith(".pdf")
                        ? FaRegFilePdf
                        : file.name.endsWith(".doc") ||
                          file.name.endsWith(".docx")
                        ? FaRegFileWord
                        : file.name.endsWith(".xls") ||
                          file.name.endsWith(".xlsx")
                        ? FaRegFileExcel
                        : file.name.endsWith(".ppt") ||
                          file.name.endsWith(".pptx")
                        ? FaRegFilePowerpoint
                        : file.name.endsWith(".jpg") ||
                          file.name.endsWith(".jpeg") ||
                          file.name.endsWith(".png")
                        ? BsImage
                        : file.name.endsWith(".mp3") ||
                          file.name.endsWith(".wav")
                        ? FaRegFileAudio
                        : file.name.endsWith(".mp4") ||
                          file.name.endsWith(".avi") ||
                          file.name.endsWith(".webm") ||
                          file.name.endsWith(".mov")
                        ? FaRegFileVideo
                        : FaRegFile
                    }
                    boxSize={{ base: 8, sm: 10 }} // Responsive icon size
                    color={"blue.500"}
                  />

                  <Text
                    mt={2}
                    fontWeight="semibold"
                    maxWidth="80%"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    fontSize={{ base: "xx-small", sm: "xs" }} 
                  >
                    {file.name.substring(0, 10)}
                    <Text as="span" color="gray.500" fontSize="xs">
                      .{file.name.substring(file.name.lastIndexOf(".") + 1)}
                    </Text>
                  </Text>

                  <Button
                    mt={2}
                    as="a"
                    href={`https://project360-backend.onrender.com/${file.path}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    size={{ base: "xs", sm: "xs" }} 
                    colorScheme="cyan"
                    w={{ base: "100%", sm: "auto" }}
                    maxW="200px"
                  >
                    Download
                  </Button>
                </Box>
              </CardBody>
            </Card>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

const Calender = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllEvents());
  }, [dispatch]);
  const events = useSelector((state) => state.AppReducer.events);
  //console.log("events", events);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);
  const users = useSelector((state) => state.AppReducer.users);
  const globalState = useSelector((store) => store.authentication.users.id);
  //console.log("empid", globalState);

  const filteredUser = users?.filter((obj) => obj._id === globalState) || [];
  //console.log("filteredUser", filteredUser);

  const filteredEvents = events.filter((event) => {
    return event.employee.some((emp) =>
      filteredUser.some((user) => user.sudo_name === emp)
    );
  });

  //console.log("filteredEvents", filteredEvents);

  const eventsData = filteredEvents.map((el) => ({
    _id: el._id,
    title: el.title,
    start: new Date(el.start),
    end: new Date(el.end),
    description: el.description,
    department: el.department,
    employee: el.employee,
    files: el.files.map((file) => ({
      _id: file._id,
      name: file.name,
      path: file.path,
    })),
  }));
  return (
    <Flex>
      <Sidebar />
      <Box
        pl={{ base: "2", md: "10" }}
        pt={{ base: "10", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Calender
        </Heading>
        <Flex justifyContent={"space-between"}>
          <Breadcrumb
            spacing="8px"
            mt={6}
            mb={6}
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/employee">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="/calender">Calender</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Flex>

        <Calendar
          localizer={localizer}
          events={eventsData}
          startAccessor="start"
          endAccessor="end"
          components={{
            event: CustomEvent,
          }}
        />
      </Box>
    </Flex>
  );
};

export default Calender;
