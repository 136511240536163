import * as types from "./actionTypes";
const initialState = {
  users: [],
  taskData: [],
  registerUser: [],
  attendances: [],
  leaves: [],
  lateLogin: [],
  earlyLogout: [],
  probationLetter: [],
  offerLetter: [],
  messages: [],
  events: [],
  documents: [],
  isLoading: false,
  isError: false,
 
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    default:
      return state;
    case types.GET_USERS_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.GET_USERS_SUCCESS:
      return { ...state, users: payload, isLoading: false, isError: false };
    case types.GET_USERS_FAILURE:
      return { ...state, users: [], isLoading: false, isError: true };

    case types.POST_USERS_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.POST_USERS_SUCCESS:
      return {
        ...state,
        registerUser: payload.new_user,
        isLoading: false,
        isError: false,
      };
    case types.POST_USERS_FAILURE:
      return { ...state, registerUser: [], isLoading: false, isError: true };

    case types.POST_TASK_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.POST_TASK_SUCCESS:
      return { ...state, taskData: payload, isLoading: false, isError: false };
    case types.POST_TASK_FAILURE:
      return { ...state, taskData: [], isLoading: false, isError: true };

    case types.GET_TASK_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.GET_TASK_SUCCESS:
      return { ...state, taskData: payload, isLoading: false, isError: false };
    case types.GET_TASK_FAILURE:
      return { ...state, taskData: [], isLoading: false, isError: true };

    case types.POST_ATTENDANCES_REQUEST:
      return { ...state, isLoading: true, isError: null };
    case types.POST_ATTENDANCES_SUCCESS:
      return { ...state, isLoading: false, attendances: payload };
    case types.POST_ATTENDANCES_FAILURE:
      return {
        ...state,
        attendances: [],
        isLoading: false,
        isError: action.payload,
      };

    case types.FETCH_ATTENDANCES_REQUEST:
      return { ...state, isLoading: true, isError: null };
    case types.FETCH_ATTENDANCES_SUCCESS:
      return { ...state, isLoading: false, attendances: payload };
    case types.FETCH_ATTENDANCES_FAILURE:
      return {
        ...state,
        attendances: [],
        isLoading: false,
        isError: action.payload,
      };
    case types.UPDATE_ATTENDANCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        attendances: state.attendances.map((attendance) =>
          attendance._id === action.payload._id ? action.payload : attendance
        ),
      };
    case types.UPDATE_ATTENDANCE_FAILURE:
      return { ...state, isLoading: false, isError: action.payload };

    case types.POST_LEAVE_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.POST_LEAVE_SUCCESS:
      return { ...state, leaves: payload, isLoading: false, isError: false };
    case types.POST_LEAVE_FAILURE:
      return { ...state, leaves: [], isLoading: false, isError: true };

    case types.GET_LEAVE_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.GET_LEAVE_SUCCESS:
      return { ...state, leaves: payload, isLoading: false, isError: false };
    case types.GET_LEAVE_FAILURE:
      return { ...state, leaves: [], isLoading: false, isError: true };

    case types.POST_LATE_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.POST_LATE_SUCCESS:
      return { ...state, lateLogin: payload, isLoading: false, isError: false };
    case types.POST_LATE_FAILURE:
      return { ...state, lateLogin: [], isLoading: false, isError: true };

    case types.GET_LATE_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.GET_LATE_SUCCESS:
      return { ...state, lateLogin: payload, isLoading: false, isError: false };
    case types.GET_LATE_FAILURE:
      return { ...state, lateLogin: [], isLoading: false, isError: true };

    case types.POST_EARLY_LOGOUT_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.POST_EARLY_LOGOUT_SUCCESS:
      return {
        ...state,
        earlyLogout: payload,
        isLoading: false,
        isError: false,
      };
    case types.POST_EARLY_LOGOUT_FAILURE:
      return { ...state, earlyLogout: [], isLoading: false, isError: true };

    case types.GET_EARLY_LOGOUT_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.GET_EARLY_LOGOUT_SUCCESS:
      return {
        ...state,
        earlyLogout: payload,
        isLoading: false,
        isError: false,
      };
    case types.GET_EARLY_LOGOUT_FAILURE:
      return { ...state, earlyLogout: [], isLoading: false, isError: true };

    case types.POST_PROBATION_LETTER_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.POST_PROBATION_LETTER_SUCCESS:
      return {
        ...state,
        probationLetter: payload,
        isLoading: false,
        isError: false,
      };
    case types.POST_PROBATION_LETTER_FAILURE:
      return { ...state, probationLetter: [], isLoading: false, isError: true };

    case types.GET_PROBATION_LETTER_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.GET_PROBATION_LETTER_SUCCESS:
      return {
        ...state,
        probationLetter: payload,
        isLoading: false,
        isError: false,
      };
    case types.GET_PROBATION_LETTER_FAILURE:
      return { ...state, probationLetter: [], isLoading: false, isError: true };

    case types.POST_OFFER_LETTER_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.POST_OFFER_LETTER_SUCCESS:
      return {
        ...state,
        offerLetter: payload,
        isLoading: false,
        isError: false,
      };
    case types.POST_OFFER_LETTER_FAILURE:
      return { ...state, offerLetter: [], isLoading: false, isError: true };

    case types.GET_OFFER_LETTER_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.GET_OFFER_LETTER_SUCCESS:
      return {
        ...state,
        offerLetter: payload,
        isLoading: false,
        isError: false,
      };
    case types.GET_OFFER_LETTER_FAILURE:
      return { ...state, offerLetter: [], isLoading: false, isError: true };

    case types.ADD_MESSAGE:
      return { ...state, messages: [...state.messages, action.payload] };

    case types.POST_EVENT_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.POST_EVENT_SUCCESS:
      return { ...state, events: payload, isLoading: false, isError: false };
    case types.POST_EVENT_FAILURE:
      return { ...state, events: [], isLoading: false, isError: true };

    case types.GET_EVENT_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.GET_EVENT_SUCCESS:
      return { ...state, events: payload, isLoading: false, isError: false };
    case types.GET_EVENT_FAILURE:
      return { ...state, events: [], isLoading: false, isError: true };

    case types.POST_DOCUMENT_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.POST_DOCUMENT_SUCCESS:
      return { ...state, documents: payload, isLoading: false, isError: false };
    case types.POST_DOCUMENT_FAILURE:
      return { ...state, documents: [], isLoading: false, isError: true };

    case types.GET_DOCUMENT_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.GET_DOCUMENT_SUCCESS:
      return { ...state, documents: payload, isLoading: false, isError: false };
    case types.GET_DOCUMENT_FAILURE:
      return { ...state, documents: [], isLoading: false, isError: true };
  }
};
