import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import AdminSidebar from "../../../../Components/AdminSidebar";
import Navbar from "../../../../Components/Navbar";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { editUser, getUsers } from "../../../../Redux/app/action";
import { format } from "date-fns";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker-custom.css";
const EditEmployeeDetail = () => {
  const InputStyle = {
    borderRadius: "md",
    borderWidth: "2px",
    borderColor: "gray.300",
    py: "2",
    px: "4",
    variant: "unstyled",
    required: true,
  };

  const BoxStyle = {
    position: "absolute",
    top: "-8px",
    left: "8px",
    pointerEvents: "none",
    color: "green",
    fontSize: "sm",
    bg: "white",
    px: "1",
  };

  const { id } = useParams();
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);
  const users = useSelector((state) => state.AppReducer.users);

  const singleUser = users.filter((obj) => obj._id === id);
  //console.log("single by id", singleUser);

  const [formValues, setFormValues] = useState(singleUser);
  //console.log("sss", formValues[0].first_name);

  const handleUpdateEmployee = async () => {
    try {
      const payload = {
        id: formValues[0]._id,

        first_name: formValues[0].first_name,
        last_name: formValues[0].last_name,
        father_name: formValues[0].father_name,
        mother_name: formValues[0].mother_name,
        employee_id: formValues[0].employee_id,
        email: formValues[0].email,
        alternate_email: formValues[0].alternate_email,
        phone: formValues[0].phone,
        alternate_phone: formValues[0].alternate_phone,
        dob: formValues[0].dob,
        gender: formValues[0].gender,
        country: formValues[0].country,
        city: formValues[0].city,
        state: formValues[0].state,
        zip_code: formValues[0].zip_code,
        sudo_name: formValues[0].sudo_name,
        blood_group: formValues[0].blood_group,
        designation: formValues[0].designation,
        department: formValues[0].department,
        marital_status: formValues[0].marital_status,
        doj: formValues[0].doj,
        password: formValues[0].password,
        confirm_password: formValues[0].confirm_password,
        role: formValues[0].role,
        account_holder_name: formValues[0].account_holder_name,
        bank_name: formValues[0].bank_name,
        ifsc: formValues[0].ifsc,
        account_number: formValues[0].account_number,
        branch_name_name: formValues[0].branch_name,
        upi_id: formValues[0].upi_id,
        registered_phone: formValues[0].registered_phone,
        aadhar: formValues[0].aadhar,
      };

      const response = await fetch(
        `https://project360-backend.onrender.com/editUser/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(...formValues, payload),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update user.");
      }
      // Dispatch an action to update the user in the Redux store
      dispatch(editUser(...formValues, payload));
      toast({
        position: "top",
        title: "Employee Updated Successfully",
        description: "",
        status: "success",
        duration: 1500,
        isClosable: true,
      });
      setTimeout(() => {
        navigate("/employees");
      }, 1500);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Updating Employee",
        description: "An error occurred while updating the employee.",
        status: "error",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  return (
    <Flex>
      <AdminSidebar />
      <Box
        pl={{ base: "none", md: "10" }}
        pt={{ base: "none", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Users
        </Heading>
        <Flex justifyContent={"space-between"}>
          <Breadcrumb
            spacing="8px"
            mt={6}
            mb={6}
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/admin">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="/employees">User</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="#">User Details</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Flex>
        <form>
          {formValues?.map((el, index) => (
            <Box key={el._id}>
              <Flex
                direction={{ base: "column", md: "row" }}
                justifyContent={{ base: "flex-start", md: "space-between" }}
                gap={5}
              >
                <Box
                  w={{ base: "100%", md: "50%" }}
                  borderRadius={10}
                  boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
                  p={5}
                >
                  <Flex
                    direction={{ base: "column", md: "row" }}
                    justifyContent={{ base: "flex-start", md: "space-between" }}
                    key={el._id}
                  >
                    <Box
                      w={{ base: "100%", md: "45%" }}
                      p={5}
                      borderRadius={10}
                    >
                      <Box textAlign={"start"}>
                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="first_name"
                            name="first_name"
                            value={el.first_name}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                first_name: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>First Name</Box>
                        </Box>
                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="father_name"
                            name="father_name"
                            value={el.father_name}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                father_name: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Father's Name</Box>
                        </Box>

                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="email"
                            id="email"
                            name="email"
                            value={el.email}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                email: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Email Address</Box>
                        </Box>
                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="email"
                            id="alternate_email"
                            name="alternate_email"
                            value={el.alternate_email}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                alternate_email: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Alternate Email Address</Box>
                        </Box>

                        {/* <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            // type="date"
                            id="dob"
                            name="dob"
                            value={new Date(el.dob).toDateString()}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                dob: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Date Of Birth</Box>
                        </Box> */}
                        <Box position="relative" mb={5}>
                          <DatePicker
                            className="datePickerInput"
                            id="dob"
                            name="dob"
                            selected={new Date(el.dob)}
                            onChange={(date) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                dob: date.toISOString(),
                              };

                              setFormValues(updatedValues);
                            }}
                          />
                          <Box className="datePickerBox">Date Of Birth</Box>
                        </Box>

                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="city"
                            name="city"
                            value={el.city}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                city: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>City</Box>
                        </Box>

                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            maxLength={6}
                            id="zip_code"
                            name="zip_code"
                            value={el.zip_code}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                zip_code: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Zip Code</Box>
                        </Box>
                        {/* <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="role"
                            name="role"
                            value={el.role}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                role: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Role</Box>
                        </Box> */}

                        <Box position="relative" mb={5}>
                          <Select
                            id="role"
                            name="role"
                            value={el.role}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                role: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          >
                            <option value="employee">Employee</option>
                            <option value="hr">HR</option>
                            <option value="admin">Admin</option>
                          </Select>
                          <Box {...BoxStyle}>Role</Box>
                        </Box>
                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="blood_group"
                            name="blood_group"
                            value={el.blood_group}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                blood_group: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Blood Group</Box>
                        </Box>

                        {/* <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="department"
                            name="department"
                            value={el.department}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                department: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Department</Box>
                        </Box> */}

                        <Box position="relative" mb={5}>
                          <Select
                            id="department"
                            name="department"
                            value={el.department}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                department: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          >
                            <option value="Software Development">
                              Software Development
                            </option>
                            <option value="Lead Generation">
                              Lead Generation
                            </option>
                            <option value="Customer Support">
                              Customer Support
                            </option>
                            <option value="HR">HR</option>
                          </Select>
                          <Box {...BoxStyle}>Department</Box>
                        </Box>
                        {/* <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            // type="date"
                            id="doj"
                            name="doj"
                            value={new Date(el.doj).toDateString()}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                doj: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Date Of Joining</Box>
                        </Box> */}
                        <Box position="relative" mb={5}>
                          <DatePicker
                            className="datePickerInput"
                            id="doj"
                            name="doj"
                            selected={new Date(el.doj)}
                            onChange={(date) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                doj: date.toISOString(),
                              };

                              setFormValues(updatedValues);
                            }}
                          />
                          <Box className="datePickerBox">Date Of Joining</Box>
                        </Box>
                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            // type="password"
                            id="password"
                            name="password"
                            value={el.password}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = e.target.value;
                              //setFormValues(updatedValues);
                            }}
                            readOnly
                          />
                          <Box {...BoxStyle}>Create Password</Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      w={{ base: "100%", md: "45%" }}
                      p={5}
                      borderRadius={10}
                    >
                      <Box textAlign={"start"}>
                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="last_name"
                            name="last_name"
                            value={el.last_name}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                last_name: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Last Name</Box>
                        </Box>
                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="mother_name"
                            name="mother_name"
                            value={el.mother_name}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                mother_name: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Mother's Name</Box>
                        </Box>

                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            maxLength={10}
                            id="phone"
                            name="phone"
                            value={el.phone}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                phone: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Phone</Box>
                        </Box>
                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            maxLength={10}
                            id="alternate_phone"
                            name="alternate_phone"
                            value={el.alternate_phone}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                alternate_phone: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Alternate Phone</Box>
                        </Box>

                        {/* <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="gender"
                            name="gender"
                            value={el.gender}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                gender: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Gender</Box>
                        </Box> */}
                        <Box position="relative" mb={5}>
                          <Select
                            id="gender"
                            name="gender"
                            value={el.gender}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                gender: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          >
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </Select>
                          <Box {...BoxStyle}>Gender</Box>
                        </Box>

                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="state"
                            name="state"
                            value={el.state}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                state: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>State</Box>
                        </Box>
                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="country"
                            name="country"
                            value={el.country}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                country: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Country</Box>
                        </Box>
                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="sudo_name"
                            name="sudo_name"
                            value={el.sudo_name}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                sudo_name: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Sudo Name</Box>
                        </Box>

                        {/* <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="marital_status"
                            name="marital_status"
                            value={el.marital_status}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                marital_status: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Marital Status</Box>
                        </Box> */}

                        <Box position="relative" mb={5}>
                          <Select
                            id="marital_status"
                            name="marital_status"
                            value={el.marital_status}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                marital_status: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          >
                            <option value="Married">Married</option>
                            <option value="Unmarried">Unmarried</option>
                          </Select>
                          <Box {...BoxStyle}>Marital Status</Box>
                        </Box>

                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="designation"
                            name="designation"
                            value={el.designation}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                designation: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Designation</Box>
                        </Box>

                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="employee_id"
                            name="employee_id"
                            value={el.employee_id}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                employee_id: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Employee ID</Box>
                        </Box>

                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            // type="password"
                            id="confirm_password"
                            name="confirm_password"
                            value={el.confirm_password}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = e.target.value;
                              //setFormValues(updatedValues);
                            }}
                            readOnly
                          />
                          <Box {...BoxStyle}>Confirm Password</Box>
                        </Box>
                      </Box>
                    </Box>
                  </Flex>
                </Box>
                <Box
                  w={{ base: "100%", md: "50%" }}
                  borderRadius={10}
                  boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
                  p={5}
                >
                  <Flex
                    direction={{ base: "column", md: "row" }}
                    justifyContent={{ base: "flex-start", md: "space-between" }}
                    key={el._id}
                  >
                    <Box
                      w={{ base: "100%", md: "45%" }}
                      p={5}
                      borderRadius={10}
                    >
                      <Box textAlign={"start"}>
                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="account_holder_name"
                            name="account_holder_name"
                            value={el.account_holder_name}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                account_holder_name: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Account Holder Name</Box>
                        </Box>

                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="ifsc"
                            name="ifsc"
                            value={el.ifsc}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                ifsc: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>IFSC</Box>
                        </Box>

                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="branch_name"
                            name="branch_name"
                            value={el.branch_name}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                branch_name: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Branch</Box>
                        </Box>
                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="aadhar"
                            name="aadhar"
                            maxLength={12}
                            value={el.aadhar}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                aadhar: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Aadhar Number</Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      w={{ base: "100%", md: "45%" }}
                      p={5}
                      borderRadius={10}
                    >
                      <Box textAlign={"start"}>
                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="bank_name"
                            name="bank_name"
                            value={el.bank_name}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                bank_name: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Bank Name</Box>
                        </Box>

                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="account_number"
                            name="account_number"
                            value={el.account_number}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                account_number: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Account Number</Box>
                        </Box>

                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            id="upi_id"
                            name="upi_id"
                            value={el.upi_id}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                upi_id: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>UPI ID</Box>
                        </Box>

                        <Box position="relative" mb={5}>
                          <Input
                            {...InputStyle}
                            type="text"
                            maxLength={10}
                            id="registered_phone"
                            name="registered_phone"
                            value={el.registered_phone}
                            onChange={(e) => {
                              const updatedValues = [...formValues];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                registered_phone: e.target.value,
                              };
                              setFormValues(updatedValues);
                            }}
                          />
                          <Box {...BoxStyle}>Registered Phone</Box>
                        </Box>
                      </Box>
                    </Box>
                  </Flex>
                  <Flex justifyContent={"flex-end"}>
                    <Button
                      colorScheme="green"
                      size={"sm"}
                      onClick={handleUpdateEmployee}
                    >
                      Submit
                    </Button>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          ))}
        </form>
      </Box>
    </Flex>
  );
};

export default EditEmployeeDetail;
