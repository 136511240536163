import axios from "axios";
import {
  AUTH_LOADING,
  AUTH_SUCCESS,
  AUTH_ERROR,
  AUTH_LOG_OUT,
} from "./actionTypes";

//User Login
export const Userlogin = (logincred) => async (dispatch) => {
  dispatch({ type: AUTH_LOADING });
  try {
    let res = await axios.post(
      "https://project360-backend.onrender.com/login",
      logincred
    );

    dispatch({ type: AUTH_SUCCESS, payload: res.data.document });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      dispatch({
        type: AUTH_ERROR,
        error: "Invalid ID or password",
      });
    } else if (e.response && e.response.status === 404) {
      dispatch({
        type: AUTH_ERROR,
        error: "Employee ID not found",
      });
    } else {
      dispatch({ type: AUTH_ERROR, error: "An error occurred" });
    }
  }
};

export const UserloginTest = (logincred) => async (dispatch) => {
  dispatch({ type: AUTH_LOADING });
  // console.log(logincred)
  try {
    let res = await axios.post(
      "https://project360-backend.onrender.com/login",
      logincred
    );
    console.log("res", res.data);
    dispatch({ type: AUTH_SUCCESS, payload: res.data.document });
  } catch (e) {
    console.log("reserror", e);
    dispatch({ type: AUTH_ERROR, payload: e });
  }
};

//User Logout
export const Userlogout = () => ({ type: AUTH_LOG_OUT });
