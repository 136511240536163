import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineLock, AiOutlineMail } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { IoLogoGoogleplus, IoLogoTwitter } from "react-icons/io";

import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import leftsideimage from "../Logins/assets/leftsideimage.PNG";
import { useDispatch, useSelector } from "react-redux";

import { AuthContext } from "../../Context/AuthContext";
import { Userlogin, UserloginTest } from "../../Redux/auth/action";
import { getUsers } from "../../Redux/app/action";

const AdminLogin = () => {
  const [loading, setLoading] = useState(false);
  const token = useSelector((store) => store.authentication.data.token.token);
  const globalState = useSelector((store) => store.authentication.data.token);
  const globalState1 = useSelector((store) => store.authentication.users);
  const isAuth = useSelector((store) => store.authentication.data.isAuth);
  const extra = useSelector((store) => store.authentication.data.extra);
  // console.log("isAuth", isAuth);
  // console.log("extra", extra);

  //console.log("token", token);
  //console.log("gsstat", globalState1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const [usercred, setUserCred] = useState({});

  const toast = useToast();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserCred({
      ...usercred,
      [name]: value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Dispatch the login action
    await dispatch(Userlogin(usercred));
    // Once the login process is complete, setLoading to false
    setLoading(false);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };
  useEffect(() => {
    if (token) {
      if (globalState.role.toLowerCase() === "admin") {
        navigate("/admin");
        toast({
          position: "top",
          title: `Welcome Back ${globalState.sudo_name}`,
          description: "Successfully Logged In",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          position: "top",
          title: "Warning",
          description: "Check the correct routes of credentials",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    else if (extra==="extraAuth" && !isAuth){
      toast({
        position: "top",
        title: "Invalid Credentials",
        description: "Enter the correct credentials",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [token, globalState.role, globalState.sudo_name, navigate, toast,extra, isAuth]);

  const setAccount = useContext(AuthContext);

  const loginSuccess = (res) => {
    //console.log(res)
    const decoded = jwt_decode(res.credential);
    //console.log(decoded);
    setAccount(decoded);
  };

  const loginFailure = (res) => {
    //console.log("Login failed", res);
  };

  return (
    <>
      <Flex flexWrap="wrap" justifyContent="center">
        <Box p={{ base: "none", md: 10 }} bg={"white"}>
          <Image
            p={{ base: "none", md: 10 }}
            width={{ base: "100%", sm: "45%", md: "80%" }}
            src={leftsideimage}
          />
        </Box>

        <Box p={20} bg={"white"} width={{ base: "100%", sm: "45%", md: "40%" }}>
          <form onSubmit={handleLogin}>
            <FormControl>
              <Text fontSize="3xl" fontWeight={"bold"} textAlign={"start"}>
                SIGN IN TO ADMIN E&D 360
              </Text>

              <br />
              <br />

              <Flex flexWrap="wrap" justifyContent="space-between">
                <Button w={"25%"}>
                  <GoogleLogin
                    buttonText={<IoLogoGoogleplus />}
                    onSuccess={loginSuccess}
                    onError={loginFailure}
                  />
                </Button>
                <Button w={"25%"}>
                  <FaFacebookF style={{ color: "#1877f2" }} />
                </Button>
                <Button w={"25%"}>
                  <IoLogoTwitter style={{ color: "#00aaec" }} />
                </Button>
              </Flex>
              <br />
              <br />
              <Flex alignItems="center">
                <Box
                  flex="1"
                  borderBottom="1px solid"
                  borderColor="gray.400"
                  mr="4"
                />
                <Text
                  fontWeight="medium"
                  textTransform="uppercase"
                  textColor={"gray"}
                >
                  OR
                </Text>
                <Box
                  flex="1"
                  borderBottom="1px solid"
                  borderColor="gray.400"
                  ml="4"
                />
              </Flex>
              <br />
              <FormLabel>Employee Id </FormLabel>
              <InputGroup>
                <Input
                  type="text"
                  id="employee_id"
                  name="employee_id"
                  placeholder="Enter Admin Id"
                  onChange={handleChange}
                  required
                  onKeyPress={handleKeyPress}
                />
                <InputRightElement
                  style={{ background: "#00ab55" }}
                  pointerEvents="none"
                  children={<AiOutlineMail color="white" />}
                />
              </InputGroup>
              <br />
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Admin Password"
                  onChange={handleChange}
                  required
                  onKeyPress={handleKeyPress}
                />
                <InputRightElement
                  style={{ background: "#00ab55" }}
                  pointerEvents="none"
                  children={<AiOutlineLock color="white" />}
                />
              </InputGroup>
              <br />

              <Button
                type="submit"
                w={"full"}
                textColor={"white"}
                background={"#00ab55"}
                _hover={{ textColor: "white", background: "#00ab55" }}
                isLoading={loading}
                // loadingText={<Spinner size="sm" color="white" />}
              >
                Login Now
              </Button>
            </FormControl>
          </form>
        </Box>
      </Flex>
    </>
  );
};

export default AdminLogin;
