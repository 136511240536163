import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  Flex,
  Grid,
  GridItem,
  Heading,
} from "@chakra-ui/react";
import React from "react";
import Navbar from "../../../Components/Navbar";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { AiOutlineFileText } from "react-icons/ai";
import { GrCopy, GrDocumentTransfer } from "react-icons/gr";
import { Link } from "react-router-dom";
import AdminSidebar from "../../../Components/AdminSidebar";
import { SlEnvolopeLetter } from "react-icons/sl";
import { BsGraphUpArrow } from "react-icons/bs";
import { FiFile } from "react-icons/fi";

const AdminGenerateLetter = () => {
  return (
    <Flex>
      <AdminSidebar />
      <Box
        pl={{ base: "none", md: "10" }}
        pt={{ base: "none", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Generate Letters
        </Heading>
        <Breadcrumb
          spacing="8px"
          mt={6}
          mb={6}
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/admin">Dashboard</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href="#">Generate Letter</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          gap="10"
          pl={{ base: "none", md: "10" }}
        >
          <Link to="/probationletter">
            <Card w={"60%"} h={"100%"} p={10}>
              <GridItem>
                <Box
                  mx="auto"
                  mb={[2, 3, 4, 5]} // Responsive margin
                  width={["80%", "60%", "50%", "40%"]} // Responsive width
                >
                  <AiOutlineFileText
                    size={["40px", "60px", "80px", "100px"]}
                    color="black"
                  />{" "}
                  {/* Responsive size */}
                </Box>

                <Heading mt={10} size={"md"} textAlign={"center"}>
                  Probation Letter
                </Heading>
              </GridItem>
            </Card>
          </Link>

          <Link to="/offerletter">
            <Card w={"60%"} h={"100%"} p={10}>
              <GridItem>
                <Box
                  mx="auto"
                  mb={[2, 3, 4, 5]} // Responsive margin
                  width={["80%", "60%", "50%", "40%"]} // Responsive width
                >
                  <SlEnvolopeLetter
                    size={["40px", "60px", "80px", "100px"]}
                    color="black"
                  />{" "}
                  {/* Responsive size */}
                </Box>

                <Heading mt={10} size={"md"} textAlign={"center"}>
                  Offer Letter
                </Heading>
              </GridItem>
            </Card>
          </Link>
          <Link to="/incrementletter">
            <Card w={"60%"} h={"100%"} p={10}>
              <GridItem>
                <Box
                  mx="auto"
                  mb={[2, 3, 4, 5]} // Responsive margin
                  width={["80%", "60%", "50%", "40%"]} // Responsive width
                >
                  <BsGraphUpArrow
                    size={["40px", "60px", "80px", "100px"]}
                    color="black"
                  />{" "}
                  {/* Responsive size */}
                </Box>

                <Heading mt={10} size={"md"} textAlign={"center"}>
                  Increment Letter
                </Heading>
              </GridItem>
            </Card>
          </Link>
          <Link to="/terminationletter">
            <Card w={"60%"} h={"100%"} p={10}>
              <GridItem>
                <Box
                  mx="auto"
                  mb={[2, 3, 4, 5]} // Responsive margin
                  width={["80%", "60%", "50%", "40%"]} // Responsive width
                >
                  <GrDocumentTransfer
                    size={["40px", "60px", "80px", "100px"]}
                    color="black"
                  />{" "}
                  {/* Responsive size */}
                </Box>

                <Heading mt={10} size={"md"} textAlign={"center"}>
                  Termination Letter
                </Heading>
              </GridItem>
            </Card>
          </Link>
          <Link to="/probationextension">
            <Card w={"60%"} h={"100%"} p={10}>
              <GridItem>
                <Box
                  mx="auto"
                  mb={[2, 3, 4, 5]} // Responsive margin
                  width={["80%", "60%", "50%", "40%"]} // Responsive width
                >
                  <GrCopy
                    size={["40px", "60px", "80px", "100px"]}
                    color="black"
                  />{" "}
                  {/* Responsive size */}
                </Box>

                <Heading mt={10} size={"md"} textAlign={"center"}>
                  Extensin Of Probation
                </Heading>
              </GridItem>
            </Card>
          </Link>
          <Link to="/letterhead">
            <Card w={"60%"} h={"100%"} p={10}>
              <GridItem>
                <Box
                  mx="auto"
                  mb={[2, 3, 4, 5]} // Responsive margin
                  width={["80%", "60%", "50%", "40%"]} // Responsive width
                >
                  <FiFile
                    size={["40px", "60px", "80px", "100px"]}
                    color="black"
                  />{" "}
                  {/* Responsive size */}
                </Box>

                <Heading mt={10} size={"md"} textAlign={"center"}>
                  Letter Head
                </Heading>
              </GridItem>
            </Card>
          </Link>
        </Grid>
      </Box>
    </Flex>
  );
};

export default AdminGenerateLetter;
