import { Box, Button, Flex } from "@chakra-ui/react";
import React from "react";

export const PaginationOther = ({ otherPage, setotherPage, othertotalPage }) => {
  const ButtonStyle = {
    color: "green",
    variant: "outline",
    colorScheme: "green",
    size: "sm",
    
  };

  const DisabledButtonStyle = {
    ...ButtonStyle,
    _disabled: {
        colorScheme: "blue",
    },
  };

  const handlePrevClick = () => {
    if (otherPage > 1) {
        setotherPage(otherPage - 1);
    }
  };

  const handleNextClick = () => {
    if (otherPage < othertotalPage) {
        setotherPage(otherPage + 1);
    }
  };
  const getPageRange = () => {
    if (othertotalPage === 0) {
      return 0;
    } else {
      return `${otherPage} - ${othertotalPage}`;
    }
  };

  return (
    <Box>
      <Flex justifyContent="center" gap={3}>
        <Button
         {...(otherPage === 1 ? DisabledButtonStyle : ButtonStyle)}
         onClick={handlePrevClick}
         disabled={otherPage === 1}
         size={"xs"}
        >
          Prev
        </Button>
        <Button {...ButtonStyle} disabled size={"xs"}>{getPageRange()}</Button>
        <Button
           {...(otherPage === othertotalPage  ? DisabledButtonStyle : ButtonStyle)}
           onClick={handleNextClick}
           disabled={otherPage === othertotalPage}
           size={"xs"}
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};
