import * as types from "./actionTypes";
import axios from "axios";

// REGISTER USER
// export const registerUser = (payload) => (dispatch) => {
//   dispatch({ type: types.POST_USERS_REQUEST });
//   return axios
//     .post(`https://project360-backend.onrender.com/signuser`, payload)
//     .then((res) => {
//       dispatch({ type: types.POST_USERS_SUCCESS, payload: res.data });
//       //console.log("append data", res.data);
//     })

//     .catch((err) => {
//       dispatch({ type: types.POST_USERS_FAILURE, payload: err });
//     });
// };

export const registerUser = (payload) => (dispatch) => {
  dispatch({ type: types.POST_USERS_REQUEST });
  return axios
    .post(`https://project360-backend.onrender.com/signuser`, payload)
    .then((res) => {
      dispatch({ type: types.POST_USERS_SUCCESS, payload: res.data });
      console.log("append data", res.data);
    })

    .catch((err) => {
      dispatch({ type: types.POST_USERS_FAILURE, payload: err });
    });
};



//GET ALL USERS
export const getUsers = (params) => (dispatch) => {
  dispatch({ type: types.GET_USERS_REQUEST });
  return axios
    .get(`https://project360-backend.onrender.com/users`, params)
    .then((res) => {
      dispatch({ type: types.GET_USERS_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: types.GET_USERS_FAILURE, payload: err });
    });
};

// SINGLE USER
export const getSingleUser = (id) => (dispatch) => {
  dispatch({ type: types.GET_USERS_REQUEST });
  return axios
    .get(`https://project360-backend.onrender.com/singleUser/${id}`)
    .then((res) => {
      dispatch({ type: types.GET_USERS_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: types.GET_USERS_FAILURE, payload: err });
    });
};

// EDIT USER DETAILS
export const editUser = (_id, payload) => async (dispatch) => {
  dispatch({ type: types.EDIT_USERS_REQUEST });
  try {
    const res = await axios.patch(
      `https://project360-backend.onrender.com/editUser/${_id}`,
      payload
    );
    dispatch({ type: types.EDIT_USERS_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: types.EDIT_USERS_FAILURE, payload: err });
  }
};

// DELETE USER
export const deleteUser = (_id, payload) => (dispatch) => {
  dispatch({ type: types.DELETE_USERS_REQUEST });
  return axios
    .delete(
      `https://project360-backend.onrender.com/deleteUser/${_id}`,
      payload
    )
    .then((res) => {
      dispatch({ type: types.DELETE_USERS_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: types.DELETE_USERS_FAILURE, payload: err });
    });
};

// POST TASK ADMIN
export const postTask = (payload) => (dispatch) => {
  dispatch({ type: types.POST_TASK_REQUEST });
  const formData = new FormData();
  formData.append("file", payload.file);
  formData.append("title", payload.title);
  formData.append("description", payload.description);
  formData.append("team", payload.team);
  formData.append("employee", payload.employee);
  formData.append("priority", payload.priority);
  formData.append("start_date", payload.start_date);
  formData.append("end_date", payload.end_date);
  return axios
    .post(`https://project360-backend.onrender.com/admintask`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      dispatch({ type: types.POST_TASK_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: types.POST_TASK_FAILURE, payload: err });
    });
};

// Multiple Task File Testing /////

export const postTaskTest = (payload) => (dispatch) => {
  dispatch({ type: types.POST_TASK_REQUEST });

  const formData = new FormData();
  payload.files.forEach((file) => {
    formData.append("files", file); // Append each file to the "files" field
  });

  formData.append("title", payload.title);
  formData.append("description", payload.description);
  formData.append("team", payload.team);
  formData.append("employee", payload.employee);
  formData.append("priority", payload.priority);
  formData.append("start_date", payload.start_date);
  formData.append("end_date", payload.end_date);

  return axios
    .post(`https://project360-backend.onrender.com/admintasktest`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      dispatch({ type: types.POST_TASK_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: types.POST_TASK_FAILURE, payload: err });
    });
};

//////////////////////////////////

// SEE ALL TASK
export const seeAllTask = (payload) => (dispatch) => {
  dispatch({ type: types.GET_TASK_REQUEST });
  return axios
    .get(`https://project360-backend.onrender.com/seeAllTask`, payload)
    .then((res) => {
      dispatch({ type: types.GET_TASK_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: types.GET_TASK_FAILURE, payload: err });
    });
};

// DELETE TASK
export const deleteTask = (_id, payload) => (dispatch) => {
  dispatch({ type: types.DELETE_TASK_REQUEST });
  return axios
    .delete(
      `https://project360-backend.onrender.com/deleteTask/${_id}`,
      payload
    )
    .then((res) => {
      dispatch({ type: types.DELETE_TASK_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: types.DELETE_TASK_FAILURE, payload: err });
    });
};

// EDIT TASK
export const editTask = (_id, payload) => (dispatch) => {
  dispatch({ type: types.EDIT_TASK_REQUEST });

  return axios
    .patch(`https://project360-backend.onrender.com/editTask/${_id}`, payload)
    .then((res) => {
      dispatch({ type: types.EDIT_TASK_SUCCESS, payload: res.data });
      //console.log("taskstatus", payload)
    })

    .catch((err) => {
      dispatch({ type: types.EDIT_TASK_FAILURE, payload: err });
    });
};

// FETCH ATTENDENCES

export const fetchAttendances = (payload) => (dispatch) => {
  dispatch({ type: types.FETCH_ATTENDANCES_REQUEST });
  return axios
    .get(`https://project360-backend.onrender.com/attendence`, payload)
    .then((res) => {
      dispatch({ type: types.FETCH_ATTENDANCES_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: types.FETCH_ATTENDANCES_FAILURE, payload: err });
    });
};

// UPDATE ATTENDENCES TIMINGS
export const updateAttendance = (id, updatedAttendance) => {
  return async (dispatch) => {
    try {
      const response = await axios.patch(
        `https://project360-backend.onrender.com/attendence/${id}`,
        updatedAttendance
      );
      dispatch({
        type: types.UPDATE_ATTENDANCE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.UPDATE_ATTENDANCE_FAILURE,
        payload: error.response.data.error,
      });
    }
  };
};

// CREATE ATTENDANCE RECORD
export const createAttendanceRecord = (payload) => async (dispatch) => {
  dispatch({ type: types.POST_ATTENDANCES_REQUEST });
  return axios
    .post(`https://project360-backend.onrender.com/attendence`, payload)
    .then((res) => {
      dispatch({ type: types.POST_ATTENDANCES_SUCCESS, payload: res.data });
      //console.log("attendence append", res.data);
    })
    .catch((err) => {
      dispatch({ type: types.POST_ATTENDANCES_FAILURE, payload: err });
      //console.log("attendence append", payload);
    });
};

// POST LEAVE REQUEST
export const leaveletter = (payload) => (dispatch) => {
  dispatch({ type: types.POST_LEAVE_REQUEST });
  return axios
    .post(`https://project360-backend.onrender.com/leaveletter`, payload)
    .then((res) => {
      dispatch({ type: types.POST_LEAVE_SUCCESS, payload: res.data });
      //console.log("leave letter append", res.data);
    })
    .catch((err) => {
      dispatch({ type: types.POST_LEAVE_FAILURE, payload: err });
    });
};

// LEAVE APPROVAL
export const leaveapproval = (_id, payload) => (dispatch) => {
  dispatch({ type: types.EDIT_LEAVE_REQUEST });

  return axios
    .patch(
      `https://project360-backend.onrender.com/leaveletter/${_id}`,
      payload
    )
    .then((res) => {
      dispatch({ type: types.EDIT_LEAVE_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: types.EDIT_LEAVE_FAILURE, payload: err });
    });
};

// SEE ALL LETTERS
export const seeAllLetters = (payload) => (dispatch) => {
  dispatch({ type: types.GET_LEAVE_REQUEST });
  return axios
    .get(`https://project360-backend.onrender.com/leaveletter`, payload)
    .then((res) => {
      dispatch({ type: types.GET_LEAVE_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: types.GET_LEAVE_FAILURE, payload: err });
    });
};

// POST LATELOGIN REQUEST
export const lateletter = (payload) => (dispatch) => {
  dispatch({ type: types.POST_LATE_REQUEST });
  return axios
    .post(`https://project360-backend.onrender.com/lateletter`, payload)
    .then((res) => {
      dispatch({ type: types.POST_LATE_SUCCESS, payload: res.data });
      //console.log("leave letter append", res.data);
    })
    .catch((err) => {
      dispatch({ type: types.POST_LATE_FAILURE, payload: err });
    });
};

// LATELOGIN APPROVAL
export const lateapproval = (_id, payload) => (dispatch) => {
  dispatch({ type: types.EDIT_LATE_REQUEST });

  return axios
    .patch(`https://project360-backend.onrender.com/lateletter/${_id}`, payload)
    .then((res) => {
      dispatch({ type: types.EDIT_LATE_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: types.EDIT_LATE_FAILURE, payload: err });
    });
};

// SEE ALL LATELOGIN
export const seeLateLetters = (payload) => (dispatch) => {
  dispatch({ type: types.GET_LATE_REQUEST });
  return axios
    .get(`https://project360-backend.onrender.com/lateletter`, payload)
    .then((res) => {
      dispatch({ type: types.GET_LATE_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: types.GET_LATE_FAILURE, payload: err });
    });
};

// POST EARLY LOGOUT REQUEST
export const earlylogoutletter = (payload) => (dispatch) => {
  dispatch({ type: types.POST_EARLY_LOGOUT_REQUEST });
  return axios
    .post(`https://project360-backend.onrender.com/earlylogout`, payload)
    .then((res) => {
      dispatch({ type: types.POST_EARLY_LOGOUT_SUCCESS, payload: res.data });
      //console.log("early logout append", res.data);
    })
    .catch((err) => {
      dispatch({ type: types.POST_EARLY_LOGOUT_FAILURE, payload: err });
    });
};

// EARLY LOGOUT APPROVAL
export const earlylogoutapproval = (_id, payload) => (dispatch) => {
  dispatch({ type: types.EDIT_EARLY_LOGOUT_REQUEST });

  return axios
    .patch(
      `https://project360-backend.onrender.com/earlylogout/${_id}`,
      payload
    )
    .then((res) => {
      dispatch({ type: types.EDIT_EARLY_LOGOUT_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: types.EDIT_EARLY_LOGOUT_FAILURE, payload: err });
    });
};

// SEE ALL EARLY LOGOUT
export const seeearlylogoutLetters = (payload) => (dispatch) => {
  dispatch({ type: types.GET_EARLY_LOGOUT_REQUEST });
  return axios
    .get(`https://project360-backend.onrender.com/earlylogout`, payload)
    .then((res) => {
      dispatch({ type: types.GET_EARLY_LOGOUT_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: types.GET_EARLY_LOGOUT_FAILURE, payload: err });
    });
};

// POST PROBATION LETTER BY ADMIN
export const postProbationLetter =
  (selectedUser, selectedFile) => (dispatch) => {
    dispatch({ type: types.POST_PROBATION_LETTER_REQUEST });
    const formData = new FormData();
    formData.append("employee_id", selectedUser.value);
    formData.append("file", selectedFile);
    return axios
      .post(
        `https://project360-backend.onrender.com/probationletter`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        dispatch({
          type: types.POST_PROBATION_LETTER_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({ type: types.POST_PROBATION_LETTER_FAILURE, payload: err });
      });
  };

// SEE ALL LETTERS FILES UPLOADED
export const getAllProbationFiles = (payload) => (dispatch) => {
  dispatch({ type: types.GET_PROBATION_LETTER_REQUEST });
  return axios
    .get(`https://project360-backend.onrender.com/allprobationfiles`, payload)
    .then((res) => {
      dispatch({ type: types.GET_PROBATION_LETTER_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: types.GET_PROBATION_LETTER_FAILURE, payload: err });
    });
};

// SEE ALL OFFERS LETTER FILES UPLOADED
export const getAllOfferFiles = (payload) => (dispatch) => {
  dispatch({ type: types.GET_OFFER_LETTER_REQUEST });
  return axios
    .get(`https://project360-backend.onrender.com/allofferfiles`, payload)
    .then((res) => {
      dispatch({ type: types.GET_OFFER_LETTER_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: types.GET_OFFER_LETTER_FAILURE, payload: err });
    });
};

export const addMessage = (message) => {
  return { type: types.ADD_MESSAGE, payload: message };
};

export const postEvent = (payload) => (dispatch) => {
  dispatch({ type: types.POST_EVENT_REQUEST });

  const formData = new FormData();
  payload.files.forEach((file) => {
    formData.append("files", file);
  });

  formData.append("title", payload.title);
  formData.append("description", payload.description);
  formData.append("department", payload.department);
  formData.append("employee", payload.employee);
  formData.append("start", payload.start);
  formData.append("end", payload.end);

  return axios
    .post(`https://project360-backend.onrender.com/event`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      dispatch({ type: types.POST_EVENT_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: types.POST_EVENT_FAILURE, payload: err });
    });
};

export const getAllEvents = (payload) => (dispatch) => {
  dispatch({ type: types.GET_EVENT_REQUEST });
  return axios
    .get(`https://project360-backend.onrender.com/event`, payload)
    .then((res) => {
      dispatch({ type: types.GET_EVENT_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: types.GET_EVENT_FAILURE, payload: err });
    });
};

export const postDocument = (payload) => (dispatch) => {
  dispatch({ type: types.POST_DOCUMENT_REQUEST });

  const formData = new FormData();
  payload.files.forEach((file) => {
    formData.append("files", file);
  });

  formData.append("employee_id", payload.employee_id);

  return axios
    .post(`https://project360-backend.onrender.com/document`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      dispatch({ type: types.POST_DOCUMENT_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: types.POST_DOCUMENT_FAILURE, payload: err });
    });
};

export const getAllDocuments = (payload) => (dispatch) => {
  dispatch({ type: types.GET_DOCUMENT_REQUEST });
  return axios
    .get(`https://project360-backend.onrender.com/document`, payload)
    .then((res) => {
      dispatch({ type: types.GET_DOCUMENT_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: types.GET_DOCUMENT_FAILURE, payload: err });
    });
};
