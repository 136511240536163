import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Heading, Input } from '@chakra-ui/react'
import React, { useState } from 'react'
import Sidebar from '../../../Components/Sidebar'
import Navbar from '../../../Components/Navbar'
import { ChevronRightIcon } from '@chakra-ui/icons'
import AllUserList from './AllUserList'

const Chat = () => {
  const [searchQuery, setSearchQuery] = useState("");
  return (
    <Flex>
      <Sidebar />
      <Box
        pl={{ base: "none", md: "10" }}
        pt={{ base: "none", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Chats
        </Heading>
        <Flex justifyContent={"space-between"}>
          <Breadcrumb
            spacing="8px"
            mt={6}
            mb={6}
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/employee">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="#">Chat</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex justifyContent={"space-between"} gap={3}>
            <Input
              htmlSize={20}
              width="auto"
              placeholder="Search"
              focusBorderColor="green.400"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Flex>
        </Flex>
        <Box>
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent={{ base: "flex-start", md: "space-between" }}
            gap={5}
          >
            <Box
              w={{ base: "100%", md: "25%" }}
              h={700}
              borderRadius={10}
              boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
              p={5}
              border={"1px solid red"}
            >
              {/* <AllUserList /> */}
            </Box>
            <Box
              w={{ base: "100%", md: "70%" }}
              borderRadius={10}
              boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
              p={5}
              border={"1px solid blue"}
              h={700}
            >
              Chat Data
          
            </Box>
          </Flex>
        </Box>
      </Box>
    </Flex>
  )
}

export default Chat