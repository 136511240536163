import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar";
import Sidebar from "../../../Components/Sidebar";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchAttendances, updateAttendance } from "../../../Redux/app/action";
import { BsCalendar3, BsFingerprint } from "react-icons/bs";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";

const TestAttendence = () => {
  const classy = {
    borderRadius: "5px",
    padding: "10px 20px",
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "uppercase",
    transition: "background-color 0.3s ease",
  };

  const dispatch = useDispatch();
  const toast = useToast();
  useEffect(() => {
    dispatch(fetchAttendances());
  }, [dispatch]);
  const attendances = useSelector((state) => state.AppReducer.attendances);

  const globalState = useSelector((store) => store.authentication.users.id);

  const sudo_name = useSelector(
    (store) => store.authentication.users.sudo_name
  );
  const filteredData =
    attendances?.filter((obj) => obj.employee_id === globalState) || [];
  //console.log("attendence", filteredData);
  const lastId = filteredData.length - 1;
  //console.log("lastId", lastId);

  let loggedInValue;
  let breakInValue;
  if (filteredData.length > 0) {
    const lastData = filteredData[filteredData.length - 1];

    // Check if the lastData has the key 'loggedIn'
    if ("loggedIn" in lastData) {
      loggedInValue = lastData.loggedIn;
    } else {
      console.log('Key "loggedIn" not found in the last data.');
    }
    if ("breakIn" in lastData) {
      breakInValue = lastData.breakIn;
    } else {
      console.log('Key "loggedIn" not found in the last data.');
    }
  } else {
    console.log("No data in the filtered array.");
  }

  // Now you can use loggedInValue globally in your code
  //console.log("loggedInValue", loggedInValue);
  //console.log("breakInValue", breakInValue);
  const [loggedIn, setLoggedIn] = useState(false);
  const [breakIn, setBreakIn] = useState(false);

  const handleLogin = async () => {
    const newAttendance = {
      employee_id: globalState,
      date: new Date(),
      loginTime: new Date(),
      status: "Available",
      loggedIn: true,
      breakIn: false,
    };
    await fetch(`https://project360-backend.onrender.com/attendence`, {
      method: "POST",
      body: JSON.stringify(newAttendance),
      headers: {
        "Content-Type": "application/json",
      },
    });
    toast({
      position: "top",
      title: "Attendence Marked Successfully",
      description: "You have Marked Your Attendence Successfully",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleLogout = async (attendanceId) => {
    try {
      if (breakIn) {
        // Call the backend API to check the breakIn condition
        const response = await fetch(
          `https://project360-backend.onrender.com/attendence/${attendanceId}`
        );
        const data = await response.json();

        if (data.breakIn) {
          // Toast message if breakIn is true
          toast({
            position: "top",
            title: "Break Out Changed To Break In",
            description: "You have completed your working hours",
            status: "warning",
            duration: 3000,
            isClosable: true,
          });

          // Update the breakIn and loggedIn states in the backend
          await fetch(
            `https://project360-backend.onrender.com/attendence/${attendanceId}`,
            {
              method: "POST",
              body: JSON.stringify({ breakIn: true }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          await fetch(
            `https://project360-backend.onrender.com/attendence/${attendanceId}`,
            {
              method: "POST",
              body: JSON.stringify({ loggedIn: false }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          // Update the breakIn and loggedIn states in the frontend
          setBreakIn(!breakIn);
          setLoggedIn(false);
        }
      } else {
        // Toast message if breakIn is false
        toast({
          position: "top",
          title: "Successfully Logged Out",
          description: "You have completed your working hours",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      const updatedAttendance = {
        logoutTime: new Date(),
        status: "Unavailable",
        loggedIn: false,
        breakIn: false,
      };

      // Dispatch the updateAttendance action to update the attendance in the backend
      dispatch(updateAttendance(attendanceId, updatedAttendance));
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      localStorage.setItem("loggedIn", false);
      localStorage.setItem("breakIn", false);
    } catch (error) {
      // Handle error if any
      //console.error(error);
    }
  };

  ////////////////////////////////////////

  const handleLoginTest = async () => {
    const newAttendance = {
      employee_id: globalState,
      date: new Date(),
      loginTime: new Date(),
      status: "Available",
      loggedIn: true,
      breakIn: false,
    };

    try {
      const response = await fetch(
        `https://project360-backend.onrender.com/attendence`,
        {
          method: "POST",
          body: JSON.stringify(newAttendance),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // API call was successful
        toast({
          position: "top",
          title: "Attendance Marked Successfully",
          description: "You have Marked Your Attendance Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        // API call failed, show error toast
        //const errorData = await response.json();
        toast({
          position: "top",
          title: "Error",
          description: "An error occurred while processing your request",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      // An error occurred during the fetch
      toast({
        position: "top",
        title: "Error",
        description: "An error occurred while processing your request",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleLogoutTest = async (attendanceId) => {
    try {
      if (breakInValue) {
        toast({
          position: "top",
          title: "Please Break Out First",
          description: "You need to end your break before logging out.",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      } else {
        // Call the backend API to update logout
        await fetch(
          `https://project360-backend.onrender.com/attendence/${attendanceId}`,
          {
            method: "PATCH",
            body: JSON.stringify({
              logoutTime: new Date(),
              status: "Unavailable",
              loggedIn: false,
              breakIn: false,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        toast({
          position: "top",
          title: "Successfully Logged Out",
          description: "You have completed your working hours",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        setBreakIn(false);
        setLoggedIn(false);
      }
    } catch (error) {
      toast({
        position: "top",
        title: "Error",
        description: "An error occurred while processing your request",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  ////////////////////////////////////////

  //////////  Testinggg  /////////////////

  const [breakIns, setBreakIns] = useState([]);
  const [breakOuts, setBreakOuts] = useState([]);

  const handleBreakIn = (attendanceId) => {
    setBreakIn(true);
    const newBreakIn = new Date();
    setBreakIns([...breakIns, { breakInTime: newBreakIn }]);
    const newBreakInData = { breakInTime: newBreakIn };
    //console.log("newBreakInData", newBreakInData);
    axios
      .post(
        `https://project360-backend.onrender.com/updateBreakIns/${attendanceId}`,
        {
          newBreakInData: { breakInTime: newBreakIn },
        }
      )
      .then((response) => {
        //console.log("Updated breakIns from backend:", response.data);
        toast({
          position: "top",
          title: "You are On Break",
          description: "You are On Break",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          position: "top",
          title: "Error",
          description: "An error occurred while processing your request",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });

    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleBreakInAlert = () => {
    toast({
      position: "top",
      title: "You Need To Login First",
      description: "",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };
  const handleBreakOut = (attendanceId) => {
    setBreakIn(false);
    const newBreakOut = new Date();
    setBreakOuts([...breakOuts, { breakOutTime: newBreakOut }]);

    axios
      .post(
        `https://project360-backend.onrender.com/updateBreakOuts/${attendanceId}`,
        {
          newBreakOutData: { breakOutTime: newBreakOut },
        }
      )
      .then((response) => {
        toast({
          position: "top",
          title: "Your Break Is Over",
          description: "Your Break Is Over",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          position: "top",
          title: "Error",
          description: "An error occurred while processing your request",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });

    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const calculateTotalTime = (startTime, endTime) => {
    if (!startTime || !endTime) {
      return "";
    }
    const start = new Date(startTime);
    const end = new Date(endTime);
    const timeDiff = end - start;
    const totalHours = Math.floor(timeDiff / (1000 * 60 * 60));
    const totalMinutes = Math.floor(
      (timeDiff % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${totalHours} Hrs ${totalMinutes} Min`;
  };

  const parseDurationToMinutes = (duration) => {
    if (!duration || typeof duration !== "string") return NaN;

    const parts = duration.split(" ");
    let minutes = 0;

    for (let i = 0; i < parts.length; i += 2) {
      const value = parseInt(parts[i]);
      const unit = parts[i + 1].toLowerCase();

      if (isNaN(value) || (unit !== "hrs" && unit !== "min")) {
        return NaN; // Invalid duration format
      }

      if (unit === "hrs") {
        minutes += value * 60;
      } else if (unit === "min") {
        minutes += value;
      }
    }

    return minutes;
  };

  const formatDuration = (minutes) => {
    if (isNaN(minutes)) return `${0} Hrs ${0} Min`;

    const hrs = Math.floor(minutes / 60);
    const min = minutes % 60;

    return `${hrs} Hrs ${min} Min`;
  };

  const [selectedDate1, setSelectedDate1] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate1(date);
  };

  const formatDate = (date) => {
    if (!date) return ""; // Return empty string if date is not selected
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "numeric",
    }).format(date);
    return formattedDate;
  };

  const today = new Date(); // Get the current date
  const firstDayOfCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1
  );

  const selectedMonth = selectedDate1
    ? selectedDate1.getMonth()
    : today.getMonth();
  const selectedYear = selectedDate1
    ? selectedDate1.getFullYear()
    : today.getFullYear();

  const reverse = [...filteredData].reverse();
  const filteredData1 =
    reverse?.filter((obj) => {
      const date = new Date(obj.date);
      const objMonth = date.getMonth();
      const objYear = date.getFullYear();

      if (!selectedDate1) {
        // If no date is selected, show data of the current month
        return (
          date >= firstDayOfCurrentMonth &&
          objMonth === selectedMonth &&
          objYear === selectedYear
        );
      } else {
        // If a date is selected, show data for the selected month
        return objMonth === selectedMonth && objYear === selectedYear;
      }
    }) || [];

  return (
    <Flex>
      <Sidebar />
      <Box
        pl={{ base: "2", md: "10" }}
        pt={{ base: "10", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Attendence
        </Heading>
        <Flex justifyContent={"space-between"}>
          <Breadcrumb
            spacing="8px"
            mt={6}
            mb={6}
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/employee">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="#">Attendence</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="#">{sudo_name}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex gap={2}>
            {loggedInValue ? (
              <>
                <Button
                  colorScheme="red"
                  onClick={() => handleLogoutTest(filteredData[lastId]._id)}
                  disabled={breakInValue}
                  style={classy}
                  variant="link"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <BsFingerprint style={{ fontSize: "25px" }} />
                    <span style={{ marginTop: "10px", fontSize: "10px" }}>
                      Logout
                    </span>
                  </div>
                </Button>
                {breakInValue ? (
                  <Button
                    colorScheme="yellow"
                    onClick={() => handleBreakOut(filteredData[lastId]._id)}
                    style={classy}
                    variant="link"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <BsFingerprint style={{ fontSize: "25px" }} />
                      <span style={{ marginTop: "10px", fontSize: "10px" }}>
                        Break Out
                      </span>
                    </div>
                  </Button>
                ) : (
                  <Button
                    colorScheme="green"
                    onClick={() => handleBreakIn(filteredData[lastId]._id)}
                    style={classy}
                    variant="link"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <BsFingerprint style={{ fontSize: "25px" }} />
                      <span style={{ marginTop: "10px", fontSize: "10px" }}>
                        Break In
                      </span>
                    </div>
                  </Button>
                )}
              </>
            ) : (
              <>
                <Button
                  colorScheme="blue"
                  onClick={handleLoginTest}
                  style={classy}
                  variant="link"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <BsFingerprint style={{ fontSize: "25px" }} />
                    <span style={{ marginTop: "10px", fontSize: "10px" }}>
                      Log In
                    </span>
                  </div>
                </Button>
                <Button
                  colorScheme="green"
                  onClick={() => handleBreakInAlert()}
                  style={classy}
                  variant="link"
                  disabled
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <BsFingerprint style={{ fontSize: "25px", opacity: 0.5 }} />
                    <span
                      style={{
                        marginTop: "10px",
                        fontSize: "10px",
                        opacity: 0.5,
                      }}
                    >
                      Break In
                    </span>
                  </div>
                </Button>
              </>
            )}
          </Flex>
        </Flex>

        <Flex justifyContent={"end"}>
          <Box>
            <DatePicker
              selected={selectedDate1}
              // onChange={(date) => setSelectedDate1(date)}
              onChange={handleDateChange}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              placeholderText="Filter Data by Month/Year"
              customInput={
                <InputGroup>
                  <Input
                    value={formatDate(selectedDate1)}
                    placeholder="Filter Data by Month/Year"
                    style={{
                      border: "1px solid gray",
                      fontWeight: "inherit",
                    }}
                  />
                  <InputRightElement>
                    <BsCalendar3 />
                  </InputRightElement>
                </InputGroup>
              }
            />
          </Box>
        </Flex>
        <Box>
          <TableContainer overflowY={"scroll"} maxH={"650"}>
            <Table
              variant="simple"
              colorScheme="green"
              boxShadow={"md"}
              sticky="thead"
            >
              <Thead
                whiteSpace={"nowrap"}
                position="sticky"
                top="0"
                zIndex={1}
                bgColor={"white"}
              >
                <Tr boxShadow={"md"}>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Sl.No
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Date
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Login
                  </Th>

                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Logout
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Breaks
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Total Break
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Total Duration <br style={{ textAlign: "center" }} /> In
                    Office
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Total Work <br style={{ textAlign: "center" }} /> Hours
                  </Th>

                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Status
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredData1.length > 0 &&
                  filteredData1.slice(0, 23)?.map((attendance, index) => {
                    const totalDurationinOffice = calculateTotalTime(
                      attendance.loginTime,
                      attendance.logoutTime
                    );

                    let totalDurationMinutes = 0;
                    attendance.breakInTime.forEach((breakInTime, index) => {
                      if (
                        attendance.breakOutTime &&
                        attendance.breakOutTime.length > index &&
                        attendance.breakOutTime[index]
                      ) {
                        const breakOutTime = new Date(
                          attendance.breakOutTime[index]
                        );
                        totalDurationMinutes += Math.floor(
                          (breakOutTime - new Date(breakInTime)) / (1000 * 60)
                        );
                      }
                    });
                    const totalHours = Math.floor(totalDurationMinutes / 60);
                    const totalMinutes = totalDurationMinutes % 60;
                    const formattedTotalDuration = `${totalHours} Hrs ${totalMinutes} Min`;

                    const formattedTotalMinutes = parseDurationToMinutes(
                      formattedTotalDuration
                    );
                    const totalOfficeMinutes = parseDurationToMinutes(
                      totalDurationinOffice
                    );

                    const finalMinutes =
                      totalOfficeMinutes - formattedTotalMinutes;
                    const finalDuration = formatDuration(finalMinutes);

                    return (
                      <Tr key={attendance._id}>
                        <Td style={{ textAlign: "center" }}>
                          <Button size={"xs"}>{index + 1}</Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button size={"xs"}>
                            {moment(attendance.date).format("Do MMM YYYY")}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          {attendance.loginTime ? (
                            <>
                              <Button size={"xs"} color="blue.500">
                                {new Date(
                                  attendance.loginTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </Button>
                            </>
                          ) : (
                            ""
                          )}
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          {attendance.logoutTime ? (
                            <>
                              <Button size={"xs"} color="red" opacity="0.9">
                                {new Date(
                                  attendance.logoutTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </Button>
                            </>
                          ) : (
                            ""
                          )}
                        </Td>

                        <Td style={{ textAlign: "center" }}>
                          {attendance.breakInTime &&
                          attendance.breakInTime.length > 0 ? (
                            <Popover>
                              <PopoverTrigger>
                                <Button size={"xs"} color="purple.500">
                                  Click To See
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent w={470}>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverBody>
                                  <Table
                                    variant="simple"
                                    colorScheme="green"
                                    boxShadow={"md"}
                                  >
                                    <Thead>
                                      <Tr boxShadow={"md"}>
                                        <Th
                                          boxShadow={"md"}
                                          style={{ textAlign: "center" }}
                                        >
                                          Break
                                        </Th>
                                        <Th
                                          boxShadow={"md"}
                                          style={{ textAlign: "center" }}
                                        >
                                          In
                                        </Th>
                                        <Th
                                          boxShadow={"md"}
                                          style={{ textAlign: "center" }}
                                        >
                                          Out
                                        </Th>
                                        <Th
                                          boxShadow={"md"}
                                          style={{ textAlign: "center" }}
                                        >
                                          Duration
                                        </Th>
                                      </Tr>
                                    </Thead>
                                    <Tbody>
                                      {attendance.breakInTime.map(
                                        (breakInTime, index) => (
                                          <Tr key={index}>
                                            <Td style={{ textAlign: "center" }}>
                                              <Button
                                                size={"xs"}
                                                colorScheme="blue"
                                              >
                                                B{index + 1}
                                              </Button>
                                            </Td>
                                            <Td style={{ textAlign: "center" }}>
                                              <Button
                                                size={"xs"}
                                                colorScheme="green"
                                              >
                                                {new Date(
                                                  breakInTime
                                                ).toLocaleTimeString([], {
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                })}
                                              </Button>
                                            </Td>
                                            <Td style={{ textAlign: "center" }}>
                                              {attendance.breakOutTime &&
                                              attendance.breakOutTime.length >
                                                index &&
                                              attendance.breakOutTime[index] ? (
                                                <Button
                                                  size={"xs"}
                                                  colorScheme="yellow"
                                                >
                                                  {new Date(
                                                    attendance.breakOutTime[
                                                      index
                                                    ]
                                                  ).toLocaleTimeString([], {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                  })}
                                                </Button>
                                              ) : (
                                                <span>-</span>
                                              )}
                                            </Td>
                                            <Td style={{ textAlign: "center" }}>
                                              {attendance.breakOutTime &&
                                              attendance.breakOutTime.length >
                                                index &&
                                              attendance.breakOutTime[index] ? (
                                                <Button
                                                  size={"xs"}
                                                  colorScheme="cyan"
                                                >
                                                  {Math.floor(
                                                    (new Date(
                                                      attendance.breakOutTime[
                                                        index
                                                      ]
                                                    ) -
                                                      new Date(breakInTime)) /
                                                      (1000 * 60)
                                                  )}{" "}
                                                  Min
                                                </Button>
                                              ) : (
                                                <span>-</span>
                                              )}
                                            </Td>
                                          </Tr>
                                        )
                                      )}
                                    </Tbody>
                                  </Table>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          ) : (
                            ""
                          )}
                        </Td>

                        <Td style={{ textAlign: "center" }}>
                          <Button size={"xs"}>{formattedTotalDuration}</Button>
                        </Td>

                        <Td style={{ textAlign: "center" }}>
                          {attendance.loginTime && attendance.logoutTime ? (
                            <>
                              <Button size={"xs"}>
                                {totalDurationinOffice}
                              </Button>
                            </>
                          ) : (
                            ""
                          )}
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          {finalDuration ? (
                            <Button size={"xs"}>{finalDuration}</Button>
                          ) : (
                            ""
                          )}
                        </Td>

                        <Td style={{ textAlign: "center" }}>
                          <Button
                            style={{
                              backgroundColor:
                                attendance.status === "Available"
                                  ? "green"
                                  : "#FF5C5C",
                              color: "white",
                              fontWeight: "bolder",
                            }}
                            size={"xs"}
                          >
                            {attendance.status}
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
            <Box>
              {filteredData1.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Attendence Record Found
                </Alert>
              )}
            </Box>
          </TableContainer>
        </Box>
      </Box>
    </Flex>
  );
};

export default TestAttendence;
