import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Flex,
  Heading,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar";
import Sidebar from "../../../Components/Sidebar";
import { ChevronRightIcon } from "@chakra-ui/icons";

import { useDispatch, useSelector } from "react-redux";
import { editTask, seeAllTask } from "../../../Redux/app/action";
import { Pagination } from "../../../Components/Pagination";
import axios from "axios";
import {
  FaRegFile,
  FaRegFileAudio,
  FaRegFileExcel,
  FaRegFilePdf,
  FaRegFilePowerpoint,
  FaRegFileVideo,
  FaRegFileWord,
} from "react-icons/fa";
import { BsImage } from "react-icons/bs";

const Task = () => {
  const OverlayTwo = () => <ModalOverlay bg="whiteAlpha.100" />;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [overlay, setOverlay] = useState(<OverlayTwo />);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(seeAllTask());
  }, [dispatch]);
  const taskData = useSelector((state) => state.AppReducer.taskData);

  //console.log("employeetddd", taskData);

  const globalState = useSelector(
    (store) => store.authentication.data.token.employee_id
  );
  //console.log("empid", globalState);

  const sudo = useSelector(
    (store) => store.authentication.data.token.sudo_name
  );
  // const filteredData = taskData.filter((obj) => obj.employee === globalState);
  //console.log("filteredtask", filteredData);
  const filteredData = taskData.filter((obj) => obj.employee.includes(sudo));
  // const [taskUpdate, settaskUpdate] = useState(filteredData);
  // console.log("taskUpdate",taskUpdate)
  const toast = useToast();

  // const handleTaskStatusChange = (_id) => {
  //   const payload = {
  //     task_status: "Completed",
  //   };

  //   axios
  //     .patch(`https://project360-backend.onrender.com/editTask/${_id}`, payload)
  //     .then((res) => {
  //       toast({
  //         position: "top",
  //         title: "Task Status Changed Successfully",
  //         description: "",
  //         status: "info",
  //         duration: 3000,
  //         isClosable: true,
  //       });
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 3000);
  //     })
  //     .catch((err) => {
  //       toast({
  //         position: "top",
  //         title: "Error",
  //         description: "Some error occurred!! Try again ",
  //         status: "error",
  //         duration: 3000,
  //         isClosable: true,
  //       });
  //     });
  // };
  const handleTaskStatusChange = (_id, task_approval) => {
    if (task_approval === "Accepted") {
      const payload = {
        task_status: "Completed",
      };

      axios
        .patch(
          `https://project360-backend.onrender.com/editTask/${_id}`,
          payload
        )
        .then((res) => {
          toast({
            position: "top",
            title: "Task Completed Successfully",
            description: "Pending Task Completed Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch((err) => {
          toast({
            position: "top",
            title: "Error",
            description: "Some error occurred!! Try again ",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    } else if (task_approval === "Rejected") {
      toast({
        position: "top",
        title: "Error",
        description: "Please accept the task before changing the status.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else if (task_approval === "On Hold") {
      toast({
        position: "top",
        title: "Error",
        description: "Please accept the task before changing the status.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const [selectedData, setSelectedData] = useState(filteredData);
  //console.log("selectedmodal", selectedData);
  const handleOpenView = (filteredData) => {
    setSelectedData(filteredData);

    setOverlay(<OverlayTwo />);
    onOpen();
  };

  const [searchQuery, setSearchQuery] = useState("");
  // Handling Pagination Here
  const [page, setPage] = useState(1);
  let perPage = 10;
  let totalPage = Math.ceil(filteredData.length / perPage);
  let end = page * perPage;
  let start = end - perPage;
  let filteredTask = [...filteredData]
    .filter(
      (el) =>
        (el.createdAt &&
          el.createdAt.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.title &&
          el.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.description &&
          el.description.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.start_date &&
          el.start_date.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.end_date &&
          el.end_date.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.task_status &&
          el.task_status.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.priority &&
          el.priority.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.task_approval &&
          el.task_approval.toLowerCase().includes(searchQuery.toLowerCase()))
    )
    .reverse();
  let paginationData = [...filteredTask].slice(start, end);
  //console.log("statuschecking",paginationData)
  // Handling Pagination End Here

  const handleTaskStatusAccepted = (taskId) => {
    const payload = {
      task_approval: "Accepted",
    };

    dispatch(editTask(taskId, payload));
    toast({
      position: "top",
      title: "Task Accepted",
      description: "",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setTimeout(() => {
      window.location.reload();
    }, 3000); // Reload after 3 seconds
  };
  const handleTaskStatusRejected = (taskId) => {
    const payload = {
      task_approval: "Rejected",
    };

    dispatch(editTask(taskId, payload));
    toast({
      position: "top",
      title: "Task Rejected",
      description: "",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    setTimeout(() => {
      window.location.reload();
    }, 3000); // Reload after 3 seconds
  };

  const handleNavigateToAllTasks = () => {
    window.location.href = "/employee/alltasks";
  };

  const [hoveredIdTitle, setHoveredIdTitle] = useState(null);

  const handleHoverTitle = (id) => {
    setHoveredIdTitle(id);
  };

  const handleMouseLeaveTitle = () => {
    setHoveredIdTitle(null);
  };
  return (
    <Flex>
      <Sidebar />
      <Box
        pl={{ base: "none", md: "10" }}
        pt={{ base: "none", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Tasks
        </Heading>
        <Flex justifyContent={"space-between"}>
          <Breadcrumb
            spacing="8px"
            mt={6}
            mb={6}
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/employee">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="#">Task</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>

          <Flex gap={2}>
            <Button
              onClick={handleNavigateToAllTasks}
              bgGradient="linear(to-r, teal.300,  teal.100)"
              _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
            >
              Sort Tasks
            </Button>
            <Box>
              <Input
                htmlSize={20}
                width="auto"
                placeholder="Search"
                focusBorderColor="green.400"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Box>
          </Flex>
        </Flex>
        <Box>
          <TableContainer>
            <Table variant="simple" colorScheme="green" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"md"}>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Assign On
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Title
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Description
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Start Date
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    End Date
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Status
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Priority
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Task
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {paginationData.length > 0 &&
                  paginationData?.map((el) => (
                    <Tr key={el._id} boxShadow={"md"}>
                      <Td style={{ textAlign: "center" }}>
                        <Button size={"xs"}>
                          {new Date(el.createdAt).toDateString()}
                        </Button>
                      </Td>
                      {/* <Td style={{ textAlign: "center" }}>
                        <Button size={"xs"}>{el.title}</Button>
                      </Td> */}
                      <Td
                        style={{
                          textAlign: "center",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Tooltip
                          label={el.title}
                          isOpen={hoveredIdTitle === el._id}
                          placement="bottom"
                        >
                          <Button
                            size={"xs"}
                            onMouseEnter={() => handleHoverTitle(el._id)}
                            onMouseLeave={handleMouseLeaveTitle}
                            // width={hoveredIdTitle === el._id ? 'auto' : '100px'}
                          >
                            {el.title.slice(0, 15)}...
                          </Button>
                        </Tooltip>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button onClick={() => handleOpenView(el)} size={"xs"}>
                          View
                        </Button>
                      </Td>

                      <Modal
                        isCentered
                        isOpen={isOpen && selectedData !== null}
                        onClose={onClose}
                      >
                        {overlay}
                        <ModalContent>
                          <ModalHeader>Task Description</ModalHeader>
                          <ModalCloseButton />

                          <ModalBody>
                            <Stack spacing={4}>
                              <Card>
                                <CardBody>
                                  <Text>{selectedData.description}</Text>
                                </CardBody>
                              </Card>

                              {selectedData.files &&
                              selectedData.files.length > 0 ? (
                                <SimpleGrid
                                  columns={{ base: 1, md: 2 }}
                                  spacing={4}
                                >
                                  {selectedData.files.map((file, index) => (
                                    <Card key={index}>
                                      <CardBody>
                                        <Box
                                          display="flex"
                                          flexDirection="column"
                                          alignItems="center"
                                        >
                                          <Icon
                                            as={
                                              file.name.endsWith(".pdf")
                                                ? FaRegFilePdf
                                                : file.name.endsWith(".doc") ||
                                                  file.name.endsWith(".docx")
                                                ? FaRegFileWord
                                                : file.name.endsWith(".xls") ||
                                                  file.name.endsWith(".xlsx")
                                                ? FaRegFileExcel
                                                : file.name.endsWith(".ppt") ||
                                                  file.name.endsWith(".pptx")
                                                ? FaRegFilePowerpoint
                                                : file.name.endsWith(".jpg") ||
                                                  file.name.endsWith(".jpeg") ||
                                                  file.name.endsWith(".png")
                                                ? BsImage
                                                : file.name.endsWith(".mp3") ||
                                                  file.name.endsWith(".wav")
                                                ? FaRegFileAudio
                                                : file.name.endsWith(".mp4") ||
                                                  file.name.endsWith(".avi") ||
                                                  file.name.endsWith(".webm") ||
                                                  file.name.endsWith(".mov")
                                                ? FaRegFileVideo
                                                : FaRegFile
                                            }
                                            boxSize={20}
                                            color={"blue.500"}
                                          />

                                          <Text
                                            mt={2}
                                            fontWeight={"semibold"}
                                            maxWidth="80%"
                                            overflow="hidden"
                                            textOverflow="ellipsis"
                                            whiteSpace="nowrap"
                                          >
                                            {file.name.substring(
                                              0,
                                              file.name.lastIndexOf(".")
                                            )}
                                          </Text>
                                          <Text color="gray.500">
                                            {file.name.substring(
                                              file.name.lastIndexOf(".") + 1
                                            )}
                                          </Text>
                                          <Button
                                            mt={2}
                                            as="a"
                                            href={`https://project360-backend.onrender.com/${file.path}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            size={"sm"}
                                            colorScheme="cyan"
                                          >
                                            Download
                                          </Button>
                                        </Box>
                                      </CardBody>
                                    </Card>
                                  ))}
                                </SimpleGrid>
                              ) : (
                                <Text>No files available.</Text>
                              )}
                            </Stack>
                          </ModalBody>
                          <ModalFooter></ModalFooter>
                        </ModalContent>
                      </Modal>

                      <Td style={{ textAlign: "center" }}>
                        <Button size={"xs"}>
                          {new Date(el.start_date).toDateString()}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                          {el.end_date && (
                            <Button size={"xs"}>
                              {new Date(el.end_date).toDateString()}
                            </Button>
                          )}
                          {el.hourly && (
                            <Button size={"xs"} ml={2}>
                              {el.hourly}
                            </Button>
                          )}
                          {el.quarterly && (
                            <Button size={"xs"} ml={2}>
                              {el.quarterly} Quarter
                            </Button>
                          )}
                        </Td>
                      {/* <Td style={{ textAlign: "center" }}>
                        {el.end_date ? (
                          <Button size={"xs"}>
                            {new Date(el.end_date).toDateString()}
                          </Button>
                        ) : (
                          ""
                        )}
                      </Td> */}

                      <Td style={{ textAlign: "center" }}>
                        {el.task_status !== "Completed" ? (
                          <Popover>
                            <PopoverTrigger>
                              <Button
                                disabled={el.task_status === "Completed"}
                                color="blue"
                                size={"xs"}
                              >
                                {el.task_status}
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent>
                              <PopoverArrow />
                              <PopoverCloseButton />
                              <PopoverHeader>Confirmation!</PopoverHeader>
                              <PopoverBody>
                                Are you sure you have completed?
                              </PopoverBody>
                              <PopoverFooter display="" justifyContent="">
                                <ButtonGroup size="xs">
                                  <Button
                                    color="green"
                                    _hover={{
                                      color: "white",
                                      bgColor: "green",
                                    }}
                                    w={150}
                                    // onClick={() =>
                                    //   handleTaskStatusChange(el._id)
                                    // }
                                    onClick={() =>
                                      handleTaskStatusChange(
                                        el._id,
                                        el.task_approval
                                      )
                                    }
                                  >
                                    Yes
                                  </Button>
                                </ButtonGroup>
                              </PopoverFooter>
                            </PopoverContent>
                          </Popover>
                        ) : (
                          <Button color="green" disabled size={"xs"}>
                            {el.task_status}
                          </Button>
                        )}
                      </Td>

                      <Td style={{ textAlign: "center" }}>
                        <Button
                          style={{
                            color:
                              el.priority === "High"
                                ? "red"
                                : el.priority === "Low"
                                ? "orange"
                                : "#41729F",
                          }}
                          size={"xs"}
                        >
                          {el.priority}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        {el.task_approval !== "Approved" ? (
                          <Popover>
                            <PopoverTrigger>
                              <Button
                                disabled={el.task_approval === "Accepted"}
                                color={
                                  el.task_approval === "Rejected"
                                    ? "red"
                                    : el.task_approval === "On Hold"
                                    ? "blue"
                                    : "green"
                                }
                                size={"xs"}
                                fontWeight={"bold"}
                              >
                                {el.task_approval}
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent>
                              <PopoverArrow />
                              <PopoverCloseButton />
                              <PopoverHeader>Confirmation!</PopoverHeader>
                              <PopoverBody>
                                
                                {el.task_approval === "Accepted"
                                  ? "Task has been accepted"
                                  : "Are you sure you want to accept?"}
                                
                              </PopoverBody>
                              <PopoverFooter
                                display="flex"
                                justifyContent="flex-end"
                                gap={2}
                              >
                                {el.task_approval !== "Accepted" && (
                                  <ButtonGroup size="xs">
                                    <Button
                                      color="red"
                                      _hover={{
                                        color: "white",
                                        bgColor: "red",
                                      }}
                                      onClick={() =>
                                        handleTaskStatusRejected(el._id)
                                      }
                                      fontWeight={"bold"}
                                    >
                                      Reject
                                    </Button>
                                  </ButtonGroup>
                                )}
                                {el.task_approval === "Accepted" ? (
                                  <ButtonGroup size="xs">
                                    <Button
                                      color="green"
                                      _hover={{
                                        color: "white",
                                        bgColor: "green",
                                      }}
                                      fontWeight={"bold"}
                                    >
                                      Accepted
                                    </Button>
                                  </ButtonGroup>
                                ) : (
                                  <ButtonGroup size="xs">
                                    <Button
                                      color="green"
                                      _hover={{
                                        color: "white",
                                        bgColor: "green",
                                      }}
                                      onClick={() =>
                                        handleTaskStatusAccepted(el._id)
                                      }
                                      fontWeight={"bold"}
                                    >
                                      Accept
                                    </Button>
                                  </ButtonGroup>
                                )}
                              </PopoverFooter>
                            </PopoverContent>
                          </Popover>
                        ) : (
                          <Button
                            color={
                              el.task_approval === "Rejected"
                                ? "red"
                                : el.task_approval === "On Hold"
                                ? "blue"
                                : "green"
                            }
                            disabled
                            size={"xs"}
                            fontWeight={"bold"}
                          >
                            {el.task_approval}
                          </Button>
                        )}
                      </Td>

                      {/* <Td style={{ textAlign: "center" }}>
                        {el.task_approval !== "Approved" ? (
                          <Popover>
                            <PopoverTrigger>
                              <Button
                                disabled={el.task_approval === "Accepted"}
                                color={
                                  el.task_approval === "Rejected"
                                    ? "red"
                                    : el.task_approval === "On Hold"
                                    ? "blue"
                                    : "green"
                                }
                                size={"xs"}
                                fontWeight={"bold"}
                              >
                                {el.task_approval}
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent>
                              <PopoverArrow />
                              <PopoverCloseButton />
                              <PopoverHeader>Confirmation!</PopoverHeader>
                              <PopoverBody>
                                Are you sure you want to accept?
                              </PopoverBody>
                              <PopoverFooter
                                display="flex"
                                justifyContent="flex-end"
                                gap={2}
                              >
                                <ButtonGroup size="xs">
                                  <Button
                                    color="red"
                                    _hover={{ color: "white", bgColor: "red" }}
                                    onClick={() =>
                                      handleTaskStatusRejected(el._id)
                                    }
                                    fontWeight={"bold"}
                                  >
                                    Reject
                                  </Button>
                                </ButtonGroup>
                                <ButtonGroup size="xs">
                                  <Button
                                    color="green"
                                    _hover={{
                                      color: "white",
                                      bgColor: "green",
                                    }}
                                    onClick={() =>
                                      handleTaskStatusAccepted(el._id)
                                    }
                                    fontWeight={"bold"}
                                  >
                                    Accept
                                  </Button>
                                </ButtonGroup>
                              </PopoverFooter>
                            </PopoverContent>
                          </Popover>
                        ) : (
                          <Button
                            color={
                              el.task_approval === "Rejected"
                                ? "red"
                                : el.task_approval === "On Hold"
                                ? "blue"
                                : "green"
                            }
                            disabled
                            size={"xs"}
                            fontWeight={"bold"}
                          >
                            {el.task_approval}
                          </Button>
                        )}
                      </Td> */}
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            <Box>
              {paginationData.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found
                </Alert>
              )}
            </Box>
          </TableContainer>
          <Flex justifyContent={"flex-end"} mt={5}>
            <Pagination page={page} setPage={setPage} totalPage={totalPage} />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default Task;
