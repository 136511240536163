import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../Pages/Login";
// import Home from "../Pages/Home/Home";
import EmpDash from "../Pages/EmployeeDashboard/EmpDash";
import AdminDash from "../Pages/AdminDashboard/AdminDash";
import EmployeeDetails from "../Pages/EmployeeDashboard/EmpDetails/EmployeeDetails";
import Task from "../Pages/EmployeeDashboard/Task/Task";
import Chat from "../Pages/EmployeeDashboard/Chat/Chat";
import Calender from "../Pages/EmployeeDashboard/Calender/Calender";
import Attendence from "../Pages/EmployeeDashboard/Attendence/Attendence";
import GenerateLetter from "../Pages/EmployeeDashboard/GenerateLetter/GenerateLetter";
import ExitManagement from "../Pages/EmployeeDashboard/ExitManagement/ExitManagement";
import ActivityRequest from "../Pages/EmployeeDashboard/GenerateLetter/ActivityRequest/ActivityRequest";
import AdviceSuggestion from "../Pages/EmployeeDashboard/GenerateLetter/AdviceSuggestion/AdviceSuggestion";
import Complaint from "../Pages/EmployeeDashboard/GenerateLetter/Complaint/Complaint";
import EarlyLogout from "../Pages/EmployeeDashboard/GenerateLetter/EarlyLogout/EarlyLogout";
import LateLogin from "../Pages/EmployeeDashboard/GenerateLetter/LateLogin/LateLogin";
import Leave from "../Pages/EmployeeDashboard/GenerateLetter/Leave/Leave";
import Employees from "../Pages/AdminDashboard/Employees/Employees";
import Mail from "../Pages/AdminDashboard/Mail/Mail";
import AdminTask from "../Pages/AdminDashboard/AdminTask/AdminTask";
import RequiredAuth from "../PrivateRoute/RequiredAuth";
import Checkkk from "../TestFolder/Checkkk";
import SingleEmployeeDetails from "../Pages/AdminDashboard/Employees/SingleEmployeeDetails";
import OnBoardingUser from "../Pages/AdminDashboard/OnBoarding/OnBoardingUser";
import EditEmployeeDetail from "../Pages/AdminDashboard/Employees/EditEmployee/EditEmployeeDetail";
import AppliedLeaveLetter from "../Pages/EmployeeDashboard/GenerateLetter/AppliedLetter/AppliedLeaveLetter";
import AppliedLateLogin from "../Pages/EmployeeDashboard/GenerateLetter/AppliedLetter/AppliedLateLogin";
import AllLeaveRequest from "../Pages/AdminDashboard/AllLetterRequest/AllLeaveRequest";
import AllLateRequest from "../Pages/AdminDashboard/AllLetterRequest/AllLateRequest";
import AppliedEarlyLogout from "../Pages/EmployeeDashboard/GenerateLetter/AppliedLetter/AppliedEarlyLogout";
import AllEarlyLogout from "../Pages/AdminDashboard/AllLetterRequest/AllEarlyLogout";
import AdminAttendence from "../Pages/AdminDashboard/AdminAttendence/AdminAttendence";
import AdminGenerateLetter from "../Pages/AdminDashboard/AdminGenerateLetter/AdminGenerateLetter";
import ProbationLetter from "../Pages/AdminDashboard/AdminGenerateLetter/ProbationLetter/ProbationLetter";
import OfferLetter from "../Pages/AdminDashboard/AdminGenerateLetter/OfferLetter/OfferLetter";
import IncrementLetter from "../Pages/AdminDashboard/AdminGenerateLetter/IncrementLetter/IncrementLetter";
import TerminationLetter from "../Pages/AdminDashboard/AdminGenerateLetter/TerminationLetter/TerminationLetter";
import ProbationExtension from "../Pages/AdminDashboard/AdminGenerateLetter/ProbationExtension/ProbationExtension";
import LetterHead from "../Pages/AdminDashboard/AdminGenerateLetter/LetterHead/LetterHead";
import AdminCalender from "../Pages/AdminDashboard/AdminCalender/AdminCalender";
import HRGenerateLetter from "../Pages/HRDashboard/HRGenerateLetter/HRGenerateLetter";
import HRCalender from "../Pages/HRDashboard/HRCalender/HRCalender";
import HRAttendence from "../Pages/HRDashboard/HRAttendence/HRAttendence";
import HRTask from "../Pages/HRDashboard/HRTask/HRTask";
import HRDash from "../Pages/HRDashboard/HRDash";

import HRProbationLetter from "../Pages/HRDashboard/HRGenerateLetter/ProbationLetter/HRProbationLetter";
import HROfferLetter from "../Pages/HRDashboard/HRGenerateLetter/OfferLetter/HROfferLetter";
import HRIncrementLetter from "../Pages/HRDashboard/HRGenerateLetter/IncrementLetter/HRIncrementLetter";
import HRTerminationLetter from "../Pages/HRDashboard/HRGenerateLetter/TerminationLetter/HRTerminationLetter";
import HRProbationExtension from "../Pages/HRDashboard/HRGenerateLetter/ProbationExtension/HRProbationExtension";
import HRLetterHead from "../Pages/HRDashboard/HRGenerateLetter/LetterHead/HRLetterHead";
import HRDetails from "../Pages/HRDashboard/HRDetails/HRDetails";
import HRLogin from "../Pages/Logins/HRLogin";
import AdminLogin from "../Pages/Logins/AdminLogin";
import MarkAttendence from "../Pages/HRDashboard/HRAttendence/MarkAttendence";
import HREmployees from "../Pages/HRDashboard/Employees/HREmployees";
import HROnBoardingUser from "../Pages/HRDashboard/OnBoarding/HROnBoardingUser";
import HRAllLeaveRequest from "../Pages/HRDashboard/AllLetterRequest/HRAllLeaveRequest";
import HRAllLateRequest from "../Pages/HRDashboard/AllLetterRequest/HRAllLateRequest";
import HRAllEarlyLogout from "../Pages/HRDashboard/AllLetterRequest/HRAllEarlyLogout";
import HREditEmployeeDetail from "../Pages/HRDashboard/Employees/EditEmployee/HREditEmployeeDetail";
import HRSingleEmployeeDetails from "../Pages/HRDashboard/Employees/HRSingleEmployeeDetails";
import HRChat from "../Pages/HRDashboard/HRChat/HRChat";
import SeeAllDetails from "../Pages/AdminDashboard/AdminTask/SeeAllDetails";
import AdminAssignTask from "../Pages/AdminDashboard/AdminTask/AdminAssignTask";
import SeeAllTask from "../Pages/EmployeeDashboard/Task/SeeAllTask";
import TestAttendence from "../Pages/EmployeeDashboard/Attendence/TestAttendence";
import UserLogin from "../Pages/Logins/UserLogin";

///All Routess
const AllRoutes = () => {
  return (
    <>
      <Routes>
        {/* <Route path="/checkkk" element={<Checkkk />} /> */}
        <Route path="/testinglogin" element={<UserLogin />} />
        <Route path="/" element={<Login />} />
        <Route path="/hr/login" element={<HRLogin />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route
          path="/employee"
          element={
            <RequiredAuth>
              <EmpDash />
             </RequiredAuth>
          }
        />
        <Route
          path="/employees"
          element={
            <RequiredAuth>
              <Employees />
            </RequiredAuth>
          }
        />
        <Route
          path="/employeedetails"
          element={
            <RequiredAuth>
              <EmployeeDetails />
            </RequiredAuth>
          }
        />
        <Route
          path="/editemployeedetails/:id"
          element={
            <RequiredAuth>
              <EditEmployeeDetail />
            </RequiredAuth>
          }
        />
        <Route
          path="/employeedetails/:id"
          element={
            <RequiredAuth>
              <SingleEmployeeDetails />
            </RequiredAuth>
          }
        />
        <Route
          path="/task"
          element={
            <RequiredAuth>
              <Task />
            </RequiredAuth>
          }
        />
        <Route
          path="/employee/alltasks"
          element={
            <RequiredAuth>
              <SeeAllTask />
            </RequiredAuth>
          }
        />
        <Route
          path="/chat"
          element={
            <RequiredAuth>
              <Chat />
            </RequiredAuth>
          }
        />
        <Route
          path="/calender"
          element={
            <RequiredAuth>
              <Calender />
            </RequiredAuth>
          }
        />
        <Route
          path="/attendence"
          element={
            <RequiredAuth>
              <TestAttendence />
            </RequiredAuth>
          }
        />
        <Route
          path="/testattendence"
          element={
            <RequiredAuth>
              <Attendence />
            </RequiredAuth>
          }
        />
        <Route
          path="/generateletters"
          element={
            <RequiredAuth>
              <GenerateLetter />
            </RequiredAuth>
          }
        />
        <Route
          path="/appliedleaveletter"
          element={
            <RequiredAuth>
              <AppliedLeaveLetter />
            </RequiredAuth>
          }
        />
        <Route
          path="/appliedlatelogin"
          element={
            <RequiredAuth>
              <AppliedLateLogin />
            </RequiredAuth>
          }
        />
        <Route
          path="/appliedearlylogout"
          element={
            <RequiredAuth>
              <AppliedEarlyLogout />
            </RequiredAuth>
          }
        />
        <Route
          path="/exit"
          element={
            <RequiredAuth>
              <ExitManagement />
            </RequiredAuth>
          }
        />
        <Route
          path="/leave"
          element={
            <RequiredAuth>
              <Leave />
            </RequiredAuth>
          }
        />
        <Route
          path="/latelogin"
          element={
            <RequiredAuth>
              <LateLogin />
            </RequiredAuth>
          }
        />
        <Route
          path="/earlylogout"
          element={
            <RequiredAuth>
              <EarlyLogout />
            </RequiredAuth>
          }
        />
        <Route
          path="/complaint"
          element={
            <RequiredAuth>
              <Complaint />
            </RequiredAuth>
          }
        />
        <Route
          path="/advice"
          element={
            <RequiredAuth>
              <AdviceSuggestion />
            </RequiredAuth>
          }
        />
        <Route
          path="/activity"
          element={
            <RequiredAuth>
              <ActivityRequest />
            </RequiredAuth>
          }
        />
        <Route
          path="/admin"
          element={
            <RequiredAuth>
              <AdminDash />
            </RequiredAuth>
          }
        />
        <Route
          path="/admintask"
          element={
            <RequiredAuth>
              {/* <AdminTask /> */}
              <AdminAssignTask />
            </RequiredAuth>
          }
        />
        <Route
          path="/adminattendence"
          element={
            <RequiredAuth>
              <AdminAttendence />
            </RequiredAuth>
          }
        />
        <Route
          path="/admincalender"
          element={
            <RequiredAuth>
              <AdminCalender />
            </RequiredAuth>
          }
        />
        <Route
          path="/leaverequest"
          element={
            <RequiredAuth>
              <AllLeaveRequest />
            </RequiredAuth>
          }
        />
        <Route
          path="/lateloginrequest"
          element={
            <RequiredAuth>
              <AllLateRequest />
            </RequiredAuth>
          }
        />
        <Route
          path="/earlylogoutrequest"
          element={
            <RequiredAuth>
              <AllEarlyLogout />
            </RequiredAuth>
          }
        />
        <Route path="/onboardingUser" element={<OnBoardingUser />} />
        <Route
          path="/mail"
          element={
            <RequiredAuth>
              <Mail />
            </RequiredAuth>
          }
        />
        <Route
          path="/admingenerateletters"
          element={
            <RequiredAuth>
              <AdminGenerateLetter />
            </RequiredAuth>
          }
        />
        <Route
          path="/probationletter"
          element={
            <RequiredAuth>
              <ProbationLetter />
            </RequiredAuth>
          }
        />
        <Route
          path="/offerletter"
          element={
            <RequiredAuth>
              <OfferLetter />
            </RequiredAuth>
          }
        />
        <Route
          path="/incrementletter"
          element={
            <RequiredAuth>
              <IncrementLetter />
            </RequiredAuth>
          }
        />
        <Route
          path="/terminationletter"
          element={
            <RequiredAuth>
              <TerminationLetter />
            </RequiredAuth>
          }
        />
        <Route
          path="/probationextension"
          element={
            <RequiredAuth>
              <ProbationExtension />
            </RequiredAuth>
          }
        />
        <Route
          path="/letterhead"
          element={
            <RequiredAuth>
              <LetterHead />
            </RequiredAuth>
          }
        />

        <Route
          path="/hr"
          element={
            <RequiredAuth>
              <HRDash />
            </RequiredAuth>
          }
        />
        <Route
          path="/hr/details"
          element={
            <RequiredAuth>
              <HRDetails />
            </RequiredAuth>
          }
        />
        <Route
          path="/hr/task"
          element={
            <RequiredAuth>
              <HRTask />
            </RequiredAuth>
          }
        />
        <Route
          path="/hr/chat"
          element={
            <RequiredAuth>
              <HRChat />
            </RequiredAuth>
          }
        />
        <Route
          path="/hr/allattendence"
          element={
            <RequiredAuth>
              <HRAttendence />
            </RequiredAuth>
          }
        />
        <Route
          path="/hr/attendence"
          element={
            <RequiredAuth>
              <MarkAttendence />
            </RequiredAuth>
          }
        />
        <Route
          path="/hr/calender"
          element={
            <RequiredAuth>
              <HRCalender />
            </RequiredAuth>
          }
        />
        <Route
          path="/hr/leaverequest"
          element={
            <RequiredAuth>
              <HRAllLeaveRequest />
            </RequiredAuth>
          }
        />
        <Route
          path="/hr/lateloginrequest"
          element={
            <RequiredAuth>
              <HRAllLateRequest />
            </RequiredAuth>
          }
        />
        <Route
          path="/hr/earlylogoutrequest"
          element={
            <RequiredAuth>
              <HRAllEarlyLogout />
            </RequiredAuth>
          }
        />
        <Route path="/hr/onboardingUser" element={<HROnBoardingUser />} />
        <Route
          path="/hr/employees"
          element={
            <RequiredAuth>
              <HREmployees />
            </RequiredAuth>
          }
        />

        <Route
          path="/hr/editemployeedetails/:id"
          element={
            <RequiredAuth>
              <HREditEmployeeDetail />
            </RequiredAuth>
          }
        />
        <Route
          path="/hr/employeedetails/:id"
          element={
            <RequiredAuth>
              <HRSingleEmployeeDetails />
            </RequiredAuth>
          }
        />

        <Route
          path="/hr/generateletters"
          element={
            <RequiredAuth>
              <HRGenerateLetter />
            </RequiredAuth>
          }
        />
        <Route
          path="/hr/probationletter"
          element={
            <RequiredAuth>
              <HRProbationLetter />
            </RequiredAuth>
          }
        />
        <Route
          path="/hr/offerletter"
          element={
            <RequiredAuth>
              <HROfferLetter />
            </RequiredAuth>
          }
        />
        <Route
          path="/hr/incrementletter"
          element={
            <RequiredAuth>
              <HRIncrementLetter />
            </RequiredAuth>
          }
        />
        <Route
          path="/hr/terminationletter"
          element={
            <RequiredAuth>
              <HRTerminationLetter />
            </RequiredAuth>
          }
        />
        <Route
          path="/hr/probationextension"
          element={
            <RequiredAuth>
              <HRProbationExtension />
            </RequiredAuth>
          }
        />
        <Route
          path="/hr/letterhead"
          element={
            <RequiredAuth>
              <HRLetterHead />
            </RequiredAuth>
          }
        />
        <Route
          path="/alltasks"
          element={
            <RequiredAuth>
              <SeeAllDetails />
            </RequiredAuth>
          }
        />
        {/* <Route
          path="/dummyroute"
          element={
            <RequiredAuth>
              <AdminAssignTask />
            </RequiredAuth>
          }
        /> */}
      </Routes>
    </>
  );
};

export default AllRoutes;
