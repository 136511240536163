export const AUTH_LOADING = "signin/loading";

export const AUTH_SUCCESS = "signin/success";

export const AUTH_ERROR = "signin/error";

export const AUTH_LOG_OUT = "signout";

export const REGISTER_LODING="register/loding";

export const REGISTER_SUCCESS="register/success";

export const REGISTER_ERROR="register/error";

