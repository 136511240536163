import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import Navbar from "../../../../Components/Navbar";
import AdminSidebar from "../../../../Components/AdminSidebar";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { getAllOfferFiles } from "../../../../Redux/app/action";

const LetterHead = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOfferFiles());
  }, [dispatch]);

  const users = useSelector((state) => state.AppReducer.users);
  const [selectedUser, setSelectedUser] = useState({});
  const toast = useToast();
  const options = [];
  users.forEach((user) => {
    const option = {
      value: user._id,
      label: user.sudo_name,
    };
    options.push(option);
  });

  const handleSelectChange = (selectedOption) => {
    setSelectedUser(selectedOption);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    setSelectedFile(event.dataTransfer.files[0]);
  };

  const handleFileUpload = () => {
    const formData = new FormData();
    formData.append("employee_id", selectedUser.value);
    formData.append("file", selectedFile);

    axios
      .post("https://project360-backend.onrender.com/offerletter", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        toast({
          position: "top",
          title: "Offer Letter Send Successfully",
          description: "Offer Letter Send Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        //console.error("Error uploading file:", error);
      });
  };

  return (
    <Flex>
      <AdminSidebar />
      <Box
        pl={{ base: "none", md: "10" }}
        pt={{ base: "10", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Letter Head
        </Heading>
        <Breadcrumb
          spacing="8px"
          mt={6}
          mb={6}
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/admin">Dashboard</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href="/admingenerateletters">
              Generate Letter
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="#">Letter Head</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent={{ base: "flex-start", md: "space-between" }}
        >
          <Box
            w={{ base: "100%", md: "100%" }}
            p={10}
            borderRadius={10}
            boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
          >
            <Box style={{ position: "relative" }}>
              <Select
                onChange={handleSelectChange}
                options={options}
                placeholder=""
                required
                name="to"
                id="to"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "md",
                    borderWidth: "2px",
                    borderColor: "gray.300",
                    padding: "2px 4px",
                    fontWeight: "semibold",
                    textAlign: "start",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 2,
                    textAlign: "start",
                  }),
                }}
              />

              <Box
                style={{
                  position: "absolute",
                  top: "-8px",
                  left: "8px",
                  pointerEvents: "none",
                  color: "gray",
                  fontSize: "xs",
                  backgroundColor: "white",
                  padding: "0 2px",
                }}
              >
                Select Employees
              </Box>
            </Box>
            <Box>
              <Heading as="h4" size="md" mt={10}>
                UPLOAD A FILE
              </Heading>
              <Flex direction="column" align="center" mt={10}>
                <Box
                  p={20}
                  borderWidth={2}
                  borderRadius="md"
                  borderStyle="dashed"
                  onDrop={handleFileDrop}
                  onDragOver={(event) => event.preventDefault()}
                >
                  {selectedFile ? (
                    <Heading as="h4" size="md">
                      {selectedFile.name}
                    </Heading>
                  ) : (
                    <Text>
                      Drag and drop a file here, or click to select a file
                    </Text>
                  )}
                </Box>

                <input
                  type="file"
                  id="file"
                  multiple
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label htmlFor="file">
                  <Button mt={4} as="span" size={"sm"} colorScheme="messenger">
                    Select File
                  </Button>
                </label>

                {selectedFile && (
                  <Flex direction="row" mt={4} gap={2}>
                    <Button
                      onClick={handleFileUpload}
                      mt={2}
                      as="a"
                      size={"sm"}
                      colorScheme="twitter"
                    >
                      Upload
                    </Button>
                    <Button
                      mt={2}
                      as="a"
                      href={URL.createObjectURL(selectedFile)}
                      target="_blank"
                      rel="noopener noreferrer"
                      size={"sm"}
                      colorScheme="cyan"
                    >
                      View
                    </Button>
                  </Flex>
                )}
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default LetterHead;
