import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
} from "@chakra-ui/react";
import React from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css"; // Import the default styles
import { useDispatch, useSelector } from "react-redux";
import { seeAllTask } from "../../../Redux/app/action";
import { useEffect } from "react";
import Navbar from "../../../Components/Navbar";
import Sidebar from "../../../Components/Sidebar";

const localizer = momentLocalizer(moment);

const CustomEvent = ({ event }) => {
  return (
    <Box className="custom-event" textAlign={"start"}>
      <Heading as="h5" size="sm">
        Title : {event.title}
      </Heading>
      <Box>Description : {event.description}</Box>
      <Box>Department: {event.department.join(", ")}</Box>
      <Box>Employee : {event.employee.join(", ")}</Box>
      <Box>Status : {event.task_status}</Box>
      <Box>Task : {event.task_approval}</Box>
    </Box>
  );
};

const SeeAllTask = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(seeAllTask());
  }, [dispatch]);

  const taskData = useSelector((state) => state.AppReducer.taskData);
  //console.log("taskData", taskData);

  const sudo = useSelector(
    (store) => store.authentication.data.token.sudo_name
  );

  const filteredData = taskData.filter((obj) => obj.employee.includes(sudo));
 

  const eventsData = filteredData.map((el) => ({
    _id: el._id,
    title: el.title,
    start_date: new Date(el.start_date),
    end_date: new Date(el.end_date),
    description: el.description,
    department: el.department,
    employee: el.employee,
    task_status: el.task_status,
    task_approval: el.task_approval,
  }));

  //console.log("evdat", eventsData);

  return (
    <Flex>
      <Sidebar />
      <Box
        pl={{ base: "2", md: "10" }}
        pt={{ base: "10", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          All Detailed Tasks
        </Heading>
        <Flex justifyContent={"space-between"}>
          <Breadcrumb
            spacing="8px"
            mt={6}
            mb={6}
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/employee">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="/task">Tasks</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Flex>
        <Calendar
          localizer={localizer}
          events={eventsData}
          startAccessor="start_date"
          endAccessor="end_date"
          components={{
            event: CustomEvent,
          }}
        />
      </Box>
    </Flex>
  );
};

export default SeeAllTask;
