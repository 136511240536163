import React from "react";
import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Avatar,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { HamburgerIcon, BellIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { Userlogout } from "../Redux/auth/action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUsers } from "../Redux/app/action";

const Navbar = () => {
  const globalState = useSelector((store) => store.authentication.data.token);

  const { isOpen, onToggle } = useDisclosure();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);
  const id = useSelector((store) => store.authentication.users.id);
  const users = useSelector((store) => store.AppReducer.users);
  const filteredUser = users?.filter((obj) => obj._id === id) || [];

  const handleNotificationClick = () => {
    // Implement notification click logic here
  };

  const handleProfileClick = () => {
    navigate("/employeedetails");
  };

  const navigate = useNavigate();
  const toast = useToast();

  const handleSignOut = () => {
    dispatch(Userlogout());
    localStorage.removeItem("username");
    // localStorage.removeItem("loggedIn");
    // localStorage.removeItem("breakIn");
    localStorage.removeItem("state");

    toast({
      position: "top",
      title: `${globalState.sudo_name}`,
      description: "Successfully Logged Out",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    if (globalState.role === "employee") {
      navigate("/");
    } else if (globalState.role === "hr") {
      navigate("/hr/login");
    } else {
      navigate("/admin/login");
    }
  };

  return (
    <Flex
      as="nav"
      align="center"
      justify={{ base: "space-between", md: "flex-end" }}
      paddingX={6}
      paddingY={4}
      color="teal"
      boxShadow="sm"
      zIndex="sticky"
      borderRadius={10}
    >
      {/* Hamburger Menu */}
      <IconButton
        icon={<HamburgerIcon />}
        variant="ghost"
        display={{ base: "block", md: "none" }}
        onClick={onToggle}
        aria-label="Toggle navigation"
      />

      <Box display={{ base: isOpen ? "block" : "none", md: "block" }}>
        {/* Notification Bell */}
        <IconButton
          icon={<BellIcon />}
          ml={2}
          colorScheme="teal"
          onClick={handleNotificationClick}
          aria-label="Notifications"
        />

        {/* Profile Picture */}
        <Menu>
          <MenuButton
            as={IconButton}
            icon={
              <Avatar
                size="sm"
                name={globalState.first_name}
                // src={`https://project360-backend.onrender.com/${filteredUser[0].path}`}
                src={
                  filteredUser[0]
                    ? `https://project360-backend.onrender.com/${filteredUser[0].path}`
                    : `https://project360-backend.onrender.com/${globalState.path}`
                }
              />
            }
            colorScheme="white"
            ml={2}
          />
          <MenuList>
            <MenuItem onClick={handleProfileClick}>Profile</MenuItem>

            <MenuItem onClick={handleSignOut}>Logout</MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
};

export default Navbar;
