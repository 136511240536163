import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar";
import { ChevronRightIcon } from "@chakra-ui/icons";

import { Link, useNavigate } from "react-router-dom";
import AdminSidebar from "../../../Components/AdminSidebar";
import { fetchAttendances, getUsers } from "../../../Redux/app/action";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "../../../Components/Pagination";
import { FiEdit } from "react-icons/fi";
import { AiOutlineEye } from "react-icons/ai";
const Employees = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const users = useSelector((state) => state.AppReducer.users);
  //console.log("allusers", users);

  useEffect(() => {
    dispatch(fetchAttendances());
  }, [dispatch]);
  const attendances = useSelector((state) => state.AppReducer.attendances);
  //console.log("adatt", attendances);
  const [searchQuery, setSearchQuery] = useState("");
  // Handling Pagination Here
  const [page, setPage] = useState(1);
  let perPage = 9;
  let totalPage = Math.ceil(users.length / perPage);
  let end = page * perPage;
  let start = end - perPage;
  let employeesData = users
    .filter(
      (el) =>
        (el.first_name &&
          el.first_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.last_name &&
          el.last_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.father_name &&
          el.father_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.mother_name &&
          el.mother_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.employee_id &&
          el.employee_id.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.email &&
          el.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.alternate_email &&
          el.alternate_email
            .toLowerCase()
            .includes(searchQuery.toLowerCase())) ||
        (el.alternate_phone &&
          el.alternate_phone
            .toLowerCase()
            .includes(searchQuery.toLowerCase())) ||
        (el.department &&
          el.department.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.designation &&
          el.designation.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.sudo_name &&
          el.sudo_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.phone &&
          el.phone.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.aadhar &&
          el.aadhar.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.marital_status &&
          el.marital_status
            .toLowerCase()
            .includes(searchQuery.toLowerCase())) ||
        (el.doj && el.doj.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.dob && el.dob.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.bank_name &&
          el.bank_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.account_number &&
          el.account_number
            .toLowerCase()
            .includes(searchQuery.toLowerCase())) ||
        (el.aadhar &&
          el.aadhar.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.upi_id &&
          el.upi_id.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.city &&
          el.city.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.state &&
          el.state.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.zip_code &&
          el.zip_code.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.gender &&
          el.gender.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.blood_group &&
          el.blood_group.toLowerCase().includes(searchQuery.toLowerCase()))
    )
    .reverse();
  let paginationData = employeesData.slice(start, end);
  // Handling Pagination End Here

  const getStatusColor = (status) => {
    return status === "Available" ? "green" : "red";
  };
  const navigate = useNavigate();
  return (
    <Flex>
      <AdminSidebar />
      <Box
        pl={{ base: "none", md: "10" }}
        pt={{ base: "none", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Users OnBoarded - {users.length - 1}
        </Heading>
        <Flex justifyContent={"space-between"}>
          <Breadcrumb
            spacing="8px"
            mt={6}
            mb={6}
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/admin">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="/employees">User</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex justifyContent={"space-between"} gap={3}>
            <Input
              htmlSize={20}
              width="auto"
              placeholder="Search"
              focusBorderColor="green.400"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Link to="/onboardingUser">
              <Button
                bgGradient="linear(to-r, teal.300,  teal.100)"
                _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
              >
                On Boarding
              </Button>
            </Link>
          </Flex>
        </Flex>
        <Box>
          <TableContainer>
            <Table variant="simple" colorScheme="green" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"md"}>
                  <Th
                    boxShadow={"md"}
                    style={{ textAlign: "center" }}
                    _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                  >
                    View
                  </Th>
                  <Th
                    boxShadow={"md"}
                    style={{ textAlign: "center" }}
                    _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                  >
                    Name
                  </Th>
                  <Th
                    boxShadow={"md"}
                    style={{ textAlign: "center" }}
                    _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                  >
                    Employee ID
                  </Th>
                  <Th
                    boxShadow={"md"}
                    style={{ textAlign: "center" }}
                    _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                  >
                    Sudo Name
                  </Th>
                  <Th
                    boxShadow={"md"}
                    style={{ textAlign: "center" }}
                    _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                  >
                    Email
                  </Th>
                  <Th
                    boxShadow={"md"}
                    style={{ textAlign: "center" }}
                    _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                  >
                    Department
                  </Th>
                  <Th
                    boxShadow={"md"}
                    style={{ textAlign: "center" }}
                    _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                  >
                    Designation
                  </Th>

                  <Th
                    boxShadow={"md"}
                    style={{ textAlign: "center" }}
                    _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                  >
                    Phone
                  </Th>
                  <Th
                    boxShadow={"md"}
                    style={{ textAlign: "center" }}
                    _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                  >
                    Aadhar Number
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {paginationData.length > 0 &&
                  paginationData?.map((el) => {
                    const reversedAttendances = [...attendances].reverse();
                    const attendance = reversedAttendances.find(
                      (attendance) => attendance.employee_id === el._id
                    );

                    const statusColor = attendance
                      ? getStatusColor(attendance.status)
                      : "gray";
                    return (
                      <Tr key={el._id} boxShadow={"md"}>
                        <Td style={{ textAlign: "center" }}>
                          {" "}
                          <Button
                            onClick={() => {
                              navigate(`/employeedetails/${el._id}`);
                            }}
                            size={"xs"}
                            _hover={{
                              bgGradient: "linear(to-r, teal.100,  teal.400)",
                            }}
                          >
                            <AiOutlineEye />
                          </Button>
                        </Td>
                        <Link to={`/employeedetails/${el._id}`}>
                          <Td style={{ textAlign: "center" }}>
                            <Wrap>
                              <WrapItem>
                                <Flex justifyContent={"space-around"} gap={2}>
                                  <Box
                                    style={{
                                      position: "relative",
                                      display: "inline-block",
                                    }}
                                  >
                                    <Avatar
                                      name={el.first_name}
                                      size="sm"
                                      src={`https://project360-backend.onrender.com/${el.path}`}
                                    />

                                    <span
                                      style={{
                                        position: "absolute",
                                        bottom: "0px",
                                        right: "-1px",
                                        display: "inline-block",
                                        width: "10px",
                                        height: "10px",
                                        borderRadius: "50%",
                                        backgroundColor: statusColor,
                                      }}
                                    />
                                  </Box>
                                  <Heading size={"xs"}>
                                    <Button
                                      size={"xs"}
                                      style={{
                                        maxWidth: "100%",
                                        width: "120px",
                                      }}
                                      mt={1}
                                      _hover={{
                                        bgGradient:
                                          "linear(to-r, teal.100,  teal.400)",
                                      }}
                                    >
                                      {el.first_name} {el.last_name}
                                    </Button>
                                  </Heading>
                                </Flex>
                              </WrapItem>
                            </Wrap>
                          </Td>
                        </Link>

                        <Td style={{ textAlign: "center" }}>
                          <Button
                            _hover={{
                              bgGradient: "linear(to-r, teal.100,  teal.400)",
                            }}
                            size={"xs"}
                            style={{ maxWidth: "100%", width: "120px" }}
                          >
                            {el.employee_id}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button
                            _hover={{
                              bgGradient: "linear(to-r, teal.100,  teal.400)",
                            }}
                            size={"xs"}
                            style={{ maxWidth: "100%", width: "100px" }}
                          >
                            {el.sudo_name}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button
                            _hover={{
                              bgGradient: "linear(to-r, teal.100,  teal.400)",
                            }}
                            size={"xs"}
                            style={{ maxWidth: "100%", width: "170px" }}
                          >
                            {el.email}
                          </Button>
                        </Td>

                        <Td style={{ textAlign: "center" }}>
                          <Button
                            _hover={{
                              bgGradient: "linear(to-r, teal.100,  teal.400)",
                            }}
                            size={"xs"}
                            style={{ maxWidth: "100%", width: "135px" }}
                          >
                            {el.department}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button
                            _hover={{
                              bgGradient: "linear(to-r, teal.100,  teal.400)",
                            }}
                            size={"xs"}
                            style={{ maxWidth: "100%", width: "180px" }}
                          >
                            {el.designation}
                          </Button>
                        </Td>

                        <Td style={{ textAlign: "center" }}>
                          <Button
                            _hover={{
                              bgGradient: "linear(to-r, teal.100,  teal.400)",
                            }}
                            size={"xs"}
                            style={{ maxWidth: "100%", width: "85px" }}
                          >
                            {el.phone}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button
                            _hover={{
                              bgGradient: "linear(to-r, teal.100,  teal.400)",
                            }}
                            size={"xs"}
                            style={{ maxWidth: "100%", width: "100px" }}
                          >
                            {el.aadhar ? el.aadhar : ""}
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
            <Box>
              {paginationData.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found
                </Alert>
              )}
            </Box>
          </TableContainer>
          <Flex justifyContent={"flex-end"} mt={5}>
            <Pagination page={page} setPage={setPage} totalPage={totalPage} />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default Employees;
