import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Pagination } from "../../../Components/Pagination";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEvents,
  getUsers,
  seeAllLetters,
  seeAllTask,
} from "../../../Redux/app/action";
import LeaveLetter from "./LeaveLetter";
import Late from "./Late";
import Early from "./Early";
import { Link } from "react-router-dom";
import { Tooltip as TooltipChakra } from "@chakra-ui/react";

ChartJS.register(ArcElement, Tooltip, Legend);

const AdminHomeDash = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(seeAllLetters());
  }, [dispatch]);

  const totaluser = useSelector((state) => state.AppReducer.users.length);

  const id = useSelector((store) => store.authentication.users.id);
  const users = useSelector((store) => store.AppReducer.users);
  const filteredUser = users?.filter((obj) => obj._id === id) || [];

  //Task

  useEffect(() => {
    dispatch(seeAllTask());
  }, [dispatch]);
  const taskData = useSelector((state) => state.AppReducer.taskData);
  //console.log("taskdata", taskData);
  // Handling Pagination Here
  const [page, setPage] = useState(1);

  let perPage = 5;

  let totalPage = Math.ceil(taskData.length / perPage);

  let end = page * perPage;
  let start = end - perPage;
  let filteredTask = [...taskData].reverse();
  let paginationData = [...filteredTask].slice(start, end);
  //console.log("revtaskdata", paginationData);

  // Handling Pagination End Here

  const [tabIndex, setTabIndex] = useState(0);

  const handleSliderChange = (event) => {
    setTabIndex(parseInt(event.target.value, 10));
  };

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const [tabIndex2, setTabIndex2] = useState(0);

  const handleTabsChange2 = (index) => {
    setTabIndex2(index);
  };

  const [tabIndex3, setTabIndex3] = useState(0);

  const handleTabsChange3 = (index) => {
    setTabIndex3(index);
  };

  ////////////// TASK STATUS CHART DATA //////////////////

  const [selectedTaskStatusLabels, setSelectedTaskStatusLabels] = useState([]);

  const statusCounts = {
    Completed: taskData.filter((task) => task.task_status === "Completed")
      .length,
    Pending: taskData.filter((task) => task.task_status === "Pending").length,
  };
  const getTaskStatusData = () => {
    if (
      selectedTaskStatusLabels.length === 0 ||
      selectedTaskStatusLabels.includes("Task Status")
    ) {
      return Object.values(statusCounts);
    }
    return [];
  };

  const getTaskStatusLabels = () => {
    const labels = [];
    if (
      selectedTaskStatusLabels.length === 0 ||
      selectedTaskStatusLabels.includes("Task Status")
    ) {
      labels.push(...Object.keys(statusCounts));
    }
    return labels;
  };

  // Prepare the chart data
  const chartTaskStatusData = {
    labels: getTaskStatusLabels(),
    datasets: [
      {
        data: getTaskStatusData(),
        backgroundColor: ["#4CAF50", "#36A2EB"],
      },
    ],
  };

  ////////////// TASK PRIORITY CHART DATA //////////////////

  const [selectedPriorityLabels, setSelectedPriorityLabels] = useState([]);

  const priorityCounts = {
    High: taskData.filter((task) => task.priority === "High").length,
    Low: taskData.filter((task) => task.priority === "Low").length,
    Medium: taskData.filter((task) => task.priority === "Medium").length,
  };
  const getPriorityData = () => {
    if (
      selectedPriorityLabels.length === 0 ||
      selectedPriorityLabels.includes("Priority")
    ) {
      return Object.values(priorityCounts);
    }
    return [];
  };

  const getPriorityLabels = () => {
    const labels = [];
    if (
      selectedPriorityLabels.length === 0 ||
      selectedPriorityLabels.includes("Priority")
    ) {
      labels.push(...Object.keys(priorityCounts));
    }
    return labels;
  };

  // Prepare the chart data
  const chartPriorityData = {
    labels: getPriorityLabels(),
    datasets: [
      {
        data: getPriorityData(),
        backgroundColor: ["#e53e3e", "#FFCE56", "#FFFFA8"],
      },
    ],
  };
  //////////////LEAVE LETTER CHART DATA ///////////////
  const leavesData = useSelector((state) => state.AppReducer.leaves);
  const [selectedLeavesLabels, setSelectedLeavesLabels] = useState([]);

  const leavestatusCounts = {
    Approved: leavesData.filter((leave) => leave.leave_status === "Approved")
      .length,
    Pending: leavesData.filter((leave) => leave.leave_status === "Pending")
      .length,
    Rejected: leavesData.filter((leave) => leave.leave_status === "Rejected")
      .length,
  };
  const getLeaveData = () => {
    if (
      selectedLeavesLabels.length === 0 ||
      selectedLeavesLabels.includes("Leave Letter")
    ) {
      return Object.values(leavestatusCounts);
    }
    return [];
  };

  const getLeaveLabels = () => {
    const labels = [];
    if (
      selectedLeavesLabels.length === 0 ||
      selectedLeavesLabels.includes("Leave Letter")
    ) {
      labels.push(...Object.keys(leavestatusCounts));
    }
    return labels;
  };

  // Prepare the chart data
  const chartLeaveData = {
    labels: getLeaveLabels(),
    datasets: [
      {
        data: getLeaveData(),
        backgroundColor: ["#4CAF50", "#36A2EB", "#e53e3e"],
      },
    ],
  };

  //////////////LATELOGIN CHART DATA //////////////////
  const lateLoginData = useSelector((state) => state.AppReducer.lateLogin);
  const [selectedLateLabels, setSelectedLateLabels] = useState([]);

  const latestatusCounts = {
    Approved: lateLoginData.filter((late) => late.late_status === "Approved")
      .length,
    Pending: lateLoginData.filter((late) => late.late_status === "Pending")
      .length,
    Rejected: lateLoginData.filter((late) => late.late_status === "Rejected")
      .length,
  };
  const getLateData = () => {
    if (
      selectedLateLabels.length === 0 ||
      selectedLateLabels.includes("Late Letter")
    ) {
      return Object.values(latestatusCounts);
    }
    return [];
  };

  const getLateLabels = () => {
    const labels = [];
    if (
      selectedLateLabels.length === 0 ||
      selectedLateLabels.includes("Late Letter")
    ) {
      labels.push(...Object.keys(latestatusCounts));
    }
    return labels;
  };

  // Prepare the chart data
  const chartLateLoginData = {
    labels: getLateLabels(),
    datasets: [
      {
        data: getLateData(),
        backgroundColor: ["#4CAF50", "#36A2EB", "#e53e3e"],
      },
    ],
  };

  //////////////EARLYLOGOUT CHART DATA //////////////////
  const earlyLogoutData = useSelector((state) => state.AppReducer.earlyLogout);
  const [selectedEarlyLogoutLabels, setSelectedEarlyLogoutLeavesLabels] =
    useState([]);

  const earlylogoutstatusCounts = {
    Approved: earlyLogoutData.filter(
      (earlylogout) => earlylogout.earlylogout_status === "Approved"
    ).length,
    Pending: earlyLogoutData.filter(
      (earlylogout) => earlylogout.earlylogout_status === "Pending"
    ).length,
    Rejected: earlyLogoutData.filter(
      (earlylogout) => earlylogout.earlylogout_status === "Rejected"
    ).length,
  };
  const getEarlyLogoutData = () => {
    if (
      selectedEarlyLogoutLabels.length === 0 ||
      selectedEarlyLogoutLabels.includes("Early Logout Letter")
    ) {
      return Object.values(earlylogoutstatusCounts);
    }
    return [];
  };

  const getEarlyLogoutLabels = () => {
    const labels = [];
    if (
      selectedEarlyLogoutLabels.length === 0 ||
      selectedEarlyLogoutLabels.includes("Early Logout Letter")
    ) {
      labels.push(...Object.keys(earlylogoutstatusCounts));
    }
    return labels;
  };

  // Prepare the chart data
  const chartEarlyLogoutData = {
    labels: getEarlyLogoutLabels(),
    datasets: [
      {
        data: getEarlyLogoutData(),
        backgroundColor: ["#4CAF50", "#36A2EB", "#e53e3e"],
      },
    ],
  };

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllEvents());
  }, [dispatch]);

  const [hoveredIdEmployee, setHoveredIdEmployee] = useState(null);

  const handleHoverEmployee = (id) => {
    setHoveredIdEmployee(id);
  };

  const handleMouseLeaveEmployee = () => {
    setHoveredIdEmployee(null);
  };

  return (
    <Box
      pl={{ base: "2", md: "10" }}
      pt={{ base: "10", md: "10" }}
      w={{ base: "100%", md: "80%" }}
    >
      <Navbar />
      <br />
      <br />
      <Flex gap={5}>
        <Heading size="sm">Admin</Heading>
      </Flex>
      <Flex
        flexWrap="wrap"
        gap={4}
        m={6}
        justifyContent={{ base: "center", md: "flex-start" }}
      >
        <Card
          bgGradient="linear(to-r, green.400, green.300, green.200)"
          w={250}
        >
          <CardBody>
            <Stack
              spacing="3"
              textAlign="center"
              align="center"
              justify="center"
            >
              <Heading size="sm">Total Employees</Heading>
              <Button size="sm" color={"cyan.600"} w={20}>
                {totaluser - 1}
              </Button>
            </Stack>
          </CardBody>
        </Card>
        <Card
          bgGradient="linear(to-r, green.400, green.300, green.200)"
          w={250}
        >
          <CardBody>
            <Stack
              spacing="3"
              textAlign="center"
              align="center"
              justify="center"
            >
              <Heading size="sm">Pending Onboard Requests</Heading>
              <Button size="sm" color={"cyan.600"} w={20}>
                0
              </Button>
            </Stack>
          </CardBody>
        </Card>
      </Flex>
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
      >
        <Box
          w={{ base: "100%", md: "70%" }}
          p={5}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <Heading
            textAlign={"start"}
            size={"sm"}
            p={2}
            textDecoration={"underline"}
          >
            Task Assigned
          </Heading>
          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"md"}>
                  <Th style={{ textAlign: "center" }} boxShadow={"md"}>
                    Assigned To
                  </Th>
                  <Th style={{ textAlign: "center" }} boxShadow={"md"}>
                    Assign On
                  </Th>
                  <Th style={{ textAlign: "center" }} boxShadow={"md"}>
                    Title
                  </Th>
                  <Th style={{ textAlign: "center" }} boxShadow={"md"}>
                    Start Date
                  </Th>
                  <Th style={{ textAlign: "center" }} boxShadow={"md"}>
                    End Date
                  </Th>
                  <Th style={{ textAlign: "center" }} boxShadow={"md"}>
                    Status
                  </Th>
                  <Th style={{ textAlign: "center" }} boxShadow={"md"}>
                    Priority
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {paginationData.length > 0 &&
                  paginationData?.map((el) => {
                    return (
                      <Tr key={el._id} boxShadow={"md"}>
                        <Link to="/admintask">
                          {/* <Td style={{ textAlign: "center" }}>
                            <Wrap>
                              <WrapItem>
                                <Flex justifyContent={"space-around"}>
                                <TooltipChakra
                                    label={`Assigned To Employees: ${el.employee.join(
                                      ", "
                                    )}`}
                                    isOpen={hoveredIdEmployee === el._id}
                                    placement="bottom"
                                    color="green.500"
                                  >
                                  <Button size={"xs"} fontWeight={"bold"}>
                                    {el.employee[0]}
                                  </Button>
                                  {el.employee.length > 1 ? (
                                    <Button
                                      size={"xs"}
                                      rounded={"full"}
                                      fontWeight={"bold"}
                                      bg="gray.300"
                                      color="red"
                                      fontSize={10}
                                    >
                                      +{el.employee.length - 1}
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                  </TooltipChakra>
                                </Flex>
                              </WrapItem>
                            </Wrap>
                          </Td> */}
                          <Td style={{ textAlign: "center" }}>
                            <Wrap>
                              <WrapItem>
                                <Flex justifyContent={"space-around"}>
                                  <TooltipChakra
                                    label={`Assigned To Employees: ${el.employee.join(
                                      ", "
                                    )}`}
                                    isOpen={hoveredIdEmployee === el._id}
                                    placement="bottom"
                                    color="green.500"
                                  >
                                    <Flex>
                                      {" "}
                                      {/* Wrap the buttons in a container */}
                                      <Button
                                        size={"xs"}
                                        fontWeight={"bold"}
                                        onMouseEnter={() =>
                                          handleHoverEmployee(el._id)
                                        }
                                        onMouseLeave={handleMouseLeaveEmployee}
                                      >
                                        {el.employee[0]}
                                      </Button>
                                      {el.employee.length > 1 ? (
                                        <Button
                                          size={"xs"}
                                          rounded={"full"}
                                          fontWeight={"bold"}
                                          bg="gray.300"
                                          color="red"
                                          fontSize={10}
                                          onMouseEnter={() =>
                                            handleHoverEmployee(el._id)
                                          }
                                          onMouseLeave={
                                            handleMouseLeaveEmployee
                                          }
                                        >
                                          +{el.employee.length - 1}
                                        </Button>
                                      ) : (
                                        ""
                                      )}
                                    </Flex>
                                  </TooltipChakra>
                                </Flex>
                              </WrapItem>
                            </Wrap>
                          </Td>
                        </Link>

                        <Td style={{ textAlign: "center" }}>
                          <Button size={"xs"} fontWeight={"bold"}>
                            {new Date(el.createdAt).toDateString()}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button size={"xs"} fontWeight={"bold"}>
                            {el.title}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button size={"xs"} fontWeight={"bold"}>
                            {new Date(el.start_date).toDateString()}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button size={"xs"} fontWeight={"bold"}>
                            {new Date(el.end_date).toDateString()}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button
                            color={
                              el.task_status === "Pending" ? "blue" : "green"
                            }
                            size={"xs"}
                            fontWeight={"bold"}
                          >
                            {el.task_status}
                          </Button>
                        </Td>
                        <Td>
                          <Button
                            style={{
                              color:
                                el.priority === "High"
                                  ? "red"
                                  : el.priority === "Low"
                                  ? "orange"
                                  : "#41729F",
                            }}
                            size={"xs"}
                            fontWeight={"bold"}
                          >
                            {el.priority}
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
            <Box>
              {paginationData.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found
                </Alert>
              )}
            </Box>
          </TableContainer>
          <Flex justifyContent={"flex-end"} mt={5}>
            <Pagination page={page} setPage={setPage} totalPage={totalPage} />
          </Flex>
        </Box>
        <Box
          w={{ base: "100%", md: "25%" }}
          p={5}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <Box>
            <Box>
              <Tabs index={tabIndex3} onChange={handleTabsChange3}>
                <Flex justifyContent={{ base: "center", md: "center" }}>
                  <TabList
                    textAlign="center"
                    size="sm"
                    spacing={5}
                    justifyContent="center"
                  >
                    <Tab fontWeight="bold" fontSize={"sm"}>
                      Task Status
                    </Tab>
                    <Tab fontWeight="bold" fontSize={"sm"}>
                      Priority
                    </Tab>
                  </TabList>
                </Flex>
                <TabPanels>
                  <TabPanel>
                    <Doughnut data={chartTaskStatusData} />
                  </TabPanel>
                  <TabPanel>
                    <Doughnut data={chartPriorityData} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </Box>
      </Flex>

      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
        mt={5}
      >
        <Box
          w={{ base: "100%", md: "70%" }}
          p={5}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <Box>
            <Tabs index={tabIndex} onChange={handleTabsChange}>
              <Box
                display={{ base: "block", md: "flex" }}
                justifyContent={{ base: "normal", md: "space-between" }}
                alignItems={{ base: "normal", md: "center" }}
              >
                <TabList
                  textAlign={{ base: "start", md: "center" }}
                  size={{ base: "sm", md: "md" }}
                  gap={{ base: 2, md: 5 }}
                  display={{ base: "block", md: "flex" }}
                >
                  <Tab fontWeight="bold" fontSize={{ base: "sm", md: "sm" }}>
                    Leave Letter
                  </Tab>
                  <Tab fontWeight="bold" fontSize={{ base: "sm", md: "sm" }}>
                    Late Login
                  </Tab>
                  <Tab fontWeight="bold" fontSize={{ base: "sm", md: "sm" }}>
                    Early Logout
                  </Tab>
                  <Tab fontWeight="bold" fontSize={{ base: "sm", md: "sm" }}>
                    Advice
                  </Tab>
                  <Tab fontWeight="bold" fontSize={{ base: "sm", md: "sm" }}>
                    Activity
                  </Tab>
                  <Tab fontWeight="bold" fontSize={{ base: "sm", md: "sm" }}>
                    Complaint
                  </Tab>
                </TabList>
                <input
                  type="range"
                  min="0"
                  max="5"
                  value={tabIndex}
                  onChange={handleSliderChange}
                  display={{ base: "block", md: "inline-block" }}
                  mt={{ base: 4, md: 0 }}
                  ml={{ md: 4 }}
                />
              </Box>
              <TabPanels>
                <TabPanel>
                  <LeaveLetter />
                </TabPanel>
                <TabPanel>
                  <Late />
                </TabPanel>
                <TabPanel>
                  <Early />
                </TabPanel>
                <TabPanel>
                  <LeaveLetter />
                </TabPanel>
                <TabPanel>
                  <Late />
                </TabPanel>
                <TabPanel>
                  <Early />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
        <Box
          w={{ base: "100%", md: "25%" }}
          p={5}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <Box>
            <Box>
              <Tabs index={tabIndex2} onChange={handleTabsChange2}>
                <Flex justifyContent={{ base: "center", md: "space-between" }}>
                  <TabList
                    textAlign={{ base: "center", md: "start" }}
                    size="sm"
                    spacing={5}
                  >
                    <Tab fontWeight="bold" fontSize={"sm"}>
                      Leave Letter
                    </Tab>
                    <Tab fontWeight="bold" fontSize={"sm"}>
                      Late Login
                    </Tab>
                    <Tab fontWeight="bold" fontSize={"sm"}>
                      Early Logout
                    </Tab>
                  </TabList>
                </Flex>
                <TabPanels>
                  <TabPanel>
                    <Doughnut data={chartLeaveData} />
                  </TabPanel>
                  <TabPanel>
                    <Doughnut data={chartLateLoginData} />
                  </TabPanel>
                  <TabPanel>
                    <Doughnut data={chartEarlyLogoutData} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default AdminHomeDash;
