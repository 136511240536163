import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import Navbar from '../../../Components/Navbar'
import AdminSidebar from '../../../Components/AdminSidebar'

const Mail = () => {
  return (
    <Flex>
    <AdminSidebar />
    <Box pl={{ base: "10", md: "10" }}
      pt={{ base: "10", md: "10" }}
      w={{ base: "100%", md: "80%" }}>
      <Navbar />
      <br />
      <br />
      <Box>Mail</Box>
    </Box>
  </Flex>
  )
}

export default Mail