import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  Wrap,
  WrapItem,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { ChevronRightIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { PaginationOther } from "../../../../Components/PaginationOther";
import Sidebar from "../../../../Components/Sidebar";
import Navbar from "../../../../Components/Navbar";
import { getUsers, seeAllLetters } from "../../../../Redux/app/action";

const AppliedLeaveLetter = () => {
  const InputStyle = {
    borderRadius: "md",
    borderWidth: "2px",
    borderColor: "gray.300",
    py: "2",
    px: "4",
    variant: "unstyled",

  };

  const BoxStyle = {
    position: "absolute",
    top: "-8px",
    left: "8px",
    pointerEvents: "none",
    color: "green",
    fontSize: "sm",
    bg: "white",
    px: "1",
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(seeAllLetters());
  }, [dispatch]);

  const globalState = useSelector(
    (store) => store.authentication.data.token.employee_id
  );

  const leavesData = useSelector((state) => state.AppReducer.leaves);
  let employeeLetter = [...leavesData].filter(
    (obj) => obj.employee_id === globalState
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [otherPage, setotherPage] = useState(1);
  let otherperPage = 10;
  let othertotalPage = Math.ceil(employeeLetter.length / otherperPage);
  let endother = otherPage * otherperPage;
  let startother = endother - otherperPage;

  let filteredLetters = [...employeeLetter]
    .filter(
      (el) =>
        (el.employee_id &&
          el.employee_id.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.type &&
          el.type.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.message &&
          el.message.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.currentDate &&
          el.currentDate.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.leave_status &&
          el.leave_status.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.end_date &&
          el.end_date.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.start_date &&
          el.start_date.toLowerCase().includes(searchQuery.toLowerCase()))
    )
    .reverse();
  let paginationDataLetters = filteredLetters.slice(startother, endother);

  // Handling Pagination End Here

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);
  const id = useSelector((store) => store.authentication.users.id);
  const users = useSelector((store) => store.AppReducer.users);
  const filteredUser = users?.filter((obj) => obj._id === id) || [];

  const {
    isOpen: isSidebarOpen,
    onOpen: onSidebarOpen,
    onClose: onSidebarClose,
  } = useDisclosure();
  const btnRef = React.useRef();
  const [selectedItem, setSelectedItem] = useState(null);
  const [formData, setFormData] = useState({
    employee_id: "",
    currentDate: "",
    start_date: "",
    end_date: "",
    message: "",
    leave_status: "",
  });
  const handleOpenSidebar = (el) => {
    setSelectedItem(el);
    setFormData({
      employee_id: el.employee_id,
      currentDate: el.currentDate,
      start_date: el.start_date,
      end_date: el.end_date,
      message: el.message,
      leave_status: el.leave_status,
    });
  };

  return (
    <Flex>
      <Sidebar />
      <Box
        pl={{ base: "none", md: "10" }}
        pt={{ base: "none", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Applied Leave Letter
        </Heading>
        <Flex justifyContent={"space-between"}>
          <Breadcrumb
            spacing="8px"
            mt={6}
            mb={6}
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/employee">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="#">Leave Letter Request</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex justifyContent={"space-between"} gap={3}>
            <Input
              htmlSize={20}
              width="auto"
              placeholder="Search"
              focusBorderColor="green.400"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Flex>
        </Flex>
        <Box>
          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"md"}>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Requested By
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Letter Type
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Applied On
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    From
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    To
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Status
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    View
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {paginationDataLetters.length > 0 &&
                  paginationDataLetters?.map((el) => (
                    <Tr key={el._id} boxShadow={"md"}>
                      <Td
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Wrap>
                          <WrapItem>
                            <Flex justifyContent={"space-around"} gap={2}>
                              <Avatar
                                size="xs"
                                name="Kent Dodds"
                                // src="https://cdn.pixabay.com/photo/2020/07/14/13/07/icon-5404125_1280.png"
                                src={`https://project360-backend.onrender.com/${filteredUser[0].path}`}
                              />
                              <Heading size={"xs"} mt={1}>
                                {el.employee_id}
                              </Heading>
                            </Flex>
                          </WrapItem>
                        </Wrap>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button size={"xs"}>{el.type}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button size={"xs"} readOnly>
                          {new Date(el.currentDate).toDateString()}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button size={"xs"}>
                          {new Date(el.start_date).toDateString()}
                        </Button>
                      </Td>

                      <Td style={{ textAlign: "center" }}>
                        <Button size={"xs"}>
                          {new Date(el.end_date).toDateString()}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button
                          color={
                            el.leave_status === "Pending"
                              ? "blue"
                              : el.leave_status === "Rejected"
                              ? "red"
                              : "green"
                          }
                          disabled
                          size={"xs"}
                        >
                          {el.leave_status}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button
                          ref={btnRef}
                          onClick={() => {
                            onSidebarOpen();
                            handleOpenSidebar(el);
                          }}
                          size={"xs"}
                        >
                          View
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            {selectedItem && (
              <Box>
                <Drawer
                  isOpen={isSidebarOpen}
                  onClose={onSidebarClose}
                  placement="right"
                  finalFocusRef={btnRef}
                  size={"sm"}
                >
                  <form>
                    <DrawerOverlay />
                    <DrawerContent>
                      <DrawerCloseButton />
                      <DrawerHeader>Letter Request</DrawerHeader>
                      <DrawerBody>
                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            type="text"
                            defaultValue={formData.employee_id}
                            readOnly
                          />
                          <Box {...BoxStyle}>Requested By</Box>
                        </Box>
                        <br />
                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            defaultValue={new Date(
                              formData.currentDate
                            ).toDateString()}
                            readOnly
                          />
                          <Box {...BoxStyle}>Applied On</Box>
                        </Box>
                        <br />
                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            defaultValue={new Date(
                              formData.start_date
                            ).toDateString()}
                            readOnly
                          />
                          <Box {...BoxStyle}>Start Date</Box>
                        </Box>
                        <br />
                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            defaultValue={new Date(
                              formData.end_date
                            ).toDateString()}
                            readOnly
                          />
                          <Box {...BoxStyle}>End Dates</Box>
                        </Box>
                        <br />
                        <Box position="relative">
                          <Textarea
                            {...InputStyle}
                            height={"200px"}
                            defaultValue={formData.message}
                            readOnly
                          />
                          <Box {...BoxStyle}>Message</Box>
                        </Box>
                      </DrawerBody>
                    </DrawerContent>
                  </form>
                </Drawer>
              </Box>
            )}
            <Box>
              {paginationDataLetters.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found
                </Alert>
              )}
            </Box>
          </TableContainer>
          <Flex justifyContent={"flex-end"} mt={5}>
            <PaginationOther
              otherPage={otherPage}
              setotherPage={setotherPage}
              othertotalPage={othertotalPage}
            />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default AppliedLeaveLetter;
