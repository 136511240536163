import { Box, Button, Flex, Input, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { deleteUser, getUsers } from "../../../Redux/app/action";

const RightForm = () => {
  const InputStyle = {
    borderBottomWidth: "2px",
    borderBottomColor: "gray.300",
    py: "1",
    mt: "3",
    px: "4",
    variant: "unstyled",
    required: true,
  };

  const BoxStyle = {
    position: "absolute",
    top: "-8px",
    left: "8px",
    pointerEvents: "none",
    color: "green",
    fontSize: "sm",
    bg: "white",
    px: "1",
  };

  const { id } = useParams();
  const dispatch = useDispatch();
  const toast = useToast();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);
  const users = useSelector((state) => state.AppReducer.users);

  const singleUser = users.filter((obj) => obj._id === id);
  // console.log("single by id", singleUser);

  const [formValues, setFormValues] = useState(singleUser);
  // console.log("sss", formValues);

  const navigate = useNavigate();

  const handleDeleteEmployee = async (_id) => {
    try {
      await dispatch(deleteUser(_id));
      navigate("/employees");
      window.location.reload();
      toast({
        position: "top",
        title: "User Deleted Successfully",
        description: "",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top",
        title: "Error Deleting User",
        description: "An error occurred while deleting the user.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <form>
        {formValues?.map((el, index) => (
          <>
            <Flex
              direction={{ base: "column", md: "row" }}
              justifyContent={{ base: "flex-start", md: "space-between" }}
              key={el._id}
            >
              <Box w={{ base: "100%", md: "45%" }} p={5} borderRadius={10}>
                <Box textAlign={"start"}>
                  <Box position="relative" mb={5}>
                    <Input
                      {...InputStyle}
                      type="text"
                      id="account_holder_name"
                      name="account_holder_name"
                      value={el.account_holder_name}
                      onChange={(e) => {
                        const updatedValues = [...formValues];
                        updatedValues[index] = e.target.value;
                        //setFormValues(updatedValues);
                      }}
                    />
                    <Box {...BoxStyle}>Account Holder Name</Box>
                  </Box>

                  <Box position="relative" mb={5}>
                    <Input
                      {...InputStyle}
                      type="text"
                      id="ifsc"
                      name="ifsc"
                      value={el.ifsc}
                      onChange={(e) => {
                        const updatedValues = [...formValues];
                        updatedValues[index] = e.target.value;
                        //setFormValues(updatedValues);
                      }}
                    />
                    <Box {...BoxStyle}>IFSC</Box>
                  </Box>

                  <Box position="relative" mb={5}>
                    <Input
                      {...InputStyle}
                      type="text"
                      id="branch_name"
                      name="branch_name"
                      value={el.branch_name}
                      onChange={(e) => {
                        const updatedValues = [...formValues];
                        updatedValues[index] = e.target.value;
                        //setFormValues(updatedValues);
                      }}
                    />
                    <Box {...BoxStyle}>Branch</Box>
                  </Box>
                  <Box position="relative" mb={5}>
                    <Input
                      {...InputStyle}
                      type="text"
                      id="aadhar"
                      name="aadhar"
                      maxLength={12}
                      value={el.aadhar}
                      onChange={(e) => {
                        const updatedValues = [...formValues];
                        updatedValues[index] = e.target.value;
                        //setFormValues(updatedValues);
                      }}
                    />
                    <Box {...BoxStyle}>Aadhar Number</Box>
                  </Box>
                </Box>
              </Box>
              <Box w={{ base: "100%", md: "45%" }} p={5} borderRadius={10}>
                <Box textAlign={"start"}>
                  <Box position="relative" mb={5}>
                    <Input
                      {...InputStyle}
                      type="text"
                      id="bank_name"
                      name="bank_name"
                      value={el.bank_name}
                      onChange={(e) => {
                        const updatedValues = [...formValues];
                        updatedValues[index] = e.target.value;
                        //setFormValues(updatedValues);
                      }}
                    />
                    <Box {...BoxStyle}>Bank Name</Box>
                  </Box>

                  <Box position="relative" mb={5}>
                    <Input
                      {...InputStyle}
                      type="text"
                      id="account_number"
                      name="account_number"
                      value={el.account_number}
                      onChange={(e) => {
                        const updatedValues = [...formValues];
                        updatedValues[index] = e.target.value;
                        //setFormValues(updatedValues);
                      }}
                    />
                    <Box {...BoxStyle}>Account Number</Box>
                  </Box>

                  <Box position="relative" mb={5}>
                    <Input
                      {...InputStyle}
                      type="text"
                      id="upi_id"
                      name="upi_id"
                      value={el.upi_id}
                      onChange={(e) => {
                        const updatedValues = [...formValues];
                        updatedValues[index] = e.target.value;
                        //setFormValues(updatedValues);
                      }}
                    />
                    <Box {...BoxStyle}>UPI ID</Box>
                  </Box>

                  <Box position="relative" mb={5}>
                    <Input
                      {...InputStyle}
                      type="text"
                      maxLength={10}
                      id="registered_phone"
                      name="registered_phone"
                      value={el.registered_phone}
                      onChange={(e) => {
                        const updatedValues = [...formValues];
                        updatedValues[index] = e.target.value;
                        //setFormValues(updatedValues);
                      }}
                    />
                    <Box {...BoxStyle}>Registered Phone</Box>
                  </Box>
                </Box>
              </Box>
            </Flex>
            <Flex justifyContent={"flex-end"} gap={3}>
              <Link to={`/editemployeedetails/${el._id}`}>
                <Button size={"sm"} colorScheme="green">
                  Edit Employee
                </Button>
              </Link>
              <Button
                size={"sm"}
                colorScheme="red"
                onClick={() => handleDeleteEmployee(el._id)}
              >
                Delete Employee
              </Button>
            </Flex>
          </>
        ))}
      </form>
    </>
  );
};

export default RightForm;
