import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar";
import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Wrap,
  WrapItem,
  useToast,
} from "@chakra-ui/react";
import { Tooltip as ChakraTooltip } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { BsFingerprint } from "react-icons/bs";
import {
  createAttendanceRecord,
  fetchAttendances,
  getUsers,
  seeAllLetters,
  seeAllTask,
  updateAttendance,
} from "../../../Redux/app/action";
import { Pagination } from "../../../Components/Pagination";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import LeaveLetter from "./LeaveLetter";
import Early from "./Early";
import Late from "./Late";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
ChartJS.register(ArcElement, Tooltip, Legend);

const HomeDash = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(seeAllLetters());
  }, [dispatch]);

  const userData = useSelector((state) => state.authentication.users);

  //Task

  useEffect(() => {
    dispatch(seeAllTask());
  }, [dispatch]);


  const taskData = useSelector((state) => state.AppReducer.taskData);
  //console.log("taskdata", taskData)
  const sudo = useSelector(
    (store) => store.authentication.data.token.sudo_name
  );
  const filteredData = taskData.filter((obj) => obj.employee.includes(sudo));
  //console.log("filteredData", filteredData)
  const globalState = useSelector(
    (store) => store.authentication.data.token.employee_id
  );
  //console.log("globalState", globalState);

  // Handling Task Pagination Here
  const [page, setPage] = useState(1);
  let perPage = 5;
  let totalPage = Math.ceil(filteredData.length / perPage);
  let end = page * perPage;
  let start = end - perPage;
  let filteredTask = filteredData.reverse();
  let paginationData = [...filteredTask].slice(start, end);
  // Handling Task Pagination End Here

  const [tabIndex, setTabIndex] = useState(0);

  const handleSliderChange = (event) => {
    setTabIndex(parseInt(event.target.value, 10));
  };

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const [tabIndex2, setTabIndex2] = useState(0);

  const handleTabsChange2 = (index) => {
    setTabIndex2(index);
  };

  // Letter Request Here

  const [tabIndex3, setTabIndex3] = useState(0);

  const handleTabsChange3 = (index) => {
    setTabIndex3(index);
  };

  ///////////// TASK STATUS CHART DATA //////////////////

  const [selectedTaskStatusLabels, setSelectedTaskStatusLabels] = useState([]);

  const statusCounts = {
    Completed: filteredData.filter((task) => task.task_status === "Completed")
      .length,
    Pending: filteredData.filter((task) => task.task_status === "Pending")
      .length,
  };
  const getTaskStatusData = () => {
    if (
      selectedTaskStatusLabels.length === 0 ||
      selectedTaskStatusLabels.includes("Task Status")
    ) {
      return Object.values(statusCounts);
    }
    return [];
  };

  const getTaskStatusLabels = () => {
    const labels = [];
    if (
      selectedTaskStatusLabels.length === 0 ||
      selectedTaskStatusLabels.includes("Task Status")
    ) {
      labels.push(...Object.keys(statusCounts));
    }
    return labels;
  };

  // Prepare the chart data
  const chartTaskStatusData = {
    labels: getTaskStatusLabels(),
    datasets: [
      {
        data: getTaskStatusData(),
        backgroundColor: ["#4CAF50", "#36A2EB"],
      },
    ],
  };

  ////////////// TASK PRIORITY CHART DATA //////////////////

  const [selectedPriorityLabels, setSelectedPriorityLabels] = useState([]);

  const priorityCounts = {
    High: filteredData.filter((task) => task.priority === "High").length,
    Low: filteredData.filter((task) => task.priority === "Low").length,
    Medium: filteredData.filter((task) => task.priority === "Medium").length,
  };
  const getPriorityData = () => {
    if (
      selectedPriorityLabels.length === 0 ||
      selectedPriorityLabels.includes("Priority")
    ) {
      return Object.values(priorityCounts);
    }
    return [];
  };

  const getPriorityLabels = () => {
    const labels = [];
    if (
      selectedPriorityLabels.length === 0 ||
      selectedPriorityLabels.includes("Priority")
    ) {
      labels.push(...Object.keys(priorityCounts));
    }
    return labels;
  };

  // Prepare the chart data
  const chartPriorityData = {
    labels: getPriorityLabels(),
    datasets: [
      {
        data: getPriorityData(),
        backgroundColor: ["#e53e3e", "#FFCE56", "#FFFFA8"],
      },
    ],
  };

  //////////////LEAVE LETTER CHART DATA ///////////////
  const leavesData = useSelector((state) => state.AppReducer.leaves);
  const employeeLeaveLetter = [...leavesData].filter(
    (obj) => obj.employee_id === globalState
  );
  //console.log("employeeLeaveLetter", leavesData);
  const [selectedLeavesLabels, setSelectedLeavesLabels] = useState([]);

  const leavestatusCounts = {
    Approved: employeeLeaveLetter.filter(
      (leave) => leave.leave_status === "Approved"
    ).length,
    Pending: employeeLeaveLetter.filter(
      (leave) => leave.leave_status === "Pending"
    ).length,
    Rejected: employeeLeaveLetter.filter(
      (leave) => leave.leave_status === "Rejected"
    ).length,
  };
  const getLeaveData = () => {
    if (
      selectedLeavesLabels.length === 0 ||
      selectedLeavesLabels.includes("Leave Letter")
    ) {
      return Object.values(leavestatusCounts);
    }
    return [];
  };

  const getLeaveLabels = () => {
    const labels = [];
    if (
      selectedLeavesLabels.length === 0 ||
      selectedLeavesLabels.includes("Leave Letter")
    ) {
      labels.push(...Object.keys(leavestatusCounts));
    }
    return labels;
  };

  // Prepare the chart data
  const chartLeaveData = {
    labels: getLeaveLabels(),
    datasets: [
      {
        data: getLeaveData(),
        backgroundColor: ["#4CAF50", "#36A2EB", "#e53e3e"],
      },
    ],
  };

  //////////////LATELOGIN CHART DATA //////////////////
  const lateLoginData = useSelector((state) => state.AppReducer.lateLogin);
  const employeeLateLoginLetter = [...lateLoginData].filter(
    (obj) => obj.employee_id === globalState
  );
  const [selectedLateLabels, setSelectedLateLabels] = useState([]);

  const latestatusCounts = {
    Approved: employeeLateLoginLetter.filter(
      (late) => late.late_status === "Approved"
    ).length,
    Pending: employeeLateLoginLetter.filter(
      (late) => late.late_status === "Pending"
    ).length,
    Rejected: employeeLateLoginLetter.filter(
      (late) => late.late_status === "Rejected"
    ).length,
  };
  const getLateData = () => {
    if (
      selectedLateLabels.length === 0 ||
      selectedLateLabels.includes("Late Letter")
    ) {
      return Object.values(latestatusCounts);
    }
    return [];
  };

  const getLateLabels = () => {
    const labels = [];
    if (
      selectedLateLabels.length === 0 ||
      selectedLateLabels.includes("Late Letter")
    ) {
      labels.push(...Object.keys(latestatusCounts));
    }
    return labels;
  };

  // Prepare the chart data
  const chartLateLoginData = {
    labels: getLateLabels(),
    datasets: [
      {
        data: getLateData(),
        backgroundColor: ["#4CAF50", "#36A2EB", "#e53e3e"],
      },
    ],
  };

  //////////////EARLYLOGOUT CHART DATA //////////////////
  const earlyLogoutData = useSelector((state) => state.AppReducer.earlyLogout);
  const employeeEarlyLogoutLetter = [...earlyLogoutData].filter(
    (obj) => obj.employee_id === globalState
  );
  const [selectedEarlyLogoutLabels, setSelectedEarlyLogoutLeavesLabels] =
    useState([]);

  const earlylogoutstatusCounts = {
    Approved: employeeEarlyLogoutLetter.filter(
      (earlylogout) => earlylogout.earlylogout_status === "Approved"
    ).length,
    Pending: employeeEarlyLogoutLetter.filter(
      (earlylogout) => earlylogout.earlylogout_status === "Pending"
    ).length,
    Rejected: employeeEarlyLogoutLetter.filter(
      (earlylogout) => earlylogout.earlylogout_status === "Rejected"
    ).length,
  };
  const getEarlyLogoutData = () => {
    if (
      selectedEarlyLogoutLabels.length === 0 ||
      selectedEarlyLogoutLabels.includes("Early Logout Letter")
    ) {
      return Object.values(earlylogoutstatusCounts);
    }
    return [];
  };

  const getEarlyLogoutLabels = () => {
    const labels = [];
    if (
      selectedEarlyLogoutLabels.length === 0 ||
      selectedEarlyLogoutLabels.includes("Early Logout Letter")
    ) {
      labels.push(...Object.keys(earlylogoutstatusCounts));
    }
    return labels;
  };

  // Prepare the chart data
  const chartEarlyLogoutData = {
    labels: getEarlyLogoutLabels(),
    datasets: [
      {
        data: getEarlyLogoutData(),
        backgroundColor: ["#4CAF50", "#36A2EB", "#e53e3e"],
      },
    ],
  };
  //////////////////// Attendence Button ///////////////////////

  const classy = {
    borderRadius: "5px",
    padding: "10px 20px",
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "uppercase",
    transition: "background-color 0.3s ease",
  };

  useEffect(() => {
    dispatch(fetchAttendances());
  }, [dispatch]);
  const attendances = useSelector((state) => state.AppReducer.attendances);
  const globalState1 = useSelector((store) => store.authentication.users.id);
  const filteredData1 =
    attendances?.filter((obj) => obj.employee_id === globalState1) || [];
  const lastId = filteredData1.length - 1;

  let loggedInValue;
  let breakInValue;
  if (filteredData1.length > 0) {
    const lastData = filteredData1[filteredData1.length - 1];

    // Check if the lastData has the key 'loggedIn'
    if ("loggedIn" in lastData) {
      loggedInValue = lastData.loggedIn;
    } else {
      console.log('Key "loggedIn" not found in the last data.');
    }
    if ("breakIn" in lastData) {
      breakInValue = lastData.breakIn;
    } else {
      console.log('Key "loggedIn" not found in the last data.');
    }
  } else {
    console.log("No data in the filtered array.");
  }

  const [loggedIn, setLoggedIn] = useState(false);
  const [breakIn, setBreakIn] = useState(false);

  const handleLogin = async () => {
    const newAttendance = {
      employee_id: globalState1,
      date: new Date(),
      loginTime: new Date(),
      status: "Available",
      loggedIn: true,
      breakIn: false,
    };
    await fetch(`https://project360-backend.onrender.com/attendence`, {
      method: "POST",
      body: JSON.stringify(newAttendance),
      headers: {
        "Content-Type": "application/json",
      },
    });
    toast({
      position: "top",
      title: "Attendence Marked Successfully",
      description: "You have Marked Your Attendence Successfully",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleLoginTest = async () => {
    const newAttendance = {
      employee_id: globalState1,
      date: new Date(),
      loginTime: new Date(),
      status: "Available",
      loggedIn: true,
      breakIn: false,
    };

    try {
      const response = await fetch(
        `https://project360-backend.onrender.com/attendence`,
        {
          method: "POST",
          body: JSON.stringify(newAttendance),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // API call was successful
        toast({
          position: "top",
          title: "Attendance Marked Successfully",
          description: "You have Marked Your Attendance Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        // API call failed, show error toast
        // const errorData = await response.json();
        toast({
          position: "top",
          title: "Error",
          description: "An error occurred while processing your request",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      // An error occurred during the fetch
      toast({
        position: "top",
        title: "Error",
        description: "An error occurred while processing your request",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleLogout = async (attendanceId) => {
    try {
      if (breakIn) {
        const response = await fetch(
          `https://project360-backend.onrender.com/attendence/${attendanceId}`
        );
        const data = await response.json();

        if (data.breakIn) {
          toast({
            position: "top",
            title: "Break Out Changed To Break In",
            description: "You have completed your working hours",
            status: "warning",
            duration: 3000,
            isClosable: true,
          });
          await fetch(
            `https://project360-backend.onrender.com/attendence/${attendanceId}`,
            {
              method: "POST",
              body: JSON.stringify({ breakIn: true }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          await fetch(
            `https://project360-backend.onrender.com/attendence/${attendanceId}`,
            {
              method: "POST",
              body: JSON.stringify({ loggedIn: false }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          setBreakIn(!breakIn);
          setLoggedIn(false);
        }
      } else {
        toast({
          position: "top",
          title: "Successfully Logged Out",
          description: "You have completed your working hours",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      const updatedAttendance = {
        logoutTime: new Date(),
        status: "Unavailable",
        loggedIn: false,
        breakIn: false,
      };
      dispatch(updateAttendance(attendanceId, updatedAttendance));
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      localStorage.setItem("loggedIn", false);
      localStorage.setItem("breakIn", false);
    } catch (error) {
      //console.error(error);
    }
  };

  const handleLogoutTest = async (attendanceId) => {
    try {
      if (breakInValue) {
        toast({
          position: "top",
          title: "Please Break Out First",
          description: "You need to end your break before logging out.",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      } else {
        // Call the backend API to update logout
        await fetch(
          `https://project360-backend.onrender.com/attendence/${attendanceId}`,
          {
            method: "PATCH",
            body: JSON.stringify({
              logoutTime: new Date(),
              status: "Unavailable",
              loggedIn: false,
              breakIn: false,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        toast({
          position: "top",
          title: "Successfully Logged Out",
          description: "You have completed your working hours",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        setBreakIn(false);
        setLoggedIn(false);
      }
    } catch (error) {
      toast({
        position: "top",
        title: "Error",
        description: "An error occurred while processing your request",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const [breakIns, setBreakIns] = useState([]);
  const [breakOuts, setBreakOuts] = useState([]);

  const handleBreakIn = (attendanceId) => {
    setBreakIn(true);
    const newBreakIn = new Date();
    setBreakIns([...breakIns, { breakInTime: newBreakIn }]);
    const newBreakInData = { breakInTime: newBreakIn };
    //console.log("newBreakInData", newBreakInData);
    axios
      .post(
        `https://project360-backend.onrender.com/updateBreakIns/${attendanceId}`,
        {
          newBreakInData: { breakInTime: newBreakIn },
        }
      )
      .then((response) => {
        //console.log("Updated breakIns from backend:", response.data);
        toast({
          position: "top",
          title: "You are On Break",
          description: "You are On Break",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          position: "top",
          title: "Error",
          description: "An error occurred while processing your request",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });

    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleBreakInAlert = () => {
    toast({
      position: "top",
      title: "You Need To Login First",
      description: "",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };
  const handleBreakOut = (attendanceId) => {
    setBreakIn(false);
    const newBreakOut = new Date();
    setBreakOuts([...breakOuts, { breakOutTime: newBreakOut }]);

    axios
      .post(
        `https://project360-backend.onrender.com/updateBreakOuts/${attendanceId}`,
        {
          newBreakOutData: { breakOutTime: newBreakOut },
        }
      )
      .then((response) => {
        toast({
          position: "top",
          title: "Your Break Is Over",
          description: "Your Break Is Over",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          position: "top",
          title: "Error",
          description: "An error occurred while processing your request",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });

    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  ////////////////// Attendence Button End /////////////

  const [hoveredIdTitle, setHoveredIdTitle] = useState(null);

  const handleHoverTitle = (id) => {
    setHoveredIdTitle(id);
  };

  const handleMouseLeaveTitle = () => {
    setHoveredIdTitle(null);
  };

  return (
    <Box
      pl={{ base: "2", md: "10" }}
      pt={{ base: "10", md: "10" }}
      w={{ base: "100%", md: "80%" }}
    >
      <Navbar />
      <br />
      <br />
      <Flex
        gap={5}
        flexWrap="wrap"
        m={{ base: "2", md: "6" }}
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
      >
        <Box>
          <Heading size="sm">
            {userData.first_name} {userData.last_name} - {userData.employee_id}{" "}
          </Heading>
        </Box>

        <Flex gap={2}>
          {loggedInValue ? (
            <>
              <Button
                colorScheme="red"
                onClick={() => handleLogoutTest(filteredData1[lastId]._id)}
                disabled={breakInValue}
                style={classy}
                variant="link"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <BsFingerprint style={{ fontSize: "25px" }} />
                  <span style={{ marginTop: "10px", fontSize: "10px" }}>
                    Logout
                  </span>
                </div>
              </Button>
              {breakInValue ? (
                <Button
                  colorScheme="yellow"
                  onClick={() => handleBreakOut(filteredData1[lastId]._id)}
                  style={classy}
                  variant="link"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <BsFingerprint style={{ fontSize: "25px" }} />
                    <span style={{ marginTop: "10px", fontSize: "10px" }}>
                      Break Out
                    </span>
                  </div>
                </Button>
              ) : (
                <Button
                  colorScheme="green"
                  onClick={() => handleBreakIn(filteredData1[lastId]._id)}
                  style={classy}
                  variant="link"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <BsFingerprint style={{ fontSize: "25px" }} />
                    <span style={{ marginTop: "10px", fontSize: "10px" }}>
                      Break In
                    </span>
                  </div>
                </Button>
              )}
            </>
          ) : (
            <>
              <Button
                colorScheme="blue"
                onClick={handleLoginTest}
                style={classy}
                variant="link"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <BsFingerprint style={{ fontSize: "25px" }} />
                  <span style={{ marginTop: "10px", fontSize: "10px" }}>
                    Log In
                  </span>
                </div>
              </Button>
              <Button
                colorScheme="green"
                onClick={() => handleBreakInAlert()}
                style={classy}
                variant="link"
                disabled
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <BsFingerprint style={{ fontSize: "25px", opacity: 0.5 }} />
                  <span
                    style={{
                      marginTop: "10px",
                      fontSize: "10px",
                      opacity: 0.5,
                    }}
                  >
                    Break In
                  </span>
                </div>
              </Button>
            </>
          )}
        </Flex>
      </Flex>
      <Flex
        flexWrap="wrap"
        gap={3}
        mb={4}
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "flex-start" }}
      >
        <Card
          w={250}
          bgGradient="linear(to-r, green.400, green.300, green.200)"
        >
          <CardBody>
            <Stack
              spacing="3"
              textAlign="center"
              align="center"
              justify="center"
            >
              <Heading size="sm">Points</Heading>
              <Button size="sm" color={"cyan.600"} w={20}>
                0
              </Button>
            </Stack>
          </CardBody>
        </Card>
        <Card
          bgGradient="linear(to-r, green.400, green.300, green.200)"
          w={250}
        >
          <CardBody>
            <Stack
              spacing="3"
              textAlign="center"
              align="center"
              justify="center"
            >
              <Heading size="sm">No Of Leaves</Heading>
              <Button size="sm" color={"cyan.600"} w={20}>
                0{leavestatusCounts.Approved}
              </Button>
            </Stack>
          </CardBody>
        </Card>
        <Card
          bgGradient="linear(to-r, green.400, green.300, green.200)"
          w={250}
        >
          <CardBody>
            <Stack
              spacing="3"
              textAlign="center"
              align="center"
              justify="center"
            >
              <Heading size="sm">Pending CL</Heading>
              <Button size="sm" color={"cyan.600"} w={20}>
                01
              </Button>
            </Stack>
          </CardBody>
        </Card>
      </Flex>
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
      >
        <Box
          w={{ base: "100%", md: "70%" }}
          p={{ base: "5", md: "5" }}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <Heading
            textAlign={"start"}
            size={"sm"}
            p={2}
            textDecoration={"underline"}
          >
            Task Assigned
          </Heading>
          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"md"}>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Created By
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Assign On
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Title
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Start Date
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    End Date
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Status
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Priority
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {paginationData.length > 0 &&
                  paginationData?.map((el) => (
                    <Tr key={el._id} boxShadow={"md"}>
                      <Link to="/task">
                        <Td style={{ textAlign: "center" }}>
                          <Wrap>
                            <WrapItem>
                              <Flex justifyContent={"space-around"} gap={2}>
                                <Avatar
                                  size="xs"
                                  name="Admin"
                                  src="https://i.ibb.co/FWy9bGx/portrait-white-man-isolated.jpg"
                                />
                                <Heading size={"xs"}>
                                  <Button size={"xs"} fontWeight={"bold"}>
                                    Admin
                                  </Button>
                                </Heading>
                              </Flex>
                            </WrapItem>
                          </Wrap>
                        </Td>
                      </Link>

                      <Td style={{ textAlign: "center" }}>
                        <Button size={"xs"} fontWeight={"bold"}>
                          {new Date(el.createdAt).toDateString()}
                        </Button>
                      </Td>
                     
                      <Td
                        style={{
                          textAlign: "center",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <ChakraTooltip
                          label={el.title}
                          isOpen={hoveredIdTitle === el._id}
                          placement="bottom"
                        >
                          <Button
                            size={"xs"}
                            onMouseEnter={() => handleHoverTitle(el._id)}
                            onMouseLeave={handleMouseLeaveTitle}
                            // width={hoveredIdTitle === el._id ? 'auto' : '100px'}
                          >
                            {el.title.slice(0, 15)}...
                          </Button>
                        </ChakraTooltip>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button size={"xs"} fontWeight={"bold"}>
                          {new Date(el.start_date).toDateString()}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button size={"xs"} fontWeight={"bold"}>
                          {new Date(el.end_date).toDateString()}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button
                          color={
                            el.task_status === "Pending" ? "blue" : "green"
                          }
                          size={"xs"}
                          fontWeight={"bold"}
                        >
                          {el.task_status}
                        </Button>
                      </Td>
                      <Td>
                        <Button
                          style={{
                            color:
                              el.priority === "High"
                                ? "red"
                                : el.priority === "Low"
                                ? "orange"
                                : "#41729F",
                          }}
                          size={"xs"}
                          fontWeight={"bold"}
                        >
                          {el.priority}
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            <Box>
              {paginationData.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found
                </Alert>
              )}
            </Box>
          </TableContainer>
          <Flex justifyContent={"flex-end"} mt={5}>
            <Pagination page={page} setPage={setPage} totalPage={totalPage} />
          </Flex>
        </Box>
        <Box
          w={{ base: "100%", md: "25%" }}
          p={{ base: "5", md: "5" }}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
        
          <Box>
            <Box>
              <Tabs index={tabIndex3} onChange={handleTabsChange3}>
                <Flex justifyContent={{ base: "center", md: "center" }}>
                  <TabList
                    textAlign="center"
                    size="sm"
                    spacing={5}
                    justifyContent="center"
                  >
                    <Tab fontWeight="bold" fontSize={"sm"}>
                      Task Status
                    </Tab>
                    <Tab fontWeight="bold" fontSize={"sm"}>
                      Priority
                    </Tab>
                  </TabList>
                </Flex>
                <TabPanels>
                  <TabPanel>
                    <Doughnut data={chartTaskStatusData} />
                  </TabPanel>
                  <TabPanel>
                    <Doughnut data={chartPriorityData} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </Box>
      </Flex>

      <Flex
        direction={{ base: "column-reverse", md: "row" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
        mt={5}
      >
        <Box
          w={{ base: "100%", md: "70%" }}
          p={5}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <Box>
            <Tabs index={tabIndex} onChange={handleTabsChange}>
              <Flex justifyContent={"space-between"}>
                <TabList textAlign={"start"} size={"sm"} gap={5}>
                  <Tab fontWeight={"bold"} fontSize={"sm"}>
                    Leave Letter
                  </Tab>
                  <Tab fontWeight={"bold"} fontSize={"sm"}>
                    Late Login
                  </Tab>
                  <Tab fontWeight={"bold"} fontSize={"sm"}>
                    Early Logout
                  </Tab>
                  <Tab fontWeight={"bold"} fontSize={"sm"}>
                    Advice
                  </Tab>
                  <Tab fontWeight={"bold"} fontSize={"sm"}>
                    Activity
                  </Tab>
                  <Tab fontWeight={"bold"} fontSize={"sm"}>
                    Complaint
                  </Tab>
                </TabList>
                <input
                  type="range"
                  min="0"
                  max="5"
                  value={tabIndex}
                  onChange={handleSliderChange}
                />
              </Flex>
              <TabPanels>
                <TabPanel>
                  <LeaveLetter />
                </TabPanel>
                <TabPanel>
                  <Late />
                </TabPanel>
                <TabPanel>
                  <Early />
                </TabPanel>
                <TabPanel>
                  <LeaveLetter />
                </TabPanel>
                <TabPanel>
                  <Late />
                </TabPanel>
                <TabPanel>
                  <Early />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
        <Box
          w={{ base: "100%", md: "25%" }}
          p={5}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <Box>
            <Box>
              <Tabs index={tabIndex2} onChange={handleTabsChange2}>
                <Flex justifyContent={{ base: "center", md: "space-between" }}>
                  <TabList
                    textAlign={{ base: "center", md: "start" }}
                    size="sm"
                    spacing={5}
                  >
                    <Tab fontWeight="bold" fontSize={"sm"}>
                      Leave Letter
                    </Tab>
                    <Tab fontWeight="bold" fontSize={"sm"}>
                      Late Login
                    </Tab>
                    <Tab fontWeight="bold" fontSize={"sm"}>
                      Early Logout
                    </Tab>
                  </TabList>
                </Flex>
                <TabPanels>
                  <TabPanel>
                    <Doughnut data={chartLeaveData} />
                  </TabPanel>
                  <TabPanel>
                    <Doughnut data={chartLateLoginData} />
                  </TabPanel>
                  <TabPanel>
                    <Doughnut data={chartEarlyLogoutData} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default HomeDash;
