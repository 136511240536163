import {
  Alert,
    AlertIcon,
    Avatar,
    Box,
    Button,
    Flex,
    Heading,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Wrap,
    WrapItem,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { Link } from "react-router-dom";
  import { PaginationOther } from "../../../Components/PaginationOther";
  import {  getUsers, seeearlylogoutLetters } from "../../../Redux/app/action";
  
  const Early = () => {
    const dispatch = useDispatch();
    const globalState = useSelector(
      (store) => store.authentication.data.token.employee_id
    );

    useEffect(() => {
      dispatch(getUsers());
    }, [dispatch]);
    const id = useSelector((store) => store.authentication.users.id);
    const users = useSelector((store) => store.AppReducer.users);
    const filteredUser = users?.filter((obj) => obj._id === id) || [];

    useEffect(() => {
      dispatch(seeearlylogoutLetters());
    }, [dispatch]);
    const earlyLogoutData = useSelector((state) => state.AppReducer.earlyLogout);
    //console.log("earlyLogoutData", earlyLogoutData);
    const employeeLeaveLetter = earlyLogoutData.filter(
      (obj) => obj.employee_id === globalState
    );
    const [otherPage, setotherPage] = useState(1);
    let otherperPage = 5;
    let othertotalPage = Math.ceil(employeeLeaveLetter.length / otherperPage);
    let endother = otherPage * otherperPage;
    let startother = endother - otherperPage;
  
    let filteredLetters = [...employeeLeaveLetter].reverse();
   
    let paginationDataLetters = filteredLetters.slice(startother, endother);
    function convertToAMPM(timeString) {
      const [hours, minutes] = timeString.split(":");
      let formattedTime = "";
  
      // Convert hours to AM/PM format
      const numericHours = parseInt(hours);
      const ampmHours = numericHours % 12 || 12;
      formattedTime += ampmHours.toString();
  
      // Append minutes
      formattedTime += `:${minutes}`;
  
      // Determine AM/PM
      const ampm = numericHours < 12 ? "AM" : "PM";
      formattedTime += ` ${ampm}`;
  
      return formattedTime;
    }
    return (
      <>
        <TableContainer>
          <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
            <Thead>
              <Tr boxShadow={"md"}>
                <Th boxShadow={"md"} style={{ textAlign: "center" }}>Requested By</Th>
                <Th boxShadow={"md"} style={{ textAlign: "center" }}>Letter Type</Th>
                <Th boxShadow={"md"} style={{ textAlign: "center" }}>Applied On</Th>
                <Th boxShadow={"md"} style={{ textAlign: "center" }}>From</Th>
                <Th boxShadow={"md"} style={{ textAlign: "center" }}>Logout At</Th>
                <Th boxShadow={"md"} style={{ textAlign: "center" }}>Status</Th>
              </Tr>
            </Thead>
           
              <Tbody >
              {paginationDataLetters.length > 0 && paginationDataLetters?.map((el) => (
                <Tr key={el._id} boxShadow={"md"}>
                  <Link to="/appliedearlylogout">
                    <Td style={{ textAlign: "center" }}>
                      <Wrap>
                        <WrapItem>
                          <Flex justifyContent={"space-around"} gap={2}>
                            <Avatar
                              size="xs"
                              name="Kent Dodds"
                              src={`https://project360-backend.onrender.com/${filteredUser[0].path}`}
                            />
                            <Heading size={"xs"}>
                              <Button size={"xs"} fontWeight={"bold"}>
                                {el.employee_id}
                                </Button>
                              </Heading>
                          </Flex>
                        </WrapItem>
                      </Wrap>
                    </Td>
                  </Link>
                  <Td style={{ textAlign: "center" }}><Button  size={"xs"} fontWeight={"bold"}>{el.type}</Button></Td>
                  <Td style={{ textAlign: "center" }}><Button  size={"xs"} fontWeight={"bold"}>{new Date(el.currentDate).toDateString()}</Button></Td>
                  <Td style={{ textAlign: "center" }}><Button  size={"xs"} fontWeight={"bold"}>{new Date(el.start_date).toDateString()}</Button></Td>
  
                  <Td style={{ textAlign: "center" }}><Button  size={"xs"} fontWeight={"bold"}>{convertToAMPM(el.at)}</Button></Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button
                      color={
                        el.earlylogout_status === "Pending"
                          ? "blue"
                          : el.earlylogout_status === "Rejected"
                          ? "red"
                          : "green"
                      }
                      disabled
                      size={"xs"} fontWeight={"bold"}
                    >
                      {el.earlylogout_status}
                    </Button>
                  </Td>
                </Tr>
                 ))}
              </Tbody>
           
          </Table>
          <Box>
              {paginationDataLetters.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found 
                </Alert>
              )}
            </Box>
        </TableContainer>
        <Flex justifyContent={"flex-end"} mt={5}>
          <PaginationOther
            otherPage={otherPage}
            setotherPage={setotherPage}
            othertotalPage={othertotalPage}
          />
        </Flex>
      </>
    );
  };
  
  export default Early;
  