import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineLock, AiOutlineMail } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { IoLogoGoogleplus, IoLogoTwitter } from "react-icons/io";
import leftsideimage from "../Logins/assets/leftsideimage.PNG";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../../Context/AuthContext";
import { UserloginTest } from "../../Redux/auth/action";
import { getUsers } from "../../Redux/app/action";

const UserLogin = () => {
  const token = useSelector((store) => store.authentication);
  const globalState = useSelector((store) => store.authentication.data.token);
  console.log("globalState", globalState);
  console.log("token", token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);
  const [usercred, setUerCed] = useState({});
  const toast = useToast();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUerCed({
      ...usercred,
      [name]: value,
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(UserloginTest(usercred));
  };

  useEffect(() => {
    if (token.data.token.token) {
       
      if (token.data.token.role.toLowerCase() === "employee") {
        navigate("/employee");
        toast({
          position: "top",
          title: `Welcome Back ${globalState.sudo_name}`,
          description: "Sucessfully Logged In",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          position: "top",
          title: "Warning",
          description: "Check the correct routes of credentials",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
      }
    } else if (token.data.isAuth) {
      navigate("/");
      toast({
        position: "top",
        title: "Invalid Credentials",
        description: "Enter the correct credentials",
        status: "error",
        duration: 500,
        isClosable: true,
      });
    }
  }, [
    token,
    globalState.role,
    globalState.sudo_name,
    globalState.token,
    navigate,
    toast,
  ]);

  //   const handleLoginSubmit = async (e) => {
  //     e.preventDefault();

  //     try {
  //       const response = await axios.post(
  //         "https://project360-backend.onrender.com/login",
  //         usercred
  //       );
  //       console.log("response: ", response);
  //       if (response.data.token) {
  //         if (response.data.document.role.toLowerCase() === "employee") {
  //           navigate("/employee");
  //           toast({
  //             position: "top",
  //             title: `Welcome Back ${response.data.document.sudo_name}`,
  //             description: "Successfully Logged In",
  //             status: "success",
  //             duration: 3000,
  //             isClosable: true,
  //           });
  //         } else {
  //           toast({
  //             position: "top",
  //             title: "Invalid Credentials",
  //             description: "Enter the correct credentials",
  //             status: "error",
  //             duration: 3000,
  //             isClosable: true,
  //           });
  //         }
  //       } else if (response.data.token === null) {
  //         toast({
  //           position: "top",
  //           title: "Invalid Credentials",
  //           description: "Enter the correct credentials",
  //           status: "error",
  //           duration: 3000,
  //           isClosable: true,
  //         });
  //       }
  //     } catch (error) {
  //       // Handle error, you can log it or show a specific error message
  //       console.error("Login failed:", error);
  //       toast({
  //         position: "top",
  //         title: "Login Failed",
  //         description: "An error occurred while logging in. Please try again.",
  //         status: "error",
  //         duration: 3000,
  //         isClosable: true,
  //       });
  //     }
  //   };

  const setAccount = useContext(AuthContext);

  const loginSuccess = (res) => {
    //console.log(res)
    const decoded = jwt_decode(res.credential);
    //console.log(decoded);
    setAccount(decoded);
  };

  const loginFailure = (res) => {
    //console.log("Login failed", res);
  };

  return (
    <>
      <Flex flexWrap="wrap" justifyContent="center">
        <Box p={{ base: "none", md: 10 }} bg={"white"}>
          <Image
            p={{ base: "none", md: 10 }}
            width={{ base: "100%", sm: "45%", md: "80%" }}
            src={leftsideimage}
          />
        </Box>

        <Box p={20} bg={"white"} width={{ base: "100%", sm: "45%", md: "40%" }}>
          <form onSubmit={handleLogin}>
            <FormControl>
              <Text fontSize="3xl" fontWeight={"bold"} textAlign={"start"}>
                SIGN IN TO EMPLOYEE E&D 360
              </Text>

              <br />
              <br />

              <Flex flexWrap="wrap" justifyContent="space-between">
                <Button w={"25%"}>
                  <GoogleLogin
                    buttonText={<IoLogoGoogleplus />}
                    onSuccess={loginSuccess}
                    onError={loginFailure}
                  />
                </Button>
                <Button w={"25%"}>
                  <FaFacebookF style={{ color: "#1877f2" }} />
                </Button>
                <Button w={"25%"}>
                  <IoLogoTwitter style={{ color: "#00aaec" }} />
                </Button>
              </Flex>
              <br />
              <br />
              <Flex alignItems="center">
                <Box
                  flex="1"
                  borderBottom="1px solid"
                  borderColor="gray.400"
                  mr="4"
                />
                <Text
                  fontWeight="medium"
                  textTransform="uppercase"
                  textColor={"gray"}
                >
                  OR
                </Text>
                <Box
                  flex="1"
                  borderBottom="1px solid"
                  borderColor="gray.400"
                  ml="4"
                />
              </Flex>
              <br />
              <FormLabel>Employee Id </FormLabel>
              <InputGroup>
                <Input
                  type="text"
                  id="employee_id"
                  name="employee_id"
                  placeholder="Enter Employee Id"
                  onChange={handleChange}
                  required
                />
                <InputRightElement
                  style={{ background: "#00ab55" }}
                  pointerEvents="none"
                  children={<AiOutlineMail color="white" />}
                />
              </InputGroup>
              <br />
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Employee Password"
                  onChange={handleChange}
                  required
                />
                <InputRightElement
                  style={{ background: "#00ab55" }}
                  pointerEvents="none"
                  children={<AiOutlineLock color="white" />}
                />
              </InputGroup>
              <br />

              <Button
                type="submit"
                w={"full"}
                textColor={"white"}
                background={"#00ab55"}
                _hover={{ textColor: "white", background: "#00ab55" }}
              >
                Login Now
              </Button>
            </FormControl>
          </form>
        </Box>
      </Flex>
    </>
  );
};

export default UserLogin;
