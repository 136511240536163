import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Table,
  TableContainer,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  Wrap,
  WrapItem,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { ChevronRightIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  getUsers,
  leaveapproval,
  seeAllLetters,
} from "../../../Redux/app/action";
import HRSidebar from "../../../Components/HRSidebar";
import Navbar from "../../../Components/Navbar";
import { PaginationOther } from "../../../Components/PaginationOther";

const HRAllLeaveRequest = () => {
  const InputStyle = {
    borderRadius: "md",
    borderWidth: "2px",
    borderColor: "gray.300",
    py: "2",
    px: "4",
    variant: "unstyled",
  };

  const BoxStyle = {
    position: "absolute",
    top: "-8px",
    left: "8px",
    pointerEvents: "none",
    color: "green",
    fontSize: "sm",
    bg: "white",
    px: "1",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(seeAllLetters());
  }, [dispatch]);

  const leavesData = useSelector((state) => state.AppReducer.leaves);
  //console.log("letter", leavesData);
  const [searchQuery, setSearchQuery] = useState("");
  const [otherPage, setotherPage] = useState(1);
  let otherperPage = 10;
  let othertotalPage = Math.ceil(leavesData.length / otherperPage);
  let endother = otherPage * otherperPage;
  let startother = endother - otherperPage;
  let filteredLetters = [...leavesData]
    .filter(
      (el) =>
        (el.employee_id &&
          el.employee_id.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.type &&
          el.type.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.message &&
          el.message.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.currentDate &&
          el.currentDate.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.leave_status &&
          el.leave_status.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.end_date &&
          el.end_date.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (el.start_date &&
          el.start_date.toLowerCase().includes(searchQuery.toLowerCase()))
    )
    .reverse();
  let paginationDataLetters = filteredLetters.slice(startother, endother);

  //console.log("reverseletter", filteredLetters);
  // Handling Pagination End Here

  const toast = useToast();

  const handleLetterStatusChange = (_id) => {
    const payload = {
      leave_status: "Approved",
    };

    // dispatch(leaveapproval(letterId, payload));
    axios
      .patch(
        `https://project360-backend.onrender.com/leaveletter/${_id}`,
        payload
      )
      .then((res) => {
        toast({
          position: "top",
          title: "Leave Approved",
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        window.location.reload();
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const handleLetterStatusRejected = (_id) => {
    const payload = {
      leave_status: "Rejected",
    };

    // dispatch(leaveapproval(letterId, payload));
    axios
      .patch(
        `https://project360-backend.onrender.com/leaveletter/${_id}`,
        payload
      )
      .then((res) => {
        toast({
          position: "top",
          title: "Leave Rejected",
          description: "",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        window.location.reload();
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const {
    isOpen: isSidebarOpen,
    onOpen: onSidebarOpen,
    onClose: onSidebarClose,
  } = useDisclosure();
  const btnRef = React.useRef();
  const [selectedItem, setSelectedItem] = useState(null);
  const [formData, setFormData] = useState({
    employee_id: "",
    currentDate: "",
    start_date: "",
    end_date: "",
    message: "",
    leave_status: "",
  });
  //console.log("ffleave", formData);
  const handleOpenSidebar = (el) => {
    setSelectedItem(el);
    setFormData({
      employee_id: el.employee_id,
      currentDate: el.currentDate,
      start_date: el.start_date,
      end_date: el.end_date,
      message: el.message,
      leave_status: el.leave_status,
    });
  };

  const handleApprove = async (_id) => {
    const payload = {
      leave_status: "Approved",
    };
    try {
      await dispatch(leaveapproval(selectedItem._id, payload));
      setSelectedItem(null);

      navigate("/hr/leaverequest");
      window.location.reload();
      toast({
        position: "top",
        title: "Leave Approved",
        description: "",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top",
        title: "Error Approving Leave",
        description: "An error occurred while Approving Leave.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleReject = async (_id) => {
    const payload = {
      leave_status: "Rejected",
    };
    try {
      await dispatch(leaveapproval(selectedItem._id, payload));
      setSelectedItem(null);

      navigate("/hr/leaverequest");
      window.location.reload();
      toast({
        position: "top",
        title: "Leave Rejected",
        description: "",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top",
        title: "Error Rejecting Leave",
        description: "An error occurred while Rejecting Leave.",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const users = useSelector((state) => state.AppReducer.users);

  const userPathMap = {};
  users.forEach((user) => {
    userPathMap[user.employee_id] = user.path;
  });

  // Now you can use the map to get the path for each task's employee_id
  paginationDataLetters.forEach((task) => {
    if (userPathMap.hasOwnProperty(task.employee_id)) {
      const path = userPathMap[task.employee_id];
      //console.log(`Employee ID: ${task.employee_id}, Path: ${path}`);
    } else {
      //console.log(`Employee ID: ${task.employee_id}, Path not found.`);
    }
  });

  return (
    <Flex>
      <HRSidebar />
      <Box
        pl={{ base: "none", md: "10" }}
        pt={{ base: "none", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          All Leave Letters Request
        </Heading>
        <Flex justifyContent={"space-between"}>
          <Breadcrumb
            spacing="8px"
            mt={6}
            mb={6}
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/hr">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="#">Leave Letter Request</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex justifyContent={"space-between"} gap={3}>
            <Input
              htmlSize={20}
              width="auto"
              placeholder="Search"
              focusBorderColor="green.400"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Flex>
        </Flex>
        <Box>
          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"md"}>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Requested By
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Letter Type
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Applied On
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    From
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    To
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Status
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    View & Action
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {paginationDataLetters.length > 0 &&
                  paginationDataLetters?.map((el) => {
                    const path = userPathMap[el.employee_id];

                    return (
                      <Tr key={el._id} boxShadow={"md"}>
                        <Td
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Wrap>
                            <WrapItem>
                              <Flex justifyContent={"space-around"} gap={2}>
                              <Avatar
                                size="xs"
                                name={el.employee_id}
                                src={
                                  path
                                    ? `https://project360-backend.onrender.com/${path}`
                                    : `https://project360-backend.onrender.com/${path}`
                                }
                              />
                                <Heading size={"xs"} mt={1}>
                                  {el.employee_id}
                                </Heading>
                              </Flex>
                            </WrapItem>
                          </Wrap>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button size={"xs"}>{el.type}</Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button size={"xs"}>
                            {new Date(el.currentDate).toDateString()}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button size={"xs"}>
                            {new Date(el.start_date).toDateString()}
                          </Button>
                        </Td>

                        <Td style={{ textAlign: "center" }}>
                          <Button size={"xs"}>
                            {new Date(el.end_date).toDateString()}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          {el.leave_status !== "Approved" ? (
                            <Popover>
                              <PopoverTrigger>
                                <Button
                                  disabled={el.leave_status === "Approved"}
                                  color={
                                    el.leave_status === "Rejected"
                                      ? "red"
                                      : el.leave_status === "Pending"
                                      ? "blue"
                                      : "green"
                                  }
                                  size={"xs"}
                                >
                                  {el.leave_status}
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverHeader>Confirmation!</PopoverHeader>
                                <PopoverBody>
                                  Are you sure you want to approve?
                                </PopoverBody>
                                <PopoverFooter
                                  display="flex"
                                  justifyContent="flex-end"
                                  gap={2}
                                >
                                  <ButtonGroup size="xs">
                                    <Button
                                      color="red"
                                      _hover={{
                                        color: "white",
                                        bgColor: "red",
                                      }}
                                      onClick={() =>
                                        handleLetterStatusRejected(el._id)
                                      }
                                    >
                                      No
                                    </Button>
                                  </ButtonGroup>
                                  <ButtonGroup size="xs">
                                    <Button
                                      color="green"
                                      _hover={{
                                        color: "white",
                                        bgColor: "green",
                                      }}
                                      onClick={() =>
                                        handleLetterStatusChange(el._id)
                                      }
                                    >
                                      Yes
                                    </Button>
                                  </ButtonGroup>
                                </PopoverFooter>
                              </PopoverContent>
                            </Popover>
                          ) : (
                            <Button
                              color={
                                el.leave_status === "Rejected"
                                  ? "red"
                                  : el.leave_status === "Pending"
                                  ? "blue"
                                  : "green"
                              }
                              disabled
                              size={"xs"}
                            >
                              {el.leave_status}
                            </Button>
                          )}
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button
                            ref={btnRef}
                            onClick={() => {
                              onSidebarOpen();
                              handleOpenSidebar(el);
                            }}
                            size={"xs"}
                          >
                            View
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
            {selectedItem && (
              <Box>
                <Drawer
                  isOpen={isSidebarOpen}
                  onClose={onSidebarClose}
                  placement="right"
                  finalFocusRef={btnRef}
                  size={"sm"}
                >
                  <form>
                    <DrawerOverlay />
                    <DrawerContent>
                      <DrawerCloseButton />
                      <DrawerHeader>Letter Request</DrawerHeader>
                      <DrawerBody>
                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            type="text"
                            defaultValue={formData.employee_id}
                          />
                          <Box {...BoxStyle}>Requested By</Box>
                        </Box>
                        <br />
                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            defaultValue={new Date(
                              formData.currentDate
                            ).toDateString()}
                          />
                          <Box {...BoxStyle}>Applied On</Box>
                        </Box>
                        <br />
                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            defaultValue={new Date(
                              formData.start_date
                            ).toDateString()}
                          />
                          <Box {...BoxStyle}>Start Date</Box>
                        </Box>
                        <br />
                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            defaultValue={new Date(
                              formData.end_date
                            ).toDateString()}
                          />
                          <Box {...BoxStyle}>End Dates</Box>
                        </Box>
                        <br />
                        <Box position="relative">
                          <Textarea
                            {...InputStyle}
                            height={"200px"}
                            defaultValue={formData.message}
                          />
                          <Box {...BoxStyle}>Message</Box>
                        </Box>
                      </DrawerBody>
                      <DrawerFooter>
                        <Flex justifyContent={"flex-end"} gap={2}>
                          <Button
                            color="red"
                            _hover={{ color: "white", bgColor: "red" }}
                            onClick={handleReject}
                            size={"xs"}
                          >
                            Reject
                          </Button>
                          <Button
                            color="green"
                            _hover={{ color: "white", bgColor: "green" }}
                            onClick={handleApprove}
                            size={"xs"}
                          >
                            Approve
                          </Button>
                        </Flex>
                      </DrawerFooter>
                    </DrawerContent>
                  </form>
                </Drawer>
              </Box>
            )}
            <Box>
              {paginationDataLetters.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found
                </Alert>
              )}
            </Box>
          </TableContainer>
          <Flex justifyContent={"flex-end"} mt={5}>
            <PaginationOther
              otherPage={otherPage}
              setotherPage={setotherPage}
              othertotalPage={othertotalPage}
            />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default HRAllLeaveRequest;
