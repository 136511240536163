import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Icon,
  Input,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllDocuments, getUsers } from "../../../Redux/app/action";
import {
  FaRegFile,
  FaRegFileAudio,
  FaRegFileExcel,
  FaRegFilePdf,
  FaRegFilePowerpoint,
  FaRegFileVideo,
  FaRegFileWord,
} from "react-icons/fa";
import { BsImage } from "react-icons/bs";
const DocumentsUpload = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getAllDocuments());
  }, [dispatch]);
  const toast = useToast();
  const users = useSelector((store) => store.AppReducer.users);
  const documents = useSelector((store) => store.AppReducer.documents);
  const globalState = useSelector((store) => store.authentication.users.id);
  //console.log("empid", globalState);

  const filteredUser = users?.filter((obj) => obj._id === globalState) || [];
  //console.log("_id", filteredUser[0]._id)

  const filteredDocuments =
    documents?.filter((obj) => obj.employee_id === filteredUser[0]._id) || [];
  //console.log("filteredDocuments", filteredDocuments);

  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  //console.log("files",files)
  //console.log("select",selectedFiles.files)

  const handleFileChange = (event) => {
    const fileList = event.target.files;
    const newSelectedFiles = Array.from(fileList);
    setSelectedFiles(newSelectedFiles);
    setFiles(newSelectedFiles);
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const fileList = event.dataTransfer.files;
    const newSelectedFiles = Array.from(fileList);
    setSelectedFiles(newSelectedFiles);
    setFiles(newSelectedFiles);
  };

  const handleFileUpload = () => {
    const formData = new FormData();

    for (const file of files) {
      formData.append("files", file);
    }
    formData.append("employee_id", filteredUser[0]._id);

    axios
      .post("https://project360-backend.onrender.com/document", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        toast({
          position: "top",
          title: "File Uploaded Successfully",
          description: "File Uploaded Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setTimeout(()=>{
            window.location.reload()
        },3000)
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });

    setSelectedFiles([]);
    setFiles([]);
  };

  return (
    <Flex
      direction={{ base: "column", md: "column" }}
      justifyContent={{ base: "flex-start", md: "space-between" }}
      gap={5}
    >
      <Box
        w={{ base: "100%", md: "100%" }}
        p={10}
        borderRadius={10}
        boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
      >
        <Box>
          <Heading as="h4" size="md" mt={10}>
            Upload Your Documents
          </Heading>
          <Flex direction="column" align="center" mt={10}>
            <Box
              p={20}
              borderWidth={2}
              borderRadius="md"
              borderStyle="dashed"
              onDrop={handleFileDrop}
              onDragOver={(event) => event.preventDefault()}
            >
              {selectedFiles.length > 0 ? (
                <ul>
                  {selectedFiles.map((file, index) => (
                    <li key={index}>
                      <Text textAlign={"start"}>{file.name}</Text>
                    </li>
                  ))}
                </ul>
              ) : (
                <Text>Click to select files.....</Text>
              )}
            </Box>

            <Input
              type="file"
              id="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
              multiple
            />
            <label htmlFor="file">
              <Button mt={4} as="span" size="sm" colorScheme="messenger">
                Select Files
              </Button>
            </label>

            {selectedFiles.length > 0 && (
              <Flex direction="row" mt={4} gap={2}>
                <Button
                  onClick={handleFileUpload}
                  mt={2}
                  as="a"
                  size="sm"
                  colorScheme="twitter"
                >
                  Upload
                </Button>
                {selectedFiles.map((file, index) => (
                  <Button
                    key={index}
                    mt={2}
                    as="a"
                    href={URL.createObjectURL(file)}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="sm"
                    colorScheme="cyan"
                  >
                    View {index + 1}
                  </Button>
                ))}
              </Flex>
            )}
          </Flex>
        </Box>
      </Box>
      <Box
        w={{ base: "100%", md: "100%" }}
        p={10}
        borderRadius={10}
        boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
      >
        {filteredDocuments && filteredDocuments.length > 0 ? (
          <SimpleGrid columns={{ base: 1, md: 5 }} spacing={4}>
            {filteredDocuments.map((document, documentIndex) => (
              <React.Fragment key={documentIndex}>
                {document.files.map((file, fileIndex) => (
                  <Card key={fileIndex}>
                    <CardBody>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Icon
                          as={
                            file.name.endsWith(".pdf")
                              ? FaRegFilePdf
                              : file.name.endsWith(".doc") ||
                                file.name.endsWith(".docx")
                              ? FaRegFileWord
                              : file.name.endsWith(".xls") ||
                                file.name.endsWith(".xlsx")
                              ? FaRegFileExcel
                              : file.name.endsWith(".ppt") ||
                                file.name.endsWith(".pptx")
                              ? FaRegFilePowerpoint
                              : file.name.endsWith(".jpg") ||
                                file.name.endsWith(".jpeg") ||
                                file.name.endsWith(".png")
                              ? BsImage
                              : file.name.endsWith(".mp3") ||
                                file.name.endsWith(".wav")
                              ? FaRegFileAudio
                              : file.name.endsWith(".mp4") ||
                                file.name.endsWith(".avi") ||
                                file.name.endsWith(".webm") ||
                                file.name.endsWith(".mov")
                              ? FaRegFileVideo
                              : FaRegFile
                          }
                          boxSize={10}
                          color={"blue.500"}
                        />

                        <Text
                          mt={2}
                          fontWeight="semibold"
                          maxWidth="80%"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                          fontSize={{ base: "xx-small", sm: "xs" }} // Responsive font size
                        >
                          {file.name.substring(0, 10)}
                          <Text as="span" color="gray.500" fontSize="xs">
                            .
                            {file.name.substring(
                              file.name.lastIndexOf(".") + 1
                            )}
                          </Text>
                        </Text>
                        <Button
                          mt={2}
                          as="a"
                          href={`https://project360-backend.onrender.com/${file.path}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          size={"xs"}
                          colorScheme="cyan"
                        >
                          Download
                        </Button>
                      </Box>
                    </CardBody>
                  </Card>
                ))}
              </React.Fragment>
            ))}
          </SimpleGrid>
        ) : (
          <Text>No files available.</Text>
        )}
      </Box>
    </Flex>
  );
};

export default DocumentsUpload;
