import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  Text,
  Textarea,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Sidebar from "../../../../Components/Sidebar";
import Navbar from "../../../../Components/Navbar";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@chakra-ui/icons";
import { BiBold, BiItalic, BiUnderline } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { leaveletter } from "../../../../Redux/app/action";
import { useNavigate } from "react-router-dom";

const Leave = () => {
  const users = useSelector((store) => store.authentication.users);
  //console.log("users late", users);

  const [fontSize, setFontSize] = useState(16);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);

  const handleIncreaseFontSize = () => {
    setFontSize(fontSize + 2);
  };

  const handleDecreaseFontSize = () => {
    setFontSize(fontSize - 2);
  };

  const handleToggleBold = () => {
    setIsBold(!isBold);
  };

  const handleToggleItalic = () => {
    setIsItalic(!isItalic);
  };

  const handleToggleUnderline = () => {
    setIsUnderline(!isUnderline);
  };

  const [employee_id, setEmployee_id] = useState(users.employee_id);
  const [to, setTo] = useState([]);
  const [currentDate, setcurrentDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState("");

  const predefinedText = `I, ${users.first_name} ${users.last_name} working in ${users.department} as ${users.designation} wants to seek leave for some work \n
Regards\n${users.first_name} ${users.last_name} `;

  const [message, setMessage] = useState(predefinedText);

  const options = [
    { value: "hr", label: "HR" },
    { value: "admin", label: "Manager" },
  ];

  const handleSelectChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setTo(selectedValues);
  };

  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const handleLeave = (e) => {
    e.preventDefault();
    dispatch(
      leaveletter({
        employee_id: employee_id,
        to: to,
        currentDate: currentDate,
        start_date: start_date,
        end_date: end_date,
        message: message,
      })
    );
    toast({
      position: "top",
      title: "Leave Request Applied Successfully",
      description: "",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setTo([]);
    setstart_date("");
    setend_date("");
    setcurrentDate(currentDate);
    navigate("/appliedleaveletter");
  };

  return (
    <Flex>
      <Sidebar />
      <Box
        pl={{ base: "none", md: "10" }}
        pt={{ base: "10", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Leave Letter
        </Heading>
        <Breadcrumb
          spacing="8px"
          mt={6}
          mb={6}
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/employee">Dashboard</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href="/generateletters">
              Generate Letter
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/appliedleaveletter">Leave Letter</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent={{ base: "flex-start", md: "space-between" }}
        >
          <Box
            w={{ base: "100%", md: "70%" }}
            p={10}
            borderRadius={10}
            boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
          >
            <form onSubmit={handleLeave}>
              <Box position="relative">
                <Input
                  borderRadius="md"
                  borderWidth="2px"
                  borderColor="gray.300"
                  fontWeight={"semibold"}
                  py={2}
                  px={4}
                  variant="unstyled"
                  id="employee_id"
                  name="employee_id"
                  defaultValue={employee_id}
                />
                <Box
                  position="absolute"
                  top="-8px"
                  left="8px"
                  pointerEvents="none"
                  color="gray.400"
                  fontSize="sm"
                  bg="white"
                  px={1}
                >
                  From
                </Box>
              </Box>
              <br />
              <div style={{ position: "relative" }}>
                <Select
                  onChange={handleSelectChange}
                  value={options.filter((option) => to.includes(option.value))}
                  options={options}
                  isMulti
                  placeholder=""
                  required
                  name="to"
                  id="to"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "md",
                      borderWidth: "2px",
                      borderColor: "gray.300",
                      padding: "2px 4px",
                      fontWeight: "semibold",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 2,
                      textAlign: "start",
                    }),
                  }}
                />

                <div
                  style={{
                    position: "absolute",
                    top: "-8px",
                    left: "8px",
                    pointerEvents: "none",
                    color: "gray",
                    fontSize: "xs",
                    backgroundColor: "white",
                    padding: "0 2px",
                  }}
                >
                  To
                </div>
              </div>
              <br />
              <Box position="relative">
                <Input
                  borderRadius="md"
                  borderWidth="2px"
                  borderColor="gray.300"
                  fontWeight={"semibold"}
                  py={2}
                  px={4}
                  variant="unstyled"
                  name="currentDate"
                  defaultValue={currentDate}
                />
                <Box
                  position="absolute"
                  top="-8px"
                  left="8px"
                  pointerEvents="none"
                  color="gray.400"
                  fontSize="sm"
                  bg="white"
                  px={1}
                >
                  Date
                </Box>
              </Box>
              <br />
              <Flex>
                <Box position="relative">
                  <Input
                    borderRadius="md"
                    borderWidth="2px"
                    borderColor="gray.300"
                    fontWeight={"semibold"}
                    py={2}
                    px={4}
                    variant="unstyled"
                    type="date"
                    name="start_date"
                    value={start_date}
                    onChange={(e) => setstart_date(e.target.value)}
                  />
                  <Box
                    position="absolute"
                    top="-8px"
                    left="8px"
                    pointerEvents="none"
                    color="gray.400"
                    fontSize="sm"
                    bg="white"
                    px={1}
                  >
                    Start Date
                  </Box>
                </Box>
                <Box position="relative">
                  <Input
                    borderRadius="md"
                    borderWidth="2px"
                    borderColor="gray.300"
                    fontWeight={"semibold"}
                    py={2}
                    px={4}
                    variant="unstyled"
                    type="date"
                    name="end_date"
                    value={end_date}
                    onChange={(e) => setend_date(e.target.value)}
                  />
                  <Box
                    position="absolute"
                    top="-8px"
                    left="8px"
                    pointerEvents="none"
                    color="gray.400"
                    fontSize="sm"
                    bg="white"
                    px={1}
                  >
                    End Date
                  </Box>
                </Box>
              </Flex>
              <Box>
                <Box
                  mt={2}
                  display="flex"
                  justifyContent="flex-end"
                  borderRadius={5}
                  borderBottom={"none"}
                  gap={2}
                  color={"gray.400"}
                  pt={2}
                  pb={2}
                  pr={2}
                >
                  <Text fontWeight={"semibold"} color="gray.400">
                    Font Size :
                  </Text>
                  <IconButton
                    aria-label="Decrease Font Size"
                    icon={
                      <ChevronDownIcon
                        style={{ color: "black", fontSize: "20" }}
                      />
                    }
                    onClick={handleDecreaseFontSize}
                    size="sm"
                  />
                  <IconButton
                    aria-label="Increase Font Size"
                    icon={
                      <ChevronUpIcon
                        style={{ color: "black", fontSize: "20" }}
                      />
                    }
                    onClick={handleIncreaseFontSize}
                    size="sm"
                  />
                  <IconButton
                    aria-label="Bold"
                    icon={<BiBold style={{ color: "black", fontSize: "20" }} />}
                    onClick={handleToggleBold}
                    size="sm"
                    colorScheme={isBold ? "teal" : undefined}
                  />
                  <IconButton
                    aria-label="Italic"
                    icon={
                      <BiItalic style={{ color: "black", fontSize: "20" }} />
                    }
                    onClick={handleToggleItalic}
                    size="sm"
                    colorScheme={isItalic ? "teal" : undefined}
                  />
                  <IconButton
                    aria-label="Underline"
                    icon={
                      <BiUnderline style={{ color: "black", fontSize: "20" }} />
                    }
                    onClick={handleToggleUnderline}
                    size="sm"
                    colorScheme={isUnderline ? "teal" : undefined}
                  />
                </Box>
                <Textarea
                  fontSize={fontSize}
                  fontWeight={isBold ? "bold" : undefined}
                  fontStyle={isItalic ? "italic" : undefined}
                  textDecoration={isUnderline ? "underline" : undefined}
                  resize="vertical"
                  minHeight="200px"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  focusBorderColor="gray.400"
                />
              </Box>
              <Box display="flex" justifyContent="flex-end" mt={5}>
                <Button colorScheme="green" size={"sm"} type="submit">
                  Send For Approval
                </Button>
              </Box>
            </form>
          </Box>

          <Box
            w={{ base: "100%", md: "25%" }}
            mt={{ base: "0", md: "none" }}
            borderRadius={10}
          >
            <Box
              p={5}
              borderRadius={10}
              boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
            >
              <Heading
                size={"md"}
                textAlign={"start"}
                textDecoration={"underline"}
                color={"green"}
              >
                Employee Details
              </Heading>
              <UnorderedList textAlign={"start"} mt={5}>
                <Flex gap={2}>
                  <Heading as="h5" size="sm" color="green">
                    Name :-
                  </Heading>
                  {users.first_name} {users.last_name}
                </Flex>
                <Flex gap={2}>
                  <Heading as="h5" size="sm" color="green">
                    Email :-{" "}
                  </Heading>
                  {users.email}
                </Flex>
                <Flex gap={2}>
                  <Heading as="h5" size="sm" color="green">
                    Phone :-{" "}
                  </Heading>
                  +91-{users.phone}
                </Flex>
                <Flex gap={2}>
                  <Heading as="h5" size="sm" color="green">
                    Department :-{" "}
                  </Heading>
                  {users.department}
                </Flex>
              </UnorderedList>
            </Box>
            <br />
            <Box
              p={5}
              borderRadius={10}
              boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
            >
              <Heading
                size={"md"}
                textAlign={"start"}
                textDecoration={"underline"}
                color={"green"}
              >
                Company Details
              </Heading>
              <UnorderedList textAlign={"start"} mt={5}>
                <Flex gap={2}>
                  <Heading as="h5" size="sm" color="green">
                    Company Name :-{" "}
                  </Heading>
                  Explore & Do
                </Flex>
                <Flex gap={2}>
                  <Heading as="h5" size="sm" color="green">
                    Email :-{" "}
                  </Heading>
                  exploreanddo@gmail.com
                </Flex>
                <Flex gap={2}>
                  <Heading as="h5" size="sm" color="green">
                    Coordinator :-{" "}
                  </Heading>
                  Alex Furguson
                </Flex>
                <Flex gap={2}>
                  <Heading as="h5" size="sm" color="green">
                    Phone :-{" "}
                  </Heading>
                  +91-9989067806
                </Flex>
              </UnorderedList>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Leave;
