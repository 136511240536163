export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const POST_USERS_REQUEST = "POST_USERS_REQUEST";
export const POST_USERS_SUCCESS = "POST_USERS_SUCCESS";
export const POST_USERS_FAILURE = "POST_USERS_FAILURE";

export const EDIT_USERS_REQUEST = "EDIT_USERS_REQUEST";
export const EDIT_USERS_SUCCESS = "EDIT_USERS_SUCCESS";
export const EDIT_USERS_FAILURE = "EDIT_USERS_FAILURE";

export const DELETE_USERS_REQUEST = "DELETE_USERS_REQUEST";
export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS";
export const DELETE_USERS_FAILURE = "DELETE_USERS_FAILURE";

export const POST_TASK_REQUEST = "POST_TASK_REQUEST";
export const POST_TASK_SUCCESS = "POST_TASK_SUCCESS";
export const POST_TASK_FAILURE = "POST_TASK_FAILURE";

export const GET_TASK_REQUEST = "GET_TASK_REQUEST";
export const GET_TASK_SUCCESS = "GET_TASK_SUCCESS";
export const GET_TASK_FAILURE = "GET_TASK_FAILURE";

export const EDIT_TASK_REQUEST = "EDIT_TASK_REQUEST";
export const EDIT_TASK_SUCCESS = "EDIT_TASK_SUCCESS";
export const EDIT_TASK_FAILURE = "EDIT_TASK_FAILURE";

export const DELETE_TASK_REQUEST = "DELETE_TASK_REQUEST";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_FAILURE = "DELETE_TASK_FAILURE";

export const FETCH_ATTENDANCES_REQUEST = "FETCH_ATTENDANCES_REQUEST";
export const FETCH_ATTENDANCES_SUCCESS = "FETCH_ATTENDANCES_SUCCESS";
export const FETCH_ATTENDANCES_FAILURE = "FETCH_ATTENDANCES_FAILURE";

export const POST_ATTENDANCES_REQUEST = "POST_ATTENDANCES_REQUEST";
export const POST_ATTENDANCES_SUCCESS = "POST_ATTENDANCES_SUCCESS";
export const POST_ATTENDANCES_FAILURE = "POST_ATTENDANCES_FAILURE";

export const UPDATE_ATTENDANCE_SUCCESS = "UPDATE_ATTENDANCE_SUCCESS";
export const UPDATE_ATTENDANCE_FAILURE = "UPDATE_ATTENDANCE_FAILURE";

export const POST_LEAVE_REQUEST = "POST_LEAVE_REQUEST";
export const POST_LEAVE_SUCCESS = "POST_LEAVE_SUCCESS";
export const POST_LEAVE_FAILURE = "POST_LEAVE_FAILURE";

export const GET_LEAVE_REQUEST = "GET_LEAVE_REQUEST";
export const GET_LEAVE_SUCCESS = "GET_LEAVE_SUCCESS";
export const GET_LEAVE_FAILURE = "GET_LEAVE_FAILURE";

export const EDIT_LEAVE_REQUEST = "EDIT_LEAVE_REQUEST";
export const EDIT_LEAVE_SUCCESS = "EDIT_LEAVE_SUCCESS";
export const EDIT_LEAVE_FAILURE = "EDIT_LEAVE_FAILURE";

export const POST_LATE_REQUEST = "POST_LATE_REQUEST";
export const POST_LATE_SUCCESS = "POST_LATE_SUCCESS";
export const POST_LATE_FAILURE = "POST_LATE_FAILURE";

export const GET_LATE_REQUEST = "GET_LATE_REQUEST";
export const GET_LATE_SUCCESS = "GET_LATE_SUCCESS";
export const GET_LATE_FAILURE = "GET_LATE_FAILURE";

export const EDIT_LATE_REQUEST = "EDIT_LATE_REQUEST";
export const EDIT_LATE_SUCCESS = "EDIT_LATE_SUCCESS";
export const EDIT_LATE_FAILURE = "EDIT_LATE_FAILURE";

export const POST_EARLY_LOGOUT_REQUEST = "POST_EARLY_LOGOUT_REQUEST";
export const POST_EARLY_LOGOUT_SUCCESS = "POST_EARLY_LOGOUT_SUCCESS";
export const POST_EARLY_LOGOUT_FAILURE = "POST_EARLY_LOGOUT_FAILURE";

export const GET_EARLY_LOGOUT_REQUEST = "GET_EARLY_LOGOUT_REQUEST";
export const GET_EARLY_LOGOUT_SUCCESS = "GET_EARLY_LOGOUT_SUCCESS";
export const GET_EARLY_LOGOUT_FAILURE = "GET_EARLY_LOGOUT_FAILURE";

export const EDIT_EARLY_LOGOUT_REQUEST = "EDIT_EARLY_LOGOUT_REQUEST";
export const EDIT_EARLY_LOGOUT_SUCCESS = "EDIT_EARLY_LOGOUT_SUCCESS";
export const EDIT_EARLY_LOGOUT_FAILURE = "EDIT_EARLY_LOGOUT_FAILURE";

export const POST_PROBATION_LETTER_REQUEST = "POST_PROBATION_LETTER_REQUEST";
export const POST_PROBATION_LETTER_SUCCESS = "POST_PROBATION_LETTER_SUCCESS";
export const POST_PROBATION_LETTER_FAILURE = "POST_PROBATION_LETTER_FAILURE";

export const GET_PROBATION_LETTER_REQUEST = "GET_PROBATION_LETTER_REQUEST";
export const GET_PROBATION_LETTER_SUCCESS = "GET_PROBATION_LETTER_SUCCESS";
export const GET_PROBATION_LETTER_FAILURE = "GET_PROBATION_LETTER_FAILURE";

export const POST_OFFER_LETTER_REQUEST = "POST_OFFER_LETTER_REQUEST";
export const POST_OFFER_LETTER_SUCCESS = "POST_OFFER_LETTER_SUCCESS";
export const POST_OFFER_LETTER_FAILURE = "POST_OFFER_LETTER_FAILURE";

export const GET_OFFER_LETTER_REQUEST = "GET_OFFER_LETTER_REQUEST";
export const GET_OFFER_LETTER_SUCCESS = "GET_OFFER_LETTER_SUCCESS";
export const GET_OFFER_LETTER_FAILURE = "GET_OFFER_LETTER_FAILURE";

export const ADD_MESSAGE = "ADD_MESSAGE";

export const POST_EVENT_REQUEST = "POST_EVENT_REQUEST";
export const POST_EVENT_SUCCESS = "POST_EVENT_SUCCESS";
export const POST_EVENT_FAILURE = "POST_EVENT_FAILURE";

export const GET_EVENT_REQUEST = "GET_EVENT_REQUEST";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_FAILURE = "GET_EVENT_FAILURE";

export const POST_DOCUMENT_REQUEST = "POST_DOCUMENT_REQUEST";
export const POST_DOCUMENT_SUCCESS = "POST_DOCUMENT_SUCCESS";
export const POST_DOCUMENT_FAILURE = "POST_DOCUMENT_FAILURE";

export const GET_DOCUMENT_REQUEST = "GET_DOCUMENT_REQUEST";
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_FAILURE = "GET_DOCUMENT_FAILURE";
