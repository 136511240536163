import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
} from "@chakra-ui/react";
import React from "react";

import Navbar from "../../../Components/Navbar";
import { ChevronRightIcon } from "@chakra-ui/icons";

import HRSidebar from "../../../Components/HRSidebar";
import RightForm from "./RightForm";
import LeftForm from "./LeftForm";

const HRSingleEmployeeDetails = () => {
  return (
    <Flex>
      <HRSidebar />
      <Box
        pl={{ base: "none", md: "10" }}
        pt={{ base: "none", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Users
        </Heading>
        <Flex justifyContent={"space-between"}>
          <Breadcrumb
            spacing="8px"
            mt={6}
            mb={6}
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/hr">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="/hr/employees">User</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="#">User Details</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Flex>
        <Box>
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent={{ base: "flex-start", md: "space-between" }}
            gap={5}
          >
            <Box
              w={{ base: "100%", md: "50%" }}
              borderRadius={10}
              boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
              p={5}
            >
              <LeftForm />
            </Box>
            <Box
              w={{ base: "100%", md: "50%" }}
              borderRadius={10}
              boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
              p={5}
            >
              <RightForm />
            </Box>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default HRSingleEmployeeDetails;
