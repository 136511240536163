import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Tooltip,
  ModalOverlay,
  ModalFooter,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Card,
  CardBody,
  Text,
  Icon,
  Stack,
  SimpleGrid,
  Avatar,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin2Line } from "react-icons/ri";
import moment from "moment";
import {
  FaRegFile,
  FaRegFileAudio,
  FaRegFileExcel,
  FaRegFilePdf,
  FaRegFilePowerpoint,
  FaRegFileVideo,
  FaRegFileWord,
} from "react-icons/fa";
import { BsImage } from "react-icons/bs";
import {
  deleteTask,
  editTask,
  postTaskTest,
  seeAllTask,
} from "../../../Redux/app/action";
import HRSidebar from "../../../Components/HRSidebar";
import Navbar from "../../../Components/Navbar";
import { Pagination } from "../../../Components/Pagination";

const HRTask = () => {
  const InputStyle = {
    borderRadius: "md",
    borderWidth: "2px",
    borderColor: "gray.300",
    py: "2",
    px: "4",
    variant: "unstyled",
  };

  const BoxStyle = {
    position: "absolute",
    top: "-8px",
    left: "8px",
    pointerEvents: "none",
    color: "green",
    fontSize: "sm",
    bg: "white",
    px: "1",
  };

  const users = useSelector((state) => state.AppReducer.users);
  //console.log("users",users)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSidebarOpen,
    onOpen: onSidebarOpen,
    onClose: onSidebarClose,
  } = useDisclosure();

  const OverlayTwo = () => <ModalOverlay bg="whiteAlpha.100" />;

  const [overlay, setOverlay] = useState(<OverlayTwo />);

  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  const btnRef = React.useRef();
  const dispatch = useDispatch();
  const toast = useToast();
  //const [tasks, setTasks] = useState({});
  const [tasks, setTasks] = useState({
    file: null,
    files: [],
    title: "",
    description: "",
    team: "",
    employee: "",
    priority: "",
    start_date: "",
    end_date: "",
  });


  const handleAssignTask = async (event) => {
    event.preventDefault();
    try {
      const response = await dispatch(postTaskTest(tasks));
      //console.log("Task assigned successfully:", response);
      onClose();
      toast({
        position: "top",
        title: "Task Assigned Successfully",
        description: "",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      //console.error("Error assigning task:", error);
    }
  };

  useEffect(() => {
    dispatch(seeAllTask(tasks));
  }, [dispatch, tasks]);

  const taskData = useSelector((state) => state.AppReducer.taskData);
  //console.log("ttdata", taskData);

  //Task Assign & Task Data End Here

  // Handling Task Delete Here

  const handleTaskDelete = async (_id) => {
    try {
      await dispatch(deleteTask(_id));
   
      toast({
        position: "top",
        title: "Task Deleted Successfully",
        description: "",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Deleting Task",
        description: "An error occurred while deleting the user.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Handling Task Delete End Here

  // Handling Task Edit & Update Here
  const [selectedItem, setSelectedItem] = useState(null);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    priority: "",
    start_date: "",
    end_date: "",
  });


  const handleTaskUpdate = async (_id) => {
    try {
      await dispatch(editTask(selectedItem._id, formData));
      setSelectedItem(null);
      setFormData({
        title: "",
        description: "",
        priority: "",
        start_date: "",
        end_date: "",
      });

      toast({
        position: "top",
        title: "Task Updated Successfully",
        description: "",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Updating Task",
        description: "An error occurred while updating the task.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (el) => {
    setSelectedItem(el);
    setFormData({
      title: el.title,
      description: el.description,
      priority: el.priority,
      start_date: el.start_date,
      end_date: el.end_date,
    });
  };

  // Handling Task Edit & Update End Here

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  let perPage = 10;

  // Check if taskData is an array, otherwise initialize it as an empty array
  let taskDataArray = Array.isArray(taskData) ? taskData : [taskData];

  // Calculate total pages based on the filtered data
  let totalPage = Math.ceil(taskDataArray.length / perPage);
  let end = page * perPage;
  let start = end - perPage;

  // Apply filtering on the taskDataArray and handle non-array cases
  let filteredData = Array.isArray(taskDataArray)
    ? taskDataArray
        .filter(
          (el) =>
            (el.employee &&
              el.employee.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (el.title &&
              el.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (el.description &&
              el.description
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (el.task_status &&
              el.task_status
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (el.end_date &&
              el.end_date.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (el.start_date &&
              el.start_date
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (el.priority &&
              el.priority.toLowerCase().includes(searchQuery.toLowerCase()))
        )
        .reverse()
    : [taskDataArray];

  // Apply pagination to the filteredData
  let paginationData = filteredData.slice(start, end);

  // If taskData is not an array, show paginationData
  let displayData = Array.isArray(taskDataArray)
    ? paginationData
    : [paginationData];
  //console.log("displaytask", displayData)

  // Now you can use the displayData in your UI to show the filtered and paginated data

  // Handling Pagination End Here

  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "file") {
      const files = Array.from(e.target.files);
      if (files.length > 4) {
        toast({
          position: "top",
          title: "Max 4 Files",
          description: "",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      setTasks((prevTasks) => ({
        ...prevTasks,
        files: files,
      }));
    } else if (name === "team") {
      setTasks((prevTasks) => ({
        ...prevTasks,
        team: value,
        employee: "",
      }));
      setSelectedTeam(value);
      setSelectedEmployee("");
    } else if (name === "employee") {
      setTasks((prevTasks) => ({
        ...prevTasks,
        employee: value,
      }));
      setSelectedEmployee(value);
    } else {
      setTasks((prevTasks) => ({
        ...prevTasks,
        [name]: value,
      }));
    }
  };

  const filteredEmployees = users.filter((user) => {
    if (tasks.team === "Software Development") {
      return user.department === "Software Development";
    }
    if (tasks.team === "Lead Generation") {
      return user.department === "Lead Generation";
    }
    if (tasks.team === "Customer Support") {
      return user.department === "Customer Support";
    }

    return false;
  });



  const [hoveredIdTitle, setHoveredIdTitle] = useState(null);

  const handleHoverTitle = (id) => {
    setHoveredIdTitle(id);
  };

  const handleMouseLeaveTitle = () => {
    setHoveredIdTitle(null);
  };

  const [selectedData, setSelectedData] = useState(paginationData);

  const handleAttachment = (paginationData) => {
    setSelectedData(paginationData);
  };

  const userPathMap = {};
  users.forEach((user) => {
    userPathMap[user.employee_id] = user.path;
  });

  // Now you can use the map to get the path for each task's employee_id
  paginationData.forEach((task) => {
    if (userPathMap.hasOwnProperty(task.employee)) {
      //const path = userPathMap[task.employee];
      //console.log(`Employee ID: ${task.employee_id}, Path: ${path}`);
    } else {
      //console.log(`Employee ID: ${task.employee_id}, Path not found.`);
    }
  });
  return (
    <Flex>
      <HRSidebar />
      <Box
        pl={{ base: "none", md: "10" }}
        pt={{ base: "none", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Tasks
        </Heading>
        <Flex justifyContent={"space-between"}>
          <Breadcrumb
            spacing="8px"
            mt={6}
            mb={6}
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/hr">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="#">Task</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex justifyContent={"space-between"} gap={3}>
            <Input
              htmlSize={20}
              width="auto"
              placeholder="Search"
              focusBorderColor="green.400"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />

            <Box>
              <Button ref={btnRef} colorScheme="teal" onClick={onOpen}>
                Assign Task
              </Button>

              <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                finalFocusRef={btnRef}
                size={"sm"}
              >
                <DrawerOverlay />
                <form onSubmit={handleAssignTask} encType="multipart/form-data">
                  <DrawerContent>
                    <DrawerCloseButton />

                    <DrawerHeader>Assign Task</DrawerHeader>

                    <DrawerBody>
                      <Box position="relative">
                        <Input
                          {...InputStyle}
                          type="text"
                          id="title"
                          name="title"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Title</Box>
                      </Box>
                      <br />
                      <Box position="relative">
                        <Textarea
                          {...InputStyle}
                          type="text"
                          id="description"
                          name="description"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Description</Box>
                      </Box>
                      <br />
                      <Box position="relative">
                        <Input
                          {...InputStyle}
                          type="file"
                          id="file"
                          name="file"
                          onChange={handleInputChange}
                          multiple
                        />
                        <Box {...BoxStyle}>Attach Files</Box>
                        {tasks.files.length === 0 && (
                          <Box fontSize="xs" mt="2" color={"red"}>
                            Max 4 files can be selected.
                          </Box>
                        )}
                        {tasks.files &&
                          tasks.files.length > 0 &&
                          tasks.files.length <= 4 && (
                            <Box fontSize="xs" mt="2">
                              {tasks.files.length} Files selected.
                            </Box>
                          )}
                      </Box>
                      <br />
                      <Box position="relative" mb={5}>
                        <Select
                          placeholder=""
                          required
                          name="team"
                          id="team"
                          onChange={handleInputChange}
                          value={selectedTeam}
                        >
                          <option value=""></option>
                          <option value="Software Development">
                            Software Development
                          </option>
                          <option value="Lead Generation">
                            Lead Generation
                          </option>
                          <option value="Customer Support">
                            Customer Support
                          </option>
                        </Select>
                        <Box {...BoxStyle}>Select Team</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Select
                          placeholder=""
                          required
                          name="employee"
                          id="employee"
                          onChange={handleInputChange}
                          value={selectedEmployee}
                        >
                          <option value=""></option>
                          {filteredEmployees.map((user) => (
                            <option key={user._id} value={user.employee_id}>
                              {user.sudo_name}
                            </option>
                          ))}
                        </Select>
                        <Box {...BoxStyle}>Select Employee</Box>
                      </Box>

                      <Box position="relative" mb={5}>
                        <Select
                          placeholder=""
                          required
                          name="priority"
                          id="priority"
                          onChange={handleInputChange}
                          value={tasks.priority || ""}
                        >
                          <option value=""></option>
                          <option value="Low">Low</option>
                          <option value="Medium">Medium</option>
                          <option value="High">High</option>
                        </Select>
                        <Box {...BoxStyle}>Priority</Box>
                      </Box>

                      <Box position="relative">
                        <Input
                          {...InputStyle}
                          type="date"
                          id="start_date"
                          name="start_date"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>Start Date</Box>
                      </Box>
                      <br />
                      <Box position="relative">
                        <Input
                          {...InputStyle}
                          type="date"
                          id="end_date"
                          name="end_date"
                          onChange={handleInputChange}
                        />
                        <Box {...BoxStyle}>End Dates</Box>
                      </Box>
                    </DrawerBody>

                    <DrawerFooter>
                      <Button colorScheme="green" type="submit">
                        Assign
                      </Button>
                    </DrawerFooter>
                  </DrawerContent>
                </form>
              </Drawer>
            </Box>
          </Flex>
        </Flex>
        <Box>
          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"md"}>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Assigned To
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Assign On
                  </Th>

                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Title
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Description
                  </Th>

                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Start Date
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    End Date
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Status
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Priority
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Action
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {paginationData.length > 0 &&
                  paginationData?.map((el) => {
                    const path = userPathMap[el.employee];
                    return (
                      <Tr key={el._id} boxShadow={"md"}>
                        <Td style={{ textAlign: "center" }}>
                          <Wrap>
                            <WrapItem>
                              <Flex justifyContent={"space-around"} gap={2}>
                                <Avatar
                                  size="xs"
                                  name={el.employee}
                                  src={
                                    path
                                      ? `https://project360-backend.onrender.com/${path}`
                                      : `https://project360-backend.onrender.com/${path}`
                                  }
                                />
                                <Heading size={"xs"}>
                                  <Button size={"xs"}>{el.employee}</Button>
                                </Heading>
                              </Flex>
                            </WrapItem>
                          </Wrap>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button size={"xs"}>
                            {moment(el.createdAt).format("lll")}
                          </Button>
                        </Td>
                        <Td
                          style={{
                            textAlign: "center",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Tooltip
                            label={el.title}
                            isOpen={hoveredIdTitle === el._id}
                            placement="bottom"
                          >
                            <Button
                              size={"xs"}
                              onMouseEnter={() => handleHoverTitle(el._id)}
                              onMouseLeave={handleMouseLeaveTitle}
                             
                            >
                              {el.title.slice(0, 15)}
                            </Button>
                          </Tooltip>
                        </Td>

                        <Td style={{ textAlign: "center" }}>
                          <Button
                            size={"xs"}
                            onClick={() => {
                              onViewOpen();
                              handleAttachment(el);
                            }}
                          >
                            View
                          </Button>
                        </Td>

                        <Modal
                          isCentered
                          isOpen={isViewOpen && selectedData !== null}
                          onClose={onViewClose}
                        >
                          {overlay}
                          <ModalContent>
                            <ModalHeader>Task Description</ModalHeader>
                            <ModalCloseButton />

                            <ModalBody>
                              <Stack spacing={4}>
                                <Card>
                                  <CardBody>
                                    <Text>{selectedData.description}</Text>
                                  </CardBody>
                                </Card>

                                {selectedData.files &&
                                selectedData.files.length > 0 ? (
                                  <SimpleGrid
                                    columns={{ base: 1, md: 2 }}
                                    spacing={4}
                                  >
                                    {selectedData.files.map((file, index) => (
                                      <Card key={index}>
                                        <CardBody>
                                          <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                          >
                                            <Icon
                                              as={
                                                file.name.endsWith(".pdf")
                                                  ? FaRegFilePdf
                                                  : file.name.endsWith(
                                                      ".doc"
                                                    ) ||
                                                    file.name.endsWith(".docx")
                                                  ? FaRegFileWord
                                                  : file.name.endsWith(
                                                      ".xls"
                                                    ) ||
                                                    file.name.endsWith(".xlsx")
                                                  ? FaRegFileExcel
                                                  : file.name.endsWith(
                                                      ".ppt"
                                                    ) ||
                                                    file.name.endsWith(".pptx")
                                                  ? FaRegFilePowerpoint
                                                  : file.name.endsWith(
                                                      ".jpg"
                                                    ) ||
                                                    file.name.endsWith(
                                                      ".jpeg"
                                                    ) ||
                                                    file.name.endsWith(".png")
                                                  ? BsImage
                                                  : file.name.endsWith(
                                                      ".mp3"
                                                    ) ||
                                                    file.name.endsWith(".wav")
                                                  ? FaRegFileAudio
                                                  : file.name.endsWith(
                                                      ".mp4"
                                                    ) ||
                                                    file.name.endsWith(
                                                      ".avi"
                                                    ) ||
                                                    file.name.endsWith(
                                                      ".webm"
                                                    ) ||
                                                    file.name.endsWith(".mov")
                                                  ? FaRegFileVideo
                                                  : FaRegFile
                                              }
                                              boxSize={20}
                                              color={"blue.500"}
                                            />

                                            <Text
                                              mt={2}
                                              fontWeight={"semibold"}
                                              maxWidth="80%"
                                              overflow="hidden"
                                              textOverflow="ellipsis"
                                              whiteSpace="nowrap"
                                            >
                                              {file.name.substring(
                                                0,
                                                file.name.lastIndexOf(".")
                                              )}
                                            </Text>
                                            <Text color="gray.500">
                                              {file.name.substring(
                                                file.name.lastIndexOf(".") + 1
                                              )}
                                            </Text>
                                            <Button
                                              mt={2}
                                              as="a"
                                              href={`https://project360-backend.onrender.com/${file.path}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              size={"sm"}
                                              colorScheme="cyan"
                                            >
                                              Download
                                            </Button>
                                          </Box>
                                        </CardBody>
                                      </Card>
                                    ))}
                                  </SimpleGrid>
                                ) : (
                                  <Text>No files available.</Text>
                                )}
                              </Stack>
                            </ModalBody>

                            <ModalFooter></ModalFooter>
                          </ModalContent>
                        </Modal>

                        <Td style={{ textAlign: "center" }}>
                          <Button size={"xs"}>
                            {new Date(el.start_date).toDateString()}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button size={"xs"}>
                            {new Date(el.end_date).toDateString()}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button
                            color={
                              el.task_status === "Pending"
                                ? "blue.600"
                                : "green"
                            }
                            size={"xs"}
                          >
                            {el.task_status}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button
                            style={{
                              color: el.priority === "High" ? "red" : "orange",
                            }}
                            size={"xs"}
                          >
                            {el.priority}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Flex justifyContent={"space-between"} gap={2}>
                            <Button
                              ref={btnRef}
                              onClick={() => {
                                onSidebarOpen();
                                handleEdit(el);
                              }}
                              size={"xs"}
                            >
                              <FiEdit />
                            </Button>

                            <Button
                              onClick={() => handleTaskDelete(el._id)}
                              size={"xs"}
                              color="red"
                            >
                              <RiDeleteBin2Line />
                            </Button>
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>

            {selectedItem && (
              <Box>
                <Drawer
                  isOpen={isSidebarOpen}
                  onClose={onSidebarClose}
                  placement="right"
                  finalFocusRef={btnRef}
                  size={"sm"}
                >
                  <form>
                    <DrawerOverlay />
                    <DrawerContent>
                      <DrawerCloseButton />
                      <DrawerHeader>Edit Task</DrawerHeader>
                      <DrawerBody>
                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            type="text"
                            value={formData.title}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                title: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>Title</Box>
                        </Box>
                        <br />
                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            type="text"
                            value={formData.description}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                description: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>Description</Box>
                        </Box>
                        <br />
                        <Box position="relative" mb={5}>
                          <Select
                            placeholder=""
                            required
                            type="text"
                            value={formData.priority}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                priority: e.target.value,
                              })
                            }
                          >
                            <option value=""></option>
                            <option value="Low">Low</option>
                            <option value="Medium">Medium</option>
                            <option value="High">High</option>
                          </Select>
                          <Box {...BoxStyle}>Priority</Box>
                        </Box>

                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            type="date"
                            value={formData.start_date}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                start_date: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>Start Date</Box>
                        </Box>
                        <br />
                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            type="date"
                            value={formData.end_date}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                end_date: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>End Dates</Box>
                        </Box>
                      </DrawerBody>
                      <DrawerFooter>
                        <Button colorScheme="green" onClick={handleTaskUpdate}>
                          Update Task
                        </Button>
                      </DrawerFooter>
                    </DrawerContent>
                  </form>
                </Drawer>
              </Box>
            )}
          </TableContainer>
          <Flex justifyContent={"flex-end"} mt={5}>
            <Pagination page={page} setPage={setPage} totalPage={totalPage} />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default HRTask;
